import React, { Component } from 'react'
import {Toast} from 'primereact/toast'
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Utils} from '../generico/Utils'
import {CNPJs} from '../generico/CNPJs'
import {Constantes} from '../generico/Constantes'

import axios from 'axios'
const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'
const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

export class RelatorioIRPF extends Component {

    constructor(props) {
        super(props)

        this.state = {
            listaObjetivo: [],
            anoBase: '2020',
            anos: ['2018','2019','2020','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030'],
            dolar: 1,
            classe: 'Todas',
            classes: ['Todas','Ações','FIIs','Stocks','REITs'],
            isLoadingChart: true,
            dataList: {},
            isLoading: true
        }

        Utils.buscarCotacaoDolar().then( valor => {
            this.setState({dolar: valor})
            this.setState({isLoading: false})
        })

        this.buscar = this.buscar.bind(this)
        this.printChart = this.printChart.bind(this)
        this.dataTenplate = this.dataTenplate.bind(this)
        this.operacaoTemplate = this.operacaoTemplate.bind(this)
        this.totalTenplate = this.totalTenplate.bind(this)
        this.moneyTenplate = this.moneyTenplate.bind(this)
        this.footerTemplate = this.footerTemplate.bind(this)
        this.calculateGroupTotal = this.calculateGroupTotal.bind(this)
        this.calculateGroupTotalAnterior = this.calculateGroupTotalAnterior.bind(this)
        this.calculateGroupQuantidade = this.calculateGroupQuantidade.bind(this)
    }

    componentDidMount() {
        this.buscar()
    }

    buscar() {
        this.setState({isLoadingChart: true})
        axios.get(objBaseUrl+'?sort=ordem&userId='+Constantes.getUserId()).then(
            async resp => {
                let listaObjetivo = resp.data


                //Buscar todas as ações
                var dataList = []
                await axios.get(acaoBaseUrl+'?userId='+Constantes.getUserId()+'&sort=nome').then(
                    listaAcao => {
                        for (var acao of listaAcao.data) {

                            var objetivo = listaObjetivo[0]
                            for (var o of listaObjetivo) {
                                if (o._id === acao.objetivoId) {
                                    objetivo = o
                                    break
                                }
                            }
                            
                            for (var mov of acao.movimentacao) {
                                let movimento = {
                                    nome: acao.nome,
                                    data: mov.data,
                                    operacao: mov.operacao,
                                    quantidade: mov.quantidade,
                                    valor: mov.preco,
                                    classe: objetivo.nome,
                                    mesAno: Utils.getMesAno(new Date(mov.data)),
                                    acao: acao,
                                    tipoClasse: objetivo.tipo
                                }
                                dataList.push(movimento)
                            }
                        }
                    }
                )
                

                var resultList = []              
                var dtFim = new Date(this.state.anoBase+'-12-31T23:59:59')
                for (var mov of dataList) {
                    let data = new Date(mov.data)

                    if ( this.state.classe!=='Todas' && mov.classe !== this.state.classe ) {
                        continue
                    }

                    if (dtFim !==null 
                        && typeof dtFim !== 'undefined'
                        && dtFim-data <= 0 ) continue

                    resultList.push(mov)
                }

                
                var resultListAnterior = []
                var dtFimAnterior = new Date( (Number.parseInt(this.state.anoBase)-1) +'-12-31T23:59:59')  
                for (mov of dataList) {
                    let data = new Date(mov.data)

                    if ( this.state.classe!=='Todas' && mov.classe !== this.state.classe ) {
                        continue
                    }

                    if (dtFimAnterior !==null 
                        && typeof dtFimAnterior !== 'undefined'
                        && dtFimAnterior-data <= 0 ) continue

                    resultListAnterior.push(mov)
                }

                this.setState({dataList: resultList})
                this.setState({dataListAnterior: resultListAnterior})
                this.setState({isLoading: false})
                this.setState({isLoadingChart: false})
            }
        ) 
    }

    operacaoTemplate(rowData, column) {
        return <span>{rowData.operacao==='+'?'Compra':'Venda'}</span>;
    }

    dataTenplate(rowData, column) {
        return <span>{Utils.formatarData(rowData.data)}</span>;
    }

    moneyTenplate(rowData, column) {    
        var valor = rowData.valor
        if ( rowData.classe === 'Stocks' || rowData.classe === 'REITs' ) {
            valor = valor * this.state.dolar
        }
        return <span>{Utils.formatarDinheiro(valor)}</span>;
    }

    totalTenplate(rowData, column) {            
        var valor = rowData.valor
        if ( rowData.classe === 'Stocks' || rowData.classe === 'REITs' ) {
            valor = valor * this.state.dolar
        }
        return <span>{Utils.formatarDinheiro(valor*rowData.quantidade)}</span>;
    }

    headerTemplate(data) {
        return <b>{data.nome}</b>
    }

    footerTemplate(data, index) {
        return (
            <React.Fragment>

                <td colSpan={3} style={{textAlign: 'right'}}>
                    CNPJ <b>{CNPJs.buscarCnpj(data.nome)}</b>
                </td>

                <td colSpan={1} style={{textAlign: 'right'}}>
                    Quantidade<br/>
                    <b>{this.calculateGroupQuantidade(data.nome)}</b>
                </td>
                <td colSpan={1} style={{textAlign: 'right'}}>
                    Valor em {(Number.parseInt(this.state.anoBase)-1)}<br/>
                    <b>{Utils.formatarDinheiro(this.calculateGroupTotalAnterior(data.nome))}</b>
                </td>
                <td colSpan={1} style={{textAlign: 'right'}}>
                    Valor em {this.state.anoBase}<br/>
                    <b>{Utils.formatarDinheiro(this.calculateGroupTotal(data.nome))}</b>
                </td>
            </React.Fragment>
        )
    }

    calculateGroupQuantidade(nome) {
        let total = 0

        if (this.state.dataList) {
            for (let rowData of this.state.dataList) {
                if (rowData.nome === nome) {
                    if ( rowData.operacao === '-')
                        total -= rowData.quantidade
                    else
                        total += rowData.quantidade
                }
            }
        }

        return total       
    }

    calculateGroupTotalAnterior(nome) {      
        let total = 0

        if (this.state.dataListAnterior) {
            for (let rowData of this.state.dataListAnterior) {
                if (rowData.nome === nome) {

                    var valor = rowData.valor
                    if ( rowData.classe === 'Stocks' || rowData.classe === 'REITs' ) {
                        valor = valor * this.state.dolar
                    }

                    if ( rowData.operacao === '-')
                        total -= (valor*rowData.quantidade)
                    else
                        total += (valor*rowData.quantidade)
                }
            }
        }

        return total
    }

    calculateGroupTotal(nome) {
        let total = 0

        if (this.state.dataList) {
            for (let rowData of this.state.dataList) {
                if (rowData.nome === nome) {

                    var valor = rowData.valor
                    if ( rowData.classe === 'Stocks' || rowData.classe === 'REITs' ) {
                        valor = valor * this.state.dolar
                    }

                    if ( rowData.operacao === '-')
                        total -= (valor*rowData.quantidade)
                    else
                        total += (valor*rowData.quantidade)
                }
            }
        }

        return total
    }

    printChart() {

        if ( !this.state.isLoadingChart )
            return (
                <DataTable value={this.state.dataList} rowGroupMode="subheader" groupField="nome"
                    rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplate}>
                    <Column field="nome" header="Ativo"/>
                    <Column field="operacao" body={this.operacaoTemplate} header="Operação"/>
                    <Column field="data" header="Data" body={this.dataTenplate}
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>
                    <Column field="quantidade" header="Quantidade"
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>
                        
                    <Column field="valor" header="Cotação" body={this.moneyTenplate}
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>

                    <Column field="valor" header="Valor Total" body={this.totalTenplate}
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>
                </DataTable>
            )
        else
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
    }

    render() {

        if ( this.state.isLoading )
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
        else 
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <Toast ref={(el) => this.messages = el}/>
                </div>

                <div className="p-col-12">  
                    <div className="card card-w-title">
                        <h1>IRPF</h1>

                    </div> 

                    <div className="card">
                        <div className="p-grid p-fluid p-align-end">
                            <div className="p-md-2 p-sm-12">
                                Ano Base
                                <Dropdown value={this.state.anoBase} options={this.state.anos} 
                                    onChange={(e) => {this.setState( {anoBase: e.target.value })} }/>
                            </div>                       
                            <div className="p-md-2 p-sm-12">
                                Classe
                                <Dropdown value={this.state.classe} options={this.state.classes} 
                                    onChange={(e) => {this.setState( {classe: e.target.value })} }/>
                            </div>    
                            <div className="p-md-2 p-sm-12">
                                <Button label="Buscar" icon="pi pi-check" onClick={this.buscar}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        {this.printChart()}
                    </div>                
                </div>

            </div>
        )
    }
}