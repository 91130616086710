import React, { Component } from 'react'
import {Utils} from '../generico/Utils'
import {TopValores} from './TopValores'

export class SugestaoAporte extends Component {

    render() {
        const calculaDif = objetivo => {
            var valorMeta = (this.props.patrimonio*(objetivo.percentual))/100
            var diferenca = objetivo.valorTotal - valorMeta
            return (
            <span className="customer-badge status-unqualified">
                {Utils.formatarDinheiro(diferenca,this.props.showMoney)}    
            </span>)
        }
        return (    
            <div className="card summary">
                <b>Diferença Atual</b><br/>
                {calculaDif(this.props.objetivo)}
                <div className="count visitors">
                    {this.props.objetivo.nome}
                </div>

                <div style={{paddingTop: '28px'}}>
                    <div>
                        <b>Menores Participações</b>
                    </div>
                    <TopValores patrimonio={this.props.patrimonio} showMoney={this.props.showMoney} 
                        objetivoId={this.props.objetivo._id} quantidade="3" header="" sentido="bottom"/>
                </div>
            </div>
        )
    }
}