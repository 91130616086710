import React, { Component } from 'react'
import {Utils} from '../generico/Utils'
import {AcaoUtils} from '../generico/AcaoUtils'
import {Chart} from 'primereact/chart'

export class CardAcaoResumo extends Component {


    constructor(props) {
        super(props)

        this.state = {
            totalInvestido: 0,
            totalAtual: 0,
            totalRendimento: 0,

            listaAtivo: [],
            listaAtual: [],
            listaAtualRendimento: [],
            listaInvestido: [],

            
            listaCorAtual: [],
            listaCorInvestido: []
        }

        this.calcularPercentualVariacao = this.calcularPercentualVariacao.bind(this)
        this.calcularPercentualRetorno = this.calcularPercentualRetorno.bind(this)
        this.calcularPercentualRendimento = this.calcularPercentualRendimento.bind(this)
    }

    componentDidMount() {
        var listaAcao = this.props.listaAcao
        var tipoClasse = this.props.tipoClasse
        var dolar = this.props.dolar

        var totalInvestido = 0
        var totalAtual = 0
        var totalRendimento = 0
        var listaAtivo = []
        var listaAtualRendimento = []
        var listaAtual = []
        var listaInvestido = []
        var listaCorAtual = []
        var listaCorInvestido = []
        var listaCorAtualRendimento = []
        for( var acao of listaAcao ) {
            var valorInvestido = AcaoUtils.calcularValorPago(acao,tipoClasse,dolar)
            var valorAtual = AcaoUtils.calcularValorAtual(acao,tipoClasse,dolar)
            var rendimento = AcaoUtils.calculaTotalRendimentos(acao,tipoClasse,dolar)

            if ( valorInvestido<(valorAtual+rendimento) ) {
                listaCorAtual.push('#20D077')
                listaCorInvestido.push('#C8E6C9')
                listaCorAtualRendimento.push('#3CB371')
            }
            else {
                listaCorAtual.push('#FF0000')
                listaCorInvestido.push('#FFCDD2')
                listaCorAtualRendimento.push('#C63737')
            }

            totalInvestido += valorInvestido
            totalAtual += valorAtual
            totalRendimento += rendimento

            listaAtivo.push(acao.nome)
            listaInvestido.push(valorInvestido.toFixed(2))
            listaAtual.push(valorAtual.toFixed(2))
            listaAtualRendimento.push((valorAtual+rendimento).toFixed(2))
        }

        this.setState({
            totalInvestido: totalInvestido,
            totalAtual: totalAtual,
            totalRendimento: totalRendimento,

            listaAtivo: listaAtivo,
            listaAtual: listaAtual,
            listaAtualRendimento: listaAtualRendimento,
            listaInvestido: listaInvestido,

            listaCorAtual: listaCorAtual,
            listaCorInvestido: listaCorInvestido,
            listaCorAtualRendimento: listaCorAtualRendimento
        })
    }

    calcularPercentualVariacao() {
        var variacao = this.state.totalAtual-this.state.totalInvestido
        var result = variacao / this.state.totalInvestido
        
        return (result*100).toFixed(2)
    }

    calcularPercentualRetorno() {
        var variacao = (this.state.totalAtual-this.state.totalInvestido)+this.state.totalRendimento
        var result = variacao / this.state.totalInvestido
        
        return (result*100).toFixed(2)
    }

    calcularPercentualRendimento() {
        var rendimento = this.state.totalRendimento
        var result = rendimento / this.state.totalInvestido

        return (result*100).toFixed(2)
    }

    printChart() {
        var data = {
            labels: this.state.listaAtivo,
            datasets: [
                {
                    label: 'Valor Investido',
                    backgroundColor: this.state.listaCorInvestido,
                    data: this.state.listaInvestido
                },
                {
                    label: 'Valor Atual',
                    backgroundColor: this.state.listaCorAtual,
                    data: this.state.listaAtual
                },
                {
                    label: 'Valor Atual Rendimentos',
                    backgroundColor: this.state.listaCorAtualRendimento,
                    data: this.state.listaAtualRendimento
                }
            ]
        }

        var options = {
            legend: {
                position: 'none'
            },
            responsive: true
        }

        return <Chart type="bar" data={data} options={options}  height="80%"/>
    }

    render() {

        return (           

            <div className="p-grid" style={{paddingTop: '20px'}}>

                <div className="p-md-4 p-sm-12">
                    <div className="p-grid"  style={{paddingTop: '20px'}}>

                        <div className="p-col-6">
                            Valor Atual
                        </div>
                        <div className="p-col-6" style={{textAlign: 'right'}}>
                            {Utils.formatarDinheiro(this.state.totalAtual)}
                        </div>

                        <div className="p-col-6">
                            Valor Investido
                        </div>
                        <div className="p-col-6" style={{textAlign: 'right'}}>
                            {Utils.formatarDinheiro(this.state.totalInvestido)}
                        </div>

                        <div className="p-col-4">
                            Variação
                        </div>
                        <div className="p-col-8" style={{textAlign: 'right'}}>
                            <span className={(this.state.totalAtual-this.state.totalInvestido)<0?'customer-badge status-unqualified':'customer-badge status-qualified'}>
                                {Utils.formatarDinheiro(this.state.totalAtual-this.state.totalInvestido)}
                                {' ('+this.calcularPercentualVariacao()+'%)'}
                            </span>
                        </div>

                        <div className="p-col-6">
                            Rendimentos
                        </div>
                        <div className="p-col-6" style={{textAlign: 'right'}}>
                            {Utils.formatarDinheiro(this.state.totalRendimento)}
                            {' ('+this.calcularPercentualRendimento()+'%)'}
                        </div>

                        <div className="p-col-6">
                            Retorno
                        </div>
                        <div className="p-col-6" style={{textAlign: 'right'}}>
                            <span className={((this.state.totalAtual-this.state.totalInvestido)+this.state.totalRendimento)<0?'customer-badge status-unqualified':'customer-badge status-qualified'}>
                                {Utils.formatarDinheiro((this.state.totalAtual-this.state.totalInvestido)+this.state.totalRendimento)}
                                {' ('+this.calcularPercentualRetorno()+'%)'}             
                            </span>               
                        </div>
                    </div>
                </div>
                
                <div className="p-md-8  div-mobile-hidden">
                    {this.printChart()}
                </div>
            </div>
        )
    }

}