import React, { Component } from 'react'
import {Utils} from '../generico/Utils'
import {ConfirmDialog} from '../generico/ConfirmDialog'
import {Button} from 'primereact/button'
import {Accordion,AccordionTab} from 'primereact/accordion'
import {Constantes} from '../generico/Constantes'
import {Toast} from 'primereact/toast'

import axios from 'axios'
const rfBaseUrl = Constantes.buscarUrlBase()+'rendafixa/'

export class ExtratoRFList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            excluirDialog: false,
            movimentacao: {}
        }

        this.deleteMov = this.deleteMov.bind(this)
    }

    deleteMov(mov) {
        this.setState({movimentacao: {...mov}})
        this.setState({excluirDialog: true})
    }

    render() {
        return (
            <Accordion style={{padding: '0px'}}>

                <AccordionTab header="Movimentações" headerStyle={{color: 'black !important'}}>

                    <Toast ref={(el) => this.messages = el} />


                        {this.props.ativo.extrato.map(
                            mov => {
                                return (

                                    <div className="cardBox p-grid p-align-center">
                                        <div className="p-col-6" style={{textAlign: 'center'}}>
                                            {Utils.formatarData(mov.dataAtualizacao)}
                                        </div>
                                        <div className="p-col-4" style={{textAlign: 'right'}}>
                                            Valor<br/>
                                            {Utils.formatarDinheiro(mov.saldo)}                                    
                                        </div>
                                        <div className="p-col-2" style={{textAlign: 'center'}}>
                                            <Button icon="pi pi-trash" className="p-button-secondary"
                                                tooltip="Excluir" onClick={ () => this.deleteMov(mov) }/> 
                                        </div>
                                    </div>
                                )
                            }
                        )}

                        <ConfirmDialog header="Excluir Movimentação"
                            visible={this.state.excluirDialog} 
                            message="Tem certeza que deseja excuir?"
                            funcaoSim={ () => {
    
                                var acao = this.props.ativo
                                var mov = this.state.extrato
    
                                acao.extrato.splice(acao.extrato.indexOf(mov), 1);
                                    
                                axios.put(rfBaseUrl+acao._id,{...acao}).then(resp => {     
                                    if ( resp.status === 201 || resp.status === 200) {
                                        this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Movimentação removida com Sucesso'})
                                        this.props.onDeleteFunction()
                                    }
                                    else  {  
                                        this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao remover Movimentação'});
                                    }
                                    
                                    this.setState({excluirDialog: false})
                                    })
                                }
                            }
                            funcaoNao={() => this.setState({excluirDialog: false})}
                        />

                </AccordionTab>
            </Accordion>
            
        )
    }

}