import React, { Component } from 'react'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {InputNumber} from 'primereact/inputnumber'
import {Calendar} from 'primereact/calendar'
import {Toast} from 'primereact/toast'
import {Constantes} from '../generico/Constantes'
import MoedaInput from '../generico/MoedaInput'

import axios from 'axios'
const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

export class CriarAcaoDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            acao: {},
            movimentacao: {
                valor: null,
                quantidade: 0,
                taxas: null
            }
        }

        this.salvarAcao = this.salvarAcao.bind(this)
        this.fecharDialog = this.fecharDialog.bind(this)
    }

    salvarAcao() {
        if (typeof this.state.acao.nome === 'undefined' || this.state.acao.nome === '') {
            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'O Código é Obrigatório!'})
            return
        }
        if (typeof this.state.movimentacao.quantidade === 'undefined' || this.state.movimentacao.quantidade === '') {
            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'A quantidade é Obrigatória!'})
            return
        } 
        if (typeof this.state.movimentacao.valor === 'undefined' || this.state.movimentacao.valor === '') {
            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'O Valor é Obrigatório!'})
            return
        } 
        if (typeof this.state.movimentacao.taxas === 'undefined' || this.state.movimentacao.taxas === '') {
            this.setState( {movimentacao: {
                ...this.state.movimentacao,
                taxas: 0
                }
            })
        }

        var novaAcao = {
            userId: this.props.userId,
            objetivoId: this.props.objetivoId,
            nome: this.state.acao.nome,
            setor: this.state.acao.setor,
            quantidadeAtual: this.state.movimentacao.quantidade,
            cotacaoAtual: ((this.state.movimentacao.quantidade*this.state.movimentacao.valor)+this.state.movimentacao.taxas)/this.state.movimentacao.quantidade,
            movimentacao: [
                {
                    data: this.state.movimentacao.data,
                    preco: this.state.movimentacao.valor,
                    taxas: this.state.movimentacao.taxas,
                    quantidade: this.state.movimentacao.quantidade
                }
            ]
        }

        axios.post(acaoBaseUrl,{...novaAcao}).then(resp => {     
                if ( resp.status === 201 || resp.status === 200) {
                    this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Ativo cadastrado com Sucesso'})                    
                    this.fecharDialog()
                }
                else    
                    this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao cadastrar Ativo'});
            }
        )
        
    }

    fecharDialog() {
        this.setState({acao: {}, movimentacao: {
            valor: null,
            quantidade: 0,
            taxas: null}})
        this.props.onHideFunction()
    }

    render() {
        
        const footer = (
            <div>
                <Button label="Salvar" icon="pi pi-check" onClick={ this.salvarAcao }/>
                <Button label="Cancelar" icon="pi pi-times" onClick={ this.fecharDialog }/>
            </div>
        )

        return (

            <Dialog header={this.props.header} visible={this.props.display} modal={true}
                footer={footer} style={{width: Constantes.tamanhoDialog()}} onHide={this.fecharDialog}>
                    
                <Toast ref={(el) => this.messages = el} />
                    
                <div className="p-grid">                        
                    <div className="p-col-12 p-md-2">
                        <label htmlFor="nome">Código</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <InputText id="nome"
                            tooltip="Código de Negociação do Ativo"
                            value={this.state.acao.nome} onChange={(e) => this.setState( {acao: {
                                ...this.state.acao,
                                nome: e.target.value
                            }
                        })}/>
                    </div>

                                        
                    <div className="p-col-12 p-md-2">
                        <label htmlFor="setor">Segmento</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <InputText id="setor"
                            tooltip="Setor de Atuação do Ativo"
                            value={this.state.acao.setor} onChange={(e) => this.setState( {acao: {
                                ...this.state.acao,
                                setor: e.target.value
                            }
                        })}/>
                    </div>
                                        
                    <div className="p-col-12 p-md-2">
                        <label htmlFor="data">Data</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <Calendar id="data" dateFormat="dd/mm/yy" style={{zIndex:1000}}
                            monthNavigator={true} yearNavigator={true} yearRange="2000:2030"
                            tooltip="Data da Operação"
                            appendTo={document.body}
                            value={this.state.movimentacao.data} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                data: e.target.value
                            }
                        })}/>
                    </div>
                                        
                    <div className="p-col-12 p-md-2">
                        <label htmlFor="quantidade">Quantidade</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <InputNumber id="quantidade"
                            tooltip="Quantidade de Ativos" 
                            minFractionDigits={(this.props.tipoClasse === 'stock' ||  this.props.tipoClasse === 'reit')?4:0} 
                            value={this.state.movimentacao.quantidade} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                quantidade: e.target.value
                            }
                        })}/>
                    </div>

                    <div className="p-col-12 p-md-2">
                        <label htmlFor="valor">Valor</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <MoedaInput placeholder="0.00" type="text"                         
                            value={this.state.movimentacao.valor} 
                            onChange={(e) => this.setState( {movimentacao: {
                                    ...this.state.movimentacao,
                                    valor: e.target.value
                                }}
                            )}
                        />
                    </div>

                    <div className="p-col-12 p-md-2">
                        <label htmlFor="taxas">Taxas</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <MoedaInput placeholder="0.00" type="text"                         
                            value={this.state.movimentacao.taxas} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                taxas: e.target.value
                            }
                        })}/>
                    </div>
                </div>
            </Dialog>
        )
    }
}