import React, { Component } from 'react'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'
import {Calendar} from 'primereact/calendar'
import {Toast} from 'primereact/toast'
import {Constantes} from '../generico/Constantes'
import MoedaInput from '../generico/MoedaInput'

import axios from 'axios'
const rfBaseUrl = Constantes.buscarUrlBase()+'rendafixa/'

export class EditarRFDialog extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            extrato: {}
        }

        this.fecharDialog = this.fecharDialog.bind(this)
        this.salvarEdit = this.salvarEdit.bind(this)
    }

    fecharDialog() {
        this.setState({extrato: {}})
        this.props.onHideFunction()
    }

    salvarEdit() {
        var conta = {...this.props.ativo}
        conta.extrato.push(this.state.extrato)
        
        axios.put(rfBaseUrl+conta._id, {...conta}).then(resp => {
            if ( resp.status === 200 ) {
                this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Ativo atualizado com Sucesso'})
                this.fecharDialog()
            }
            else    
                this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao atualizar Ativo'});
            }
        )
    }

    render() {
        const footerEdit = (
            <div>
                <Button label="Salvar" icon="pi pi-check" onClick={this.salvarEdit}/>
                <Button label="Cancelar" icon="pi pi-times" onClick={this.fecharDialog}/>
            </div>
        );

        return(
            
            <Dialog header={this.props.header} visible={this.props.display} modal={true}
                footer={footerEdit} style={{width: Constantes.tamanhoDialog()}} onHide={this.fecharDialog}>

                <Toast ref={(el) => this.messages = el} />
                    
                <div className="p-grid">     

                <div className="p-col-12 p-md-2">
                    <label htmlFor="data">Data</label>
                </div>
                <div className="p-col-12 p-md-10">
                    <Calendar id="data" dateFormat="dd/mm/yy" style={{zIndex:1000}}
                        tooltip="Data de Atualização do Saldo"
                        appendTo={document.body}
                        value={this.state.extrato.data} 
                        onChange={(e) => this.setState( {extrato: {
                            ...this.state.extrato,
                            data: e.target.value
                        }
                    })}/>
                </div>

                <div className="p-col-12 p-md-2">
                    <label htmlFor="valor">Saldo</label>
                </div>
                <div className="p-col-12 p-md-10">
                    <MoedaInput placeholder="0.00" type="text"                         
                        value={this.state.extrato.saldo} 
                        onChange={(e) => this.setState( {extrato: {
                            ...this.state.extrato,
                            saldo: e.target.value
                        }
                    })}/>
                </div>

                </div>
            </Dialog>
        )
    }
}