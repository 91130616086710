import React, { Component } from 'react'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber'
import {Calendar} from 'primereact/calendar'
import {Toast} from 'primereact/toast'
import {Constantes} from '../generico/Constantes'
import MoedaInput from '../generico/MoedaInput'

import axios from 'axios'
const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

export class EditarAcaoDialog extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            acao: {},
            movimentacao: { 
                operacao: '+',
                data: new Date(),
                valor: null,
                quantidade: 0,
                preco: null,
                taxas: null
            },
            operacoes: [
                {label: 'Compra', value: '+'},
                {label: 'Venda', value: '-'},
                {label: 'Dividendos', value: 'Dividendos'},
                {label: 'Juro s/ Capital', value: 'Juros'},
                {label: 'Rendimentos', value: 'Rendimentos'}
            ],
            operacao: {}
        }

        this.fecharDialog = this.fecharDialog.bind(this)
        this.salvar = this.salvar.bind(this)
        this.operacaoChange = this.operacaoChange.bind(this)
    }

    fecharDialog() {
        this.setState({acao: {}, movimentacao: { 
            operacao: '+',
            valor: null,
            data: new Date(),
            quantidade: 0,
            preco: null,
            taxas: null}})
        this.props.onHideFunction()
    }

    async salvar() {

        let novaAcao = {...this.props.ativo}

        let operacao = this.state.movimentacao.operacao

        if (operacao !== '+' && operacao !== '-') {
            let rendimentos = []
            var rendimento = {
                tipo: operacao,
                data: this.state.movimentacao.data,
                valor: this.state.movimentacao.preco
            }

            rendimentos.push(rendimento)
            if ( typeof novaAcao.rendimentos === 'undefined') {               
                novaAcao.rendimentos = rendimentos
            }
            else {
                novaAcao.rendimentos = novaAcao.rendimentos.concat(rendimentos)
            }
        }
        else {
            novaAcao.movimentacao.push(this.state.movimentacao)

            let quantidadeTotal = 0
            for(const m of novaAcao.movimentacao) {

                if ( m.operacao === '+' ) {
                    quantidadeTotal += m.quantidade
                }
                else if (m.operacao === '-') {
                    quantidadeTotal -= m.quantidade
                }
            }
            
            novaAcao.quantidadeAtual = quantidadeTotal
        }


        axios.put(acaoBaseUrl+novaAcao._id,{...novaAcao}).then(resp => {
            console.log(resp)
            if ( resp.status === 201 || resp.status === 200) {
                this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Movimentação cadastrada com Sucesso'})
                this.fecharDialog()
            }
            else    
                this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao cadastrar Movimentação'});
            }
        )
    }

    operacaoChange(e) {
        this.setState( 
            {
                movimentacao: {
                    ...this.state.movimentacao,
                    operacao: e.target.value
                }
            }
        )

        if (e.target.value === '+' || e.target.value === '-') {
            document.getElementById("divQtd").style.display = "block"
            document.getElementById("lbDivQtd").style.display = "block"
            document.getElementById("divTx").style.display = "block"
            document.getElementById("lbDivTx").style.display = "block"
        }
        else {
            document.getElementById("divQtd").style.display = "none"
            document.getElementById("lbDivQtd").style.display = "none"
            document.getElementById("divTx").style.display = "none"
            document.getElementById("lbDivTx").style.display = "none"
        }
    
    }

    render() {
        const footerEdit = (
            <div>
                <Button label="Salvar" icon="pi pi-check" onClick={this.salvar}/>
                <Button label="Cancelar" icon="pi pi-times" onClick={this.fecharDialog}/>
            </div>
        );

        return(
            
            <Dialog header={this.props.header} visible={this.props.display} modal={true}
                footer={footerEdit} style={{width: Constantes.tamanhoDialog()}} onHide={this.fecharDialog}>

                <Toast ref={(el) => this.messages = el} />
                    
                <div className="p-grid">     

                    <div className="p-col-12  p-md-2">
                        <label>Código: </label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <span>{this.props.ativo.nome}</span>
                    </div>

                    <div className="p-col-12 p-md-2">
                        <label htmlFor="data">Operação:</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <Dropdown value={this.state.movimentacao.operacao} options={this.state.operacoes} 
                            onChange={this.operacaoChange}/>
                    </div>

                    <div className="p-col-12 p-md-2">
                        <label htmlFor="data">Data:</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <Calendar id="data" dateFormat="dd/mm/yy" style={{zIndex:1000}}
                            monthNavigator={true} yearNavigator={true} yearRange="2000:2030"
                            tooltip="Data da Operação"
                            appendTo={document.body}
                            value={this.state.movimentacao.data} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                data: e.target.value
                            }
                        })}/>
                    </div>
                                        
                    <div className="p-col-12 p-md-2" id="lbDivQtd">
                        <label htmlFor="quantidade">Quantidade:</label>
                    </div>
                    <div className="p-col-12 p-md-10" id="divQtd">
                        <InputNumber id="quantidade"
                            tooltip="Quantidade de Ativos"
                            minFractionDigits={(this.props.tipoClasse === 'stock' ||  this.props.tipoClasse === 'reit')?4:0} 
                            value={this.state.movimentacao.quantidade} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                quantidade: e.value
                            }
                        })}/>
                    </div>

                    <div className="p-col-12 p-md-2">
                        <label htmlFor="valor">Valor:</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <MoedaInput placeholder="0.00" type="text"                         
                            value={this.state.movimentacao.preco} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                preco: e.target.value
                            }
                        })}/>
                    </div>

                    <div className="p-col-12 p-md-2" id="lbDivTx">
                        <label htmlFor="taxas">Taxas</label>
                    </div>
                    <div className="p-col-12 p-md-10" id="divTx">
                        <MoedaInput placeholder="0.00" type="text"                         
                            value={this.state.movimentacao.taxas} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                taxas: e.target.value
                            }
                        })}/>                        
                    </div>
                </div>
            </Dialog>
            )
    }
}