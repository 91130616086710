import React, { Component } from 'react'
import {Toast} from 'primereact/toast'
import {Calendar} from 'primereact/calendar'
import {Button} from 'primereact/button'
import {Dropdown} from 'primereact/dropdown'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Utils} from '../generico/Utils'
import {Constantes} from '../generico/Constantes'

import axios from 'axios'
const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'
const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

export class RelatorioExtrato extends Component {

    constructor(props) {
        super(props)

        this.state = {
            listaObjetivo: [],
            dtIni: null,
            dtFim: null,
            dolar: 1,
            classe: 'Todas',
            classes: ['Todas','Ações','FIIs','Stocks','REITs'],
            isLoadingChart: true,
            dataList: {},
            isLoading: true
        }

        Utils.buscarCotacaoDolar().then( valor => {
            this.setState({dolar: valor})
            this.setState({isLoading: false})
        })

        this.buscar = this.buscar.bind(this)
        this.printChart = this.printChart.bind(this)
        this.dataTenplate = this.dataTenplate.bind(this)
        this.totalTenplate = this.totalTenplate.bind(this)
        this.moneyTenplate = this.moneyTenplate.bind(this)
        this.footerTemplate = this.footerTemplate.bind(this)
        this.calculateGroupTotal = this.calculateGroupTotal.bind(this)
    }

    componentDidMount() {
        this.buscar()
    }

    buscar() {
        this.setState({isLoadingChart: true})
        axios.get(objBaseUrl+'?sort=ordem&userId='+Constantes.getUserId()).then(
            async resp => {
                let listaObjetivo = resp.data


                //Buscar todas as ações
                var dataList = []
                await axios.get(acaoBaseUrl+'?userId='+Constantes.getUserId()+'&sort=objetivoId').then(
                    listaAcao => {
                        for (var acao of listaAcao.data) {

                            var objetivo = listaObjetivo[0]
                            for (var o of listaObjetivo) {
                                if (o._id === acao.objetivoId) {
                                    objetivo = o
                                    break
                                }
                            }
                            
                            for (var mov of acao.movimentacao) {
                                let movimento = {
                                    nome: acao.nome,
                                    data: mov.data,
                                    quantidade: mov.quantidade,
                                    valor: mov.preco,
                                    classe: objetivo.nome,
                                    operacao: mov.operacao === "+"?"Compra":"Venda",
                                    mesAno: Utils.getMesAno(new Date(mov.data))
                                }
                                dataList.push(movimento)
                            }
                        }
                    }
                )
                
                dataList = dataList.sort( (a,b) => {
                    return new Date(a.data) - new Date(b.data)
                })

                var resultList = []
                for (var mov of dataList) {
                    let data = new Date(mov.data)

                    if ( this.state.classe!=='Todas' && mov.classe !== this.state.classe ) {
                        continue
                    }

                    if (this.state.dtIni !==null 
                        && typeof this.state.dtIni !== 'undefined'
                        && this.state.dtIni-data >= 0 ) continue

                    if (this.state.dtFim !==null 
                        && typeof this.state.dtFim !== 'undefined'
                        && this.state.dtFim-data <= 0 ) continue

                    resultList.push(mov)
                }

                this.setState({dataList: resultList})
                this.setState({isLoading: false})
                this.setState({isLoadingChart: false})
            }
        ) 
    }

    dataTenplate(rowData, column) {
        return <span>{Utils.formatarData(rowData.data)}</span>;
    }

    moneyTenplate(rowData, column) {    
        var valor = rowData.valor
        if ( rowData.classe === 'Stocks' || rowData.classe === 'REITs' ) {
            valor = valor * this.state.dolar
        }
        return <span>{Utils.formatarDinheiro(valor)}</span>;
    }

    totalTenplate(rowData, column) {            
        var valor = rowData.valor
        if ( rowData.classe === 'Stocks' || rowData.classe === 'REITs' ) {
            valor = valor * this.state.dolar
        }
        return <span>{Utils.formatarDinheiro(valor*rowData.quantidade)}</span>;
    }

    headerTemplate(data) {
        return <b>{data.mesAno}</b>
    }

    footerTemplate(data, index) {
        return (
            <React.Fragment>
                <td colSpan={5} style={{textAlign: 'right'}}>Total</td>
                <td style={{textAlign: 'right'}}><b>{Utils.formatarDinheiro(this.calculateGroupTotal(data.mesAno))}</b></td>
            </React.Fragment>
        )
    }

    calculateGroupTotal(mesAno) {
        let total = 0

        if (this.state.dataList) {
            for (let rowData of this.state.dataList) {
                if (rowData.mesAno === mesAno) {

                    var valor = rowData.valor
                    if ( rowData.classe === 'Stocks' || rowData.classe === 'REITs' ) {
                        valor = valor * this.state.dolar
                    }

                    total = total+(valor*rowData.quantidade)
                }
            }
        }

        return total
    }

    printChart() {

        if ( !this.state.isLoadingChart )
            return (
                <DataTable value={this.state.dataList} rowGroupMode="subheader" groupField="mesAno"
                    rowGroupHeaderTemplate={this.headerTemplate} rowGroupFooterTemplate={this.footerTemplate}>
                    <Column field="nome" header="Ativo"/>
                    <Column field="data" header="Data" body={this.dataTenplate}
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>
                    <Column field="quantidade" header="Quantidade"
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>
                    <Column field="operacao" header="Operação"/>
                    <Column field="valor" header="Cotação" body={this.moneyTenplate}
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>

                    <Column field="valor" header="Valor Total" body={this.totalTenplate}
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>
                </DataTable>
            )
        else
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
    }

    render() {

        if ( this.state.isLoading )
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
        else 
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <Toast ref={(el) => this.messages = el} />
                </div>

                <div className="p-col-12">  
                    <div className="card card-w-title">
                        <h1>Extrato</h1>

                    </div> 

                    <div className="card">
                        <div className="p-grid p-fluid p-align-end">
                            <div className="p-md-2 p-sm-12">
                                Início
                                <Calendar value={this.state.dtIni} onChange={(e) => this.setState({ dtIni: e.value })} 
                                    view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="1900:2030" />
                            </div>
                            <div className="p-md-2 p-sm-12">
                                Fim
                                <Calendar value={this.state.dtFim} onChange={(e) => this.setState({ dtFim: e.value })} 
                                    view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="1900:2030" />
                            </div>                            
                            <div className="p-md-2 p-sm-12">
                                Classe
                                <Dropdown value={this.state.classe} options={this.state.classes} 
                                    onChange={(e) => {this.setState( {classe: e.target.value })} }/>
                            </div>    
                            <div className="p-md-2 p-sm-12">
                                <Button label="Buscar" icon="pi pi-check" onClick={this.buscar}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        {this.printChart()}
                    </div>                
                </div>

            </div>
        )
    }
}