import React, { Component } from 'react'

import './GenericCSS.css'

export class HeaderCircle extends Component {

    render() {
        return (
        <div className="headerCircle">{this.props.text}</div>
        )
    }
}