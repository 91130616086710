import React, { Component } from 'react'
import { Chart } from "react-google-charts"
import {Constantes} from '../generico/Constantes'
import axios from 'axios'

const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'
const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'

export class DiversificacaoChart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataChart: []
        }
    }

    componentDidMount(){
        var tipo = this.props.tipo

        axios.get(objBaseUrl+'?&userId='+Constantes.getUserId()+'&tipo='+tipo).then(
            resp => {
                
                var objetivo = resp.data[0]
                axios.get(acaoBaseUrl+'?&objetivoId='+objetivo._id).then(
                    respAcao => {
                        var listaAcao = respAcao.data
                        var listaSegmento = []
                        var listaValores = [['Segmento', 'Percentual']]
                        for( var acao of listaAcao ) {
                            var setor = acao.setor
                            if ( typeof setor === 'undefined' || setor === '') setor='Outros'

                            if ( listaSegmento.includes(setor) ) {
                                for(var valor of listaValores) {
                                    if (valor[0] === setor) {
                                        valor[1] += acao.quantidadeAtual*acao.cotacaoAtual
                                    }
                                }
                            }
                            else {
                                listaSegmento.push(setor)
                                listaValores.push([setor,(acao.quantidadeAtual*acao.cotacaoAtual)])
                            }
                        }
                        
                        this.setState( {dataChart: listaValores} )
                    }
                )
            }
        )
    }

    render() {        
        return (
            <Chart
                width="100%"
                height="325px"
                chartType="PieChart"
                loader={<div>Loading...</div>}
                data={this.state.dataChart}                
                options={{
                    pieSliceText: 'none',
                    chartArea: {width: '100%', height: '95%'},
                }}
            />
        )
    }
}