import React, { Component } from 'react'
import { Chart } from "react-google-charts"

export class ObjetivoPieChart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataChart: []
        }
    }

    componentDidMount(){
        var dataChart = [['Classe', 'Percentual']]

        for( let obj of this.props.objetivos ) {
            let nome = obj.nome
            let valor = ((obj.valorTotal/this.props.totalGeral)*100).toFixed(2)
            dataChart.push([nome,parseFloat(valor)])        
        }
        this.setState( {dataChart: dataChart} )
    }

    render() {        
        return (
            <Chart
                width="100%"
                height="100%"
                chartType="PieChart"
                loader={<div>Loading...</div>}
                data={this.state.dataChart}                
                options={{
                    legend: 'none',
                    pieSliceText: 'none',
                    chartArea: {width: '100%', height: '95%'},
                }}
            />
        )
    }
}