import React, { Component } from 'react'
import {Card} from 'primereact/card'
import {Button} from 'primereact/button'
import {ConfirmDialog} from '../generico/ConfirmDialog'
import {Toast} from 'primereact/toast'
import {ProgressBar} from 'primereact/progressbar'
import {CriarAcaoDialog} from './CriarAcaoDialog'
import {EditarAcaoDialog} from './EditarAcaoDialog'
import {Constantes} from '../generico/Constantes'
import {Utils} from '../generico/Utils'
import {AcaoUtils} from '../generico/AcaoUtils'
import {CardAcao} from './CardAcao'
import {DiversificacaoChart} from '../chart/DiversificacaoChart'
import { Panel } from 'primereact/panel'
import {TopValores} from '../acao/TopValores'
import {MovimentacaoDialog} from './MovimentacaoDialog'
import axios from 'axios'

import '../generico/GenericCSS.css'
import { RendimentosDialog } from './RendimentosDialog'
import { CardAcaoResumo } from './CardAcaoResumo'

const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'
const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

const boxSize = '300px'


export class ListaAcao extends Component {

      
    constructor(props) {
        super(props);
        this.state = {
            acao: {},
            movimentacao: {},
            classe: {},
            listaAcao: [],
            valorTotal: 0,
            acaoId: {},
            valorAcaoCard: {},
            validadeAcaoCard: {},
            displayDialog: false,
            displayDialogEdit:false,
            excluirDialog: false,
            displayDialogRendimentos: false,
            displayDialogMovimentacao: false,
            dolar: 1,
            
            isLoading: true
        }

        axios.get(objBaseUrl+'?userId='+Constantes.getUserId()+'&tipo='+props.tipoClasse).then(
            resp => {
                this.setState( {classe: resp.data[0]} )                
                this.buscarAcoes()
            }
        ) 

        Utils.buscarCotacaoDolar().then(valor => this.setState({dolar:valor}))

        Utils.calcularValorPorObjetivoSemServico().then(objetivos =>  this.atualizarObjetivos(objetivos))

        this.gerarCard = this.gerarCard.bind(this)        
        this.gerarCardNovo = this.gerarCardNovo.bind(this)    
        this.onHideFunctionNovo = this.onHideFunctionNovo.bind(this)
        this.onHideFunctionEdit = this.onHideFunctionEdit.bind(this) 
        this.onHideFunctionRendimento = this.onHideFunctionRendimento.bind(this)   
        this.onHideFunctionMovimentacao = this.onHideFunctionMovimentacao.bind(this)   
        this.onDeleteFunctionMovimentacao = this.onDeleteFunctionMovimentacao.bind(this)
        this.buscarAcoes = this.buscarAcoes.bind(this)
        this.buscarCotacao = this.buscarCotacao.bind(this)
        this.formatarDinheiro = this.formatarDinheiro.bind(this)
        this.exibirCotacaoDolar = this.exibirCotacaoDolar.bind(this)
        this.calcularPrecoMedio = this.calcularPrecoMedio.bind(this)
        this.calcularPercentualMeta = this.calcularPercentualMeta.bind(this)
        this.atualizarObjetivos = this.atualizarObjetivos.bind(this)
        this.exclusaoCallBack = this.exclusaoCallBack.bind(this)
        this.rendimentosCallBack = this.rendimentosCallBack.bind(this)
        this.movimentacaoCallBack = this.movimentacaoCallBack.bind(this)
        this.cotacaoCallBack = this.cotacaoCallBack.bind(this)
        this.posicaoCallBack = this.posicaoCallBack.bind(this)
    }

    calcularPercentualMeta() {
        Utils.calcularValorPorObjetivo().then(objetivos => this.atualizarObjetivos(objetivos))
    }

    atualizarObjetivos(objetivos) {
        let tipo = this.props.tipoClasse

        let valorTotal = 0
        let valorClasse = 0
        for( var o of objetivos ) {
            if (o.tipo === tipo) {
                valorClasse = o.valorTotal
            }
            valorTotal += o.valorTotal
        }

        let valorObjetivo = (valorTotal * this.state.classe.percentual)/100 
        let percentualMeta = (valorClasse/valorObjetivo)*100

        this.setState({percentualMeta: valorClasse===0?0:percentualMeta.toFixed(2)})
        this.setState({valorMeta: valorObjetivo})
        this.setState({valorTotal: valorClasse})        
        this.setState({patrimonioTotal: valorTotal})
        this.setState({isLoading: false})
    }

    calcularPrecoMedio(acao) {
        return AcaoUtils.calcularPrecoMedio(acao,this.props.tipoClasse, this.state.dolar)
    }

    exclusaoCallBack(id) {
        console.log('Exclusão '+id)
        this.setState({acaoId: id})
        this.setState({excluirDialog: true})
    }

    rendimentosCallBack(acao) {        
        this.setState({displayDialogRendimentos: true})
        this.setState( {acao: {...acao}})
    }

    movimentacaoCallBack(acao) {        
        this.setState({displayDialogMovimentacao: true})
        this.setState( {acao: {...acao}})
    }

    cotacaoCallBack(acao) {
        this.buscarCotacao(acao)
    }

    posicaoCallBack(acao) {        
        this.setState({displayDialogEdit: true})
        this.setState( {acao: {...acao}})
    }

    gerarCard(acao) {

        return (
            <div className="p-col-12 p-lg-3" id={'div'+acao._id}>
                <CardAcao item={acao} 
                    valor={this.state.valorAcaoCard[acao.nome]}
                    exclusaoCallBack={this.exclusaoCallBack}
                    rendimentosCallBack={this.rendimentosCallBack}
                    movimentacaoCallBack={this.movimentacaoCallBack}
                    cotacaoCallBack={this.cotacaoCallBack}
                    posicaoCallBack={this.posicaoCallBack}>
                    <div style={{textAlign: 'center', paddingTop: '20px'}}>
                        
                        <h2>
                            {this.formatarDinheiro(this.state.valorAcaoCard[acao.nome])} 
                            {/*<i className={acao.cotacaoValida?'':'pi pi-exclamation-triangle'} 
                                title="Cotação não atualizada! Utilizando última disponível"
                                style={{'fontSize': '1em'}}/>*/}
                        </h2>
                        <ProgressBar value={((this.state.valorAcaoCard[acao.nome]/this.state.valorTotal)*100).toFixed(2)} />
                                                
                    </div>

                    <div className="p-grid">
                    
                        <div className="p-col-6" style={{paddingTop: '10px'}}>
                            Preço Médio: 
                        </div>                            
                        <div className="p-col-6" style={{textAlign: 'right', paddingTop: '10px'}}>
                            <span className={this.calcularPrecoMedio(acao)-acao.cotacaoAtual>=0?'customer-badge status-unqualified':'customer-badge status-qualified'}>
                                {this.formatarDinheiro(this.calcularPrecoMedio(acao))}
                            </span>
                        </div>

                        <div className="p-col-6" style={{paddingTop: '0px'}}>
                            Rendimentos: 
                        </div>                            
                        <div className="p-col-6" style={{textAlign: 'right', paddingTop: '0px'}}>
                            {Utils.formatarDinheiro(this.calculaTotalRendimentos(acao))}
                            {' ('+this.calcularPercentualRendimentos(acao)+'%)'}
                        </div>                        

                        <div className="p-col-4" style={{paddingTop: '0px'}}>
                            Retorno: 
                        </div>                            
                        <div className="p-col-8" style={{textAlign: 'right', paddingTop: '0px'}}>
                            <span className={this.calculaTotalRetorno(acao)<0?'customer-badge status-unqualified':'customer-badge status-qualified'}>
                                {Utils.formatarDinheiro(this.calculaTotalRetorno(acao))}
                                {' ('+this.calcularPercentualRetorno(acao)+'%)'}
                            </span>
                        </div>
                        
                        <div className="p-col-6" style={{paddingTop: '0px'}}>
                            % Patrimônio: 
                        </div>                            
                        <div className="p-col-6" style={{textAlign: 'right', paddingTop: '0px'}}>
                            {((this.state.valorAcaoCard[acao.nome]/this.state.patrimonioTotal)*100).toFixed(2)}%
                        </div>
                    </div>
                </CardAcao>
                {
                    //<ExtratoAcaoList acao={acao} onDeleteFunction={this.onHideFunctionEdit}/>
                }

            </div>
        )
    }

    gerarCardNovo() {
        return (
            <div className="p-col-12 p-lg-3 p-sm-12">
                <Card title="" style={{minHeight: boxSize}}>


                    <div className="p-col-12"  
                        style={{textAlign: 'center', marginTop: '60px'}}>
                        
                        <Button icon="pi pi-plus" className="p-button-secondary p-button-rounded"
                            style={{'fontSize': '2em'}} onClick={ () => {
                                this.setState({acao:{}})
                                this.setState({displayDialog:true}) 
                            }}/> <br/>
                        Cadastrar Novo
                    </div>

                </Card>                         
            </div>
        )
    }

    buscarAcoes() {
       axios.get(acaoBaseUrl+'?sort=nome&userId='+Constantes.getUserId()+'&objetivoId='+this.state.classe._id).then(
            resp => {
                this.setState( {listaAcao: resp.data} )

                for(const a of this.state.listaAcao) {                    
                    const valoresAtualizados = {...this.state.valorAcaoCard}
                    const validadeAtualizada = {...this.state.validadeAcaoCard}

                    valoresAtualizados[a.nome] = a.cotacaoAtual
                    validadeAtualizada[a.nome] = false

                    this.setState({valorAcaoCard: valoresAtualizados})  
                    this.setState({validadeAcaoCard: validadeAtualizada}) 

                    let valor = a.quantidadeAtual * a.cotacaoAtual
                    valoresAtualizados[a.nome] = valor    
                    this.setState({valorAcaoCard: valoresAtualizados})    

                    //Atualizar com o serviço
                    //this.buscarCotacao(a)
                }  
                
                this.calcularPercentualMeta()                
            }
        ) 
    }

    buscarCotacao(acao) {

        Utils.buscarCotacaoAcao(acao,this.props.sufixoCotacao).then(
            resp => {
                acao.cotacaoAtual = resp.valor
                acao.cotacaoValida = resp.valido
                
                const valoresAtualizados = {...this.state.valorAcaoCard}
                const validadeAtualizada = {...this.state.validadeAcaoCard}               

                valoresAtualizados[acao.nome] = acao.quantidadeAtual * acao.cotacaoAtual
                validadeAtualizada[acao.nome] = acao.cotacaoValida

                this.setState({valorAcaoCard: valoresAtualizados})   
                this.setState({validadeAcaoCard: validadeAtualizada})     
                
                let total = 0
                for(const a of this.state.listaAcao) {
                    total += this.state.valorAcaoCard[a.nome]
                }
                this.setState( {valorTotal: total} )
            }
        )
    }

    onHideFunctionNovo() {
        this.buscarAcoes()
        this.setState({displayDialog:false})
    }

    onHideFunctionEdit() {
        this.buscarAcoes()
        this.setState({displayDialogEdit:false})
    }

    onHideFunctionRendimento() {
        this.setState({displayDialogRendimentos:false})
    }

    onHideFunctionMovimentacao() {
        this.setState({displayDialogMovimentacao:false})
    }

    onDeleteFunctionMovimentacao() {
        this.buscarAcoes()
        this.setState({displayDialogMovimentacao:false})
    }

    calculaTotalRendimentos(acao) {
        return AcaoUtils.calculaTotalRendimentos(acao,this.props.tipoClasse, this.state.dolar)
    }

    calcularPercentualRendimentos(acao) {
        var rendimento = AcaoUtils.calculaTotalRendimentos(acao,this.props.tipoClasse, this.state.dolar)
        var valorPago = AcaoUtils.calcularValorPago(acao,this.props.tipoClasse, this.state.dolar)

        return ((rendimento/valorPago)*100).toFixed(2)
    }

    calculaTotalRetorno(acao) {
        return AcaoUtils.calculaTotalRetorno(acao,this.props.tipoClasse, this.state.dolar) 
    }

    calcularPercentualRetorno(acao) {
        var retorno = AcaoUtils.calculaTotalRetorno(acao,this.props.tipoClasse, this.state.dolar)
        var valorPago = AcaoUtils.calcularValorPago(acao,this.props.tipoClasse, this.state.dolar)

        return ((retorno/valorPago)*100).toFixed(2)
    }

    formatarDinheiro(valor) {
        return Utils.formatarDinheiro(valor)
    }

    async exibirCotacaoDolar() {
        await Utils.buscarCotacaoDolar().then(
            dolar => { 
                this.setState({dolar: dolar})
            }
        )
             
    }

    render() {
        if ( this.state.isLoading )
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
        else 
        return(
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12">
                    <Toast ref={(el) => this.messages = el} />
                </div>

                <div className="p-lg-12">
                    <div className="card summary">
                        <span className="title" style={{fontSize: '2em'}}>
                            {this.state.classe.nome}
                        </span>
                        <span className="detail">
                            Meta: {this.state.classe.percentual}% ({this.formatarDinheiro(this.state.valorMeta)})
                            <ProgressBar value={this.state.percentualMeta} />
                        </span>
                        <span className="count visitors">{this.formatarDinheiro(this.state.valorTotal)}</span>
                        
                        
                        {//<PatrimonioAreaChart valorAtual={this.state.valorTotal} classe={this.state.classe}/> 
                        }
                        
     
                        <CardAcaoResumo 
                            listaAcao={this.state.listaAcao}
                            tipoClasse={this.state.classe.tipo}
                            dolar={this.state.dolar}/>
                    </div>
                </div>

                <div className="p-col-12">
                    <div className="">
                        <div className="p-grid p-fluid">
                            
                            <div className="p-col-12 p-md-6">
                                <Panel header="Segmentos">
                                    <DiversificacaoChart tipo={this.state.classe.tipo} />
                                </Panel>
                            </div>

                            <div className="p-col-12 p-md-3">
                                <TopValores patrimonio={this.state.valorTotal} 
                                    showMoney={true} 
                                    quantidade={5}
                                    objetivoId={this.state.classe._id}
                                    header="Maiores %"
                                    legenda=""/>
                            </div>

                            <div className="p-col-12 p-md-3">
                                <TopValores patrimonio={this.state.valorTotal} 
                                    showMoney={true}
                                    quantidade={5}
                                    objetivoId={this.state.classe._id}
                                    header="Menores %" 
                                    legenda=""
                                    sentido='bottom'/>
                            </div>

                            {this.state.listaAcao.map( this.gerarCard )}

                            {this.gerarCardNovo()}

                            <CriarAcaoDialog header="Cadastrar Novo Ativo" 
                                display={this.state.displayDialog} 
                                tipoClasse={this.state.classe.tipo}
                                userId={Constantes.getUserId()} objetivoId={this.state.classe._id}
                                onHideFunction={this.onHideFunctionNovo}/>

                            <EditarAcaoDialog header="Adicionar Movimentação" 
                                display={this.state.displayDialogEdit} 
                                tipoClasse={this.state.classe.tipo}
                                ativo={this.state.acao}
                                onHideFunction={this.onHideFunctionEdit}/>

                            <RendimentosDialog 
                                display={this.state.displayDialogRendimentos} 
                                ativo={this.state.acao}
                                tipoClasse={this.state.classe.tipo}
                                onHideFunction={this.onHideFunctionRendimento}
                                />

                            <MovimentacaoDialog 
                                display={this.state.displayDialogMovimentacao} 
                                ativo={this.state.acao}
                                tipoClasse={this.state.classe.tipo}
                                onHideFunction={this.onHideFunctionMovimentacao}
                                onDeleteFunction={this.onDeleteFunctionMovimentacao}
                                />

                            <ConfirmDialog header="Excluir Ativo"
                                visible={this.state.excluirDialog} 
                                message="Tem certeza que deseja excuir?"
                                funcaoSim={ () => {
                                    axios.delete(acaoBaseUrl+this.state.acaoId).then(resp => {
                                        if ( resp.status === 204 ) 
                                            this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Ativo excluído com Sucesso'});
                                        else    
                                            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao excluir Ativo'});
                                        
                                        this.buscarAcoes()
                                        this.setState({excluirDialog: false})
                                        }
                                    )
                                    }
                                }
                                funcaoNao={() => this.setState({excluirDialog: false})}
                            />

                        </div>

                        <div  className="p-grid p-justify-end">   

                            <div className="p-col-2">
                                <Button icon="pi pi-refresh" 
                                    className="p-button-sm p-button-rounded p-button-secondary"
                                    title="Atualizar Cotações"
                                    iconPos="left"
                                    label={'Dolar: '+this.state.dolar}
                                    onClick={ () => {
                                        this.exibirCotacaoDolar()
                                        this.buscarAcoes()
                                    }}/> 
                            </div>

                        </div>
                    </div>
                </div>
            
            </div>
        )
                    
    }
}