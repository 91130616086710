import React, { Component } from 'react'
import { Panel } from 'primereact/panel'
import {Constantes} from '../generico/Constantes'
import {TopValoresItem} from './TopValoresItem'

import axios from 'axios'
const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

/*
    Parametros: 
        - patrimonio: Patrimonio Total Atual - Obrigatório
        - quantidade: Quantidade de Registros (Default: 5)
        - sentido: Se 'Top' ou 'Bottom'
        - showMoney: Exibir ou nao os valores monetarios
        - header: cabeçalho
        - objetivoId: Caso queira filtrar classe específica
        - legenda: Texto para header do %. Padrão: '% Patrimonio'
*/
export class TopValores extends Component {

    constructor(props) {
        super(props)

        this.state = {
            quantidade: 5,
            sentido: 'top',
            listaAtivo: {},
            objetivoId: {},
            isLoading: true,
            legenda: '% Patrimonio'
        }
    }


    componentDidMount() {
       
        let objetivoId = ''
        if (typeof this.props.objetivoId !== 'undefined' ) {
            objetivoId = this.props.objetivoId
            this.setState({objetivoId: objetivoId})
        }

        let sentido = this.state.sentido
        if (typeof this.props.sentido !== 'undefined' 
                && this.props.sentido === 'bottom' ) {
                    sentido = 'bottom'
                    this.setState({sentido: sentido})
        }

        let quantidade = this.state.quantidade
        if (typeof this.props.quantidade !== 'undefined' ) {
            quantidade = this.props.quantidade
            this.setState({quantidade: quantidade})
        }

        let legenda = this.state.legenda
        if (typeof this.props.legenda !== 'undefined' ) {
            legenda = this.props.legenda
            this.setState({legenda: legenda})
        }

        if ( objetivoId !== '' ) {
            objetivoId = '&objetivoId='+objetivoId
        }

        //Buscar todas acoes do usuário
        axios.get(acaoBaseUrl+'?sort=nome&userId='+Constantes.getUserId()+objetivoId).then(
            resp => {
                let listaAcao = resp.data

                for(var ativo of listaAcao ) {
                    ativo.valor = ativo.quantidadeAtual * ativo.cotacaoAtual
                }

                //Ordena pelo valor
                let sortedList = listaAcao.sort( (a,b) => {
                    return b.valor - a.valor
                })

                //Pega a quantidade no sentido desejado
                if ( sentido === 'bottom' ) {
                    this.setState( {listaAtivo: sortedList.slice(-quantidade, sortedList.lenght)} ) 
                }
                else {
                    this.setState( {listaAtivo: sortedList.slice(0, quantidade)} )
                }
                this.setState({isLoading: false})
            }
        )
    }

    render() {
        const gerarItens = itens => {
            return itens.map( 
                item => <TopValoresItem 
                    key={item.nome}
                    item={item} 
                    showMoney={this.props.showMoney} 
                    patrimonio={this.props.patrimonio}
                    legenda={this.state.legenda}/>
            )
        }

        if ( this.state.isLoading ) {
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '1em'}}></i>
        }
        else if ( typeof this.props.header !== 'undefined' && this.props.header === '') {
            return gerarItens(this.state.listaAtivo)
        }
        else {
            return (


                <Panel header={ typeof this.props.header === 'undefined' ?
                    'Top '+(this.state.sentido==='bottom'?'-':'')+this.state.quantidade : this.props.header}>

                    {gerarItens(this.state.listaAtivo)}

                </Panel>
            )
        }
    }
}