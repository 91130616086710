import React, { Component } from 'react'
import {Utils} from '../generico/Utils'
import {HeaderCircle} from '../generico/HeaderCircle'

export class TopValoresItem extends Component {


    gerarLengenda() {

        if ( this.props.legenda !== '' ) {
            return (
                <div>
                    {this.props.legenda}<br/>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="cardBox p-grid p-align-center">
                <div className="p-col-4" style={{textAlign: 'center'}}>
                    <HeaderCircle text={this.props.item.nome}/>                    
                </div>
                <div className="p-col-4" style={{textAlign: 'right'}}>
                    {Utils.formatarDinheiro(this.props.item.valor,this.props.showMoney)}
                </div>
                <div className="p-col-4" style={{textAlign: 'right'}}>
                    {this.gerarLengenda()} 
                    {((this.props.item.valor/this.props.patrimonio)*100).toFixed(2)}% 
                </div>
            </div>
        )
    }

}