import React, { Component } from 'react'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'
import {InputText} from 'primereact/inputtext'
import {Calendar} from 'primereact/calendar'
import {Toast} from 'primereact/toast'
import {Constantes} from '../generico/Constantes'
import MoedaInput from '../generico/MoedaInput'

import axios from 'axios'
const rfBaseUrl = Constantes.buscarUrlBase()+'rendafixa/'

export class CriarRFDialog extends Component {

    constructor(props) {
        super(props)

        this.state = {
            ativo: {},
            movimentacao: {}
        }

        this.salvarAtivo = this.salvarAtivo.bind(this)
        this.fecharDialog = this.fecharDialog.bind(this)
        this.gerarInputVencimento = this.gerarInputVencimento.bind(this)
    }

    salvarAtivo() {
        if (typeof this.state.ativo.nome === 'undefined' || this.state.ativo.nome === '') {
            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'O Nome é Obrigatório!'})
            return
        }

        var novoAtivo = {
            userId: this.props.userId,
            objetivoId: this.props.objetivoId,
            nome: this.state.ativo.nome,
            dataVencimento: this.state.ativo.dataVencimento,
            extrato: [
                {
                    dataAtualizacao: this.state.movimentacao.data,
                    saldo: this.state.movimentacao.valor
                }
            ]
        }

        axios.post(rfBaseUrl,{...novoAtivo}).then(resp => {     
                if ( resp.status === 201 || resp.status === 200) {
                    this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Ativo cadastrado com Sucesso'})                    
                    this.fecharDialog()
                }
                else    
                    this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao cadastrar Ativo'});
            }
        )
        
    }

    fecharDialog() {
        this.setState({ativo: {}, movimentacao: {}})
        this.props.onHideFunction()
    }

    gerarInputVencimento() {
        const vencimento = (
            <>
            <div className="p-col-12 p-md-2">
                <label htmlFor="data">Vencimento</label>
            </div>
            <div className="p-col-12 p-md-10">
                <Calendar id="data" dateFormat="dd/mm/yy" style={{zIndex:1000}}
                    monthNavigator={true} yearNavigator={true} yearRange="2000:2030"
                    tooltip="Data fim do contrato"
                    appendTo={document.body}
                    value={this.state.ativo.dataVencimento} 
                    onChange={(e) => this.setState( {ativo: {
                        ...this.state.ativo,
                        dataVencimento: e.target.value
                    }
                })}/>
            </div>
            </>
        )

        if ( this.props.tipoClasse !== 're') return vencimento
        else return(<> </>)
    }

    render() {
        
        const footer = (
            <div>
                <Button label="Salvar" icon="pi pi-check" onClick={ this.salvarAtivo }/>
                <Button label="Cancelar" icon="pi pi-times" onClick={ this.fecharDialog }/>
            </div>
        )

        return (

            <Dialog header={this.props.header} visible={this.props.display} modal={true}
                footer={footer} style={{width: Constantes.tamanhoDialog()}} onHide={this.fecharDialog}>
                    
                <Toast ref={(el) => this.messages = el} />
                    
                <div className="p-grid">                        
                    <div className="p-col-12 p-md-2">
                        <label htmlFor="nome">Nome</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <InputText id="nome"
                            tooltip="Nome para identificação"
                            value={this.state.ativo.nome} onChange={(e) => this.setState( {ativo: {
                                ...this.state.ativo,
                                nome: e.target.value
                            }
                        })}/>
                    </div>
                                        
                    {this.gerarInputVencimento()}
                                        
                    <div className="p-col-12 p-md-2">
                        <label htmlFor="data">Data</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <Calendar id="data" dateFormat="dd/mm/yy" style={{zIndex:1000}}
                            monthNavigator={true} yearNavigator={true} yearRange="2000:2030"
                            tooltip="Data da atualização do Saldo"
                            appendTo={document.body}
                            value={this.state.movimentacao.data} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                data: e.target.value
                            }
                        })}/>
                    </div>

                    <div className="p-col-12 p-md-2">
                        <label htmlFor="valor">Saldo</label>
                    </div>
                    <div className="p-col-12 p-md-10">
                        <MoedaInput placeholder="0.00" type="text"                         
                            value={this.state.movimentacao.valor} 
                            onChange={(e) => this.setState( {movimentacao: {
                                ...this.state.movimentacao,
                                valor: e.target.value
                            }
                        })}/>
                    </div>

                </div>
            </Dialog>
        )
    }
}