import React, { Component } from 'react'
import {Card} from 'primereact/card'
import {Button} from 'primereact/button'
import {ConfirmDialog} from '../generico/ConfirmDialog'
import {Toast} from 'primereact/toast'
import {ProgressBar} from 'primereact/progressbar'
import {CriarRFDialog} from './CriarRFDialog'
import {EditarRFDialog} from './EditarRFDialog'
import {Constantes} from '../generico/Constantes'
import {Dialog} from 'primereact/dialog'
import {Calendar} from 'primereact/calendar'
import {InputText} from 'primereact/inputtext'
import {Utils} from '../generico/Utils'
import {ExtratoRFList} from './ExtratoRFList'

import axios from 'axios'

const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'
const rfBaseUrl = Constantes.buscarUrlBase()+'rendafixa/'

const boxSize = '280px'

const moneyFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

export class ListaRF extends Component {

      
    constructor(props) {
        super(props);
        this.state = {
            ativo: {},
            classe: {},
            listaAtivo: [],
            valorTotal: 0,
            ativoId: {},
            displayDialog: false,
            displayDialogEdit:false,
            displayDialogValor: false,
            excluirDialog: false
        }

        axios.get(objBaseUrl+'?userId='+Constantes.getUserId()+'&tipo='+props.tipoClasse).then(
            resp => {
                this.setState( {classe: resp.data[0]} )                
                this.buscarAtivos()
            }
        ) 

        this.gerarCard = this.gerarCard.bind(this)        
        this.gerarCardNovo = this.gerarCardNovo.bind(this)    
        this.onHideFunctionNovo = this.onHideFunctionNovo.bind(this)
        this.onHideFunctionEdit = this.onHideFunctionEdit.bind(this)    
        this.onHideFunctionValor = this.onHideFunctionValor.bind(this)
        this.buscarAtivos = this.buscarAtivos.bind(this)
        this.formatarDinheiro = this.formatarDinheiro.bind(this)
        this.salvarEdit = this.salvarEdit.bind(this)
        this.calcularPercentualMeta = this.calcularPercentualMeta.bind(this)
        this.gerarInputVencimento = this.gerarInputVencimento.bind(this)
    }

    calcularPercentualMeta() {
        Utils.calcularValorPorObjetivoSemServico().then(
            objetivos => {
                let tipo = this.state.classe.tipo

                let valorTotal = 0
                let valorClasse = 0
                for( var o of objetivos ) {
                    if (o.tipo === tipo) {
                        valorClasse = o.valorTotal
                    }
                    valorTotal += o.valorTotal
                }

                this.setState({valorTotal: valorClasse})

                let valorObjetivo = (valorTotal * this.state.classe.percentual)/100 
                let percentualMeta = (valorClasse/valorObjetivo)*100

                this.setState({percentualMeta: valorClasse===0?0:percentualMeta.toFixed(2)})
                this.setState({valorMeta:valorObjetivo})
                this.setState({patrimonioTotal: valorTotal})
            }
        )
    }

    salvarEdit() {
        if (typeof this.state.ativo.nome === 'undefined' || this.state.ativo.nome === '') {
            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'O Nome é Obrigatório!'})
            return
        }
        
        axios.put(rfBaseUrl+this.state.ativo._id, {...this.state.ativo}).then(resp => {
            if ( resp.status === 200 ) {
                this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Ativo atualizado com Sucesso'})
                this.onHideFunctionEdit()
            }
            else    
                this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao atualizar Ativo'});
            }
        )
    }

    gerarCard(ativo) {
        return (
            <div className="p-col-12 p-lg-4" id={'div'+ativo._id}>
                
                <Card title={ativo.nome} 
                    subTitle={ this.props.tipoClasse !== 're'?'Vencimento: '+ Utils.formatarData(ativo.dataVencimento):'Reserva de Emergência'} 
                    style={{minHeight: boxSize, maxHeight: boxSize}} id={ativo._id}>

                    <div style={{textAlign: 'center', paddingTop: '20px'}}>
                        
                        <h2>
                            {this.formatarDinheiro(ativo.extrato.slice(-1)[0].saldo)}
                        </h2>
                        <ProgressBar value={((ativo.extrato.slice(-1)[0].saldo/this.state.valorTotal)*100).toFixed(2)} />
                        
                        <div className="p-grid">
                            <div className="p-col-6" style={{textAlign: 'left'}}>
                                % Patrimônio: 
                            </div>                            
                            <div className="p-col-6" style={{textAlign: 'right'}}>
                                {((ativo.extrato.slice(-1)[0].saldo/this.state.patrimonioTotal)*100).toFixed(2)}%
                            </div>
                        </div>

                        <Button icon="pi pi-pencil" className="p-button-secondary p-button-rounded"
                            style={{marginTop: '25px', marginRight: '5px'}} tooltip="Editar"
                            onClick={ () => {
                                this.setState({displayDialogEdit:true})
                                this.setState({ativo: {...ativo}})
                            }} />

                        <Button icon="pi pi-dollar" className="p-button-rounded"
                            style={{marginTop: '25px', marginRight: '5px'}} tooltip="Ajustar Valor"
                            onClick={ () => {
                                this.setState({displayDialogValor:true})
                                this.setState({ativo: {...ativo}})
                                this.setState({extrato: {}})
                            }} />  

                        <Button icon="pi pi-trash" className="p-button-secondary p-button-rounded"
                            tooltip="Excluir"
                            style={{marginTop: '25px'}} onClick={ () => {
                                    this.setState({ativoId: ativo._id})
                                    this.setState({excluirDialog: true})
                                }
                            }/>                                      
                    </div>   
                    
                </Card> 
                
                <ExtratoRFList ativo={ativo} onDeleteFunction={this.onHideFunctionEdit}/>
            </div>
        )
    }

    gerarCardNovo() {
        return (
            <div className="p-col-12 p-lg-4 p-sm-12">
                <Card title="" style={{minHeight: boxSize}}>

                    <div className="p-col-12"  
                        style={{textAlign: 'center', marginTop: '60px'}}>
                        
                        <Button icon="pi pi-plus" className="p-button-secondary p-button-rounded"
                            style={{'fontSize': '2em'}} onClick={ () => {
                                this.setState({ativo:{}})
                                this.setState({displayDialog:true}) 
                            }}/> <br/>
                        Cadastrar Nova
                    </div>

                </Card>                         
            </div>
        )
    }

    buscarAtivos() {
       axios.get(rfBaseUrl+'?sort=nome&userId='+Constantes.getUserId()+'&objetivoId='+this.state.classe._id).then(
            resp => {
                this.setState( {listaAtivo: resp.data} )
                this.calcularPercentualMeta()     
            }
        ) 
    }

    onHideFunctionNovo() {
        this.setState({displayDialog:false})
        this.setState({ativo: {}})
        this.buscarAtivos()
    }

    onHideFunctionEdit() {
        this.setState({displayDialogEdit:false})
        this.setState({ativo: {}})
        this.buscarAtivos()
    }

    onHideFunctionValor() {
        this.setState({displayDialogValor:false})
        this.setState({ativo: {}})
        this.buscarAtivos()
    }

    formatarDinheiro(valor) {
        return moneyFormat.format(valor)
    }

    gerarInputVencimento() {
        const vencimento = (
            <>
            <div className="p-col-12 p-md-2">
                <label htmlFor="data">Vencimento</label>
            </div>
            <div className="p-col-12 p-md-10">
                <Calendar id="data" dateFormat="dd/mm/yy" style={{zIndex:1000}}
                    monthNavigator={true} yearNavigator={true} yearRange="2000:2030"
                    tooltip="Data fim do contrato"
                    appendTo={document.body}
                    value={this.state.ativo.dataVencimento} 
                    onChange={(e) => this.setState( {ativo: {
                        ...this.state.ativo,
                        dataVencimento: e.target.value
                    }
                })}/>
            </div>
            </>
        )

        if ( this.props.tipoClasse !== 're') return vencimento
        else return(<> </>)
    }

    render() { 
        const footerEdit = (
            <div>
                <Button label="Salvar" icon="pi pi-check" onClick={this.salvarEdit}/>
                <Button label="Cancelar" icon="pi pi-times" onClick={this.onHideFunctionEdit}/>
            </div>
        )

        return(
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12">
                    <Toast ref={(el) => this.messages = el} />
                </div>

                <div className="p-col-12 p-lg-12">
                    <div className="card summary">
                        <span className="title" style={{fontSize: '2em'}}>
                            {this.state.classe.nome}
                        </span>
                        <span className="detail">
                            Meta: {this.state.classe.percentual}% ({this.formatarDinheiro(this.state.valorMeta)})
                            <ProgressBar value={this.state.percentualMeta} />
                        </span>
                        <span className="count visitors">{this.formatarDinheiro(this.state.valorTotal)}</span>
                    </div>
                </div>

                <div className="p-col-12">
                    <div className="">
                        <div className="p-grid p-fluid">

                            {this.state.listaAtivo.map( this.gerarCard )}

                            {this.gerarCardNovo()}

                            <CriarRFDialog header="Cadastrar Novo Ativo" 
                                display={this.state.displayDialog}  tipoClasse={this.props.tipoClasse}
                                userId={Constantes.getUserId()} objetivoId={this.state.classe._id}
                                onHideFunction={this.onHideFunctionNovo}/>

                            <Dialog header="Editar Ativo" visible={this.state.displayDialogEdit} modal={true}
                                footer={footerEdit} style={{width: Constantes.tamanhoDialog()}} onHide={this.onHideFunctionEdit}>

                                <Toast ref={(el) => this.messages = el} />
                                    
                                <div className="p-grid">     

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="nome">Nome</label>
                                    </div>
                                    <div className="p-col-12 p-md-10">
                                        <InputText id="nome"
                                            tooltip="Nome de identificação do Ativo"
                                            value={this.state.ativo.nome} onChange={(e) => this.setState( {ativo: {
                                                ...this.state.ativo,
                                                nome: e.target.value
                                            }
                                        })}/>
                                    </div>
                                                        
                                    {this.gerarInputVencimento()}

                                </div>
                            </Dialog>

                            <EditarRFDialog header="Atualizar Saldo" 
                                display={this.state.displayDialogValor} 
                                ativo={this.state.ativo}
                                onHideFunction={this.onHideFunctionValor}/>

                            <ConfirmDialog header="Excluir Ativo"
                                visible={this.state.excluirDialog} 
                                message="Tem certeza que deseja excuir?"
                                funcaoSim={ () => {
                                    axios.delete(rfBaseUrl+this.state.ativoId).then(resp => {
                                        if ( resp.status === 204 ) 
                                            this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Ativo excluído com Sucesso'});
                                        else    
                                            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao excluir Ativo'});
                                        
                                        this.buscarAtivos()
                                        this.setState({excluirDialog: false})
                                        }
                                    )
                                    }
                                }
                                funcaoNao={() => this.setState({excluirDialog: false})}
                            />

                        </div>
                    </div>
                </div>

            
            </div>
        )
                    
    }
}