import React, { Component } from 'react'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputText} from 'primereact/inputtext'
import {Toast} from 'primereact/toast'
import {Constantes} from '../generico/Constantes'

import axios from 'axios'

const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'

export class ListaObjetivo extends Component {

    constructor() {
        super()

        this.state = {
            listaObjetivo: []
        }
        this.objCopia = {}

        axios.get(objBaseUrl+'?sort=ordem&userId='+Constantes.getUserId()).then(
            resp => {
                this.setState( {listaObjetivo: resp.data} )
            }
        )     
        
        this.editorForRowEditing = this.editorForRowEditing.bind(this);
        this.onRowEditorValidator = this.onRowEditorValidator.bind(this);
        this.onRowEditInit = this.onRowEditInit.bind(this);
        this.onRowEditSave = this.onRowEditSave.bind(this);
        this.onRowEditCancel = this.onRowEditCancel.bind(this);
    }

    /*Row Edit*/
    percentualTenplate(rowData, column) {
        return <span>{parseFloat(rowData.percentual).toFixed(2)}%</span>;
    }

    onEditorValueChangeForRowEditing(props, value) {
        let updatedFields = [...props.value];
        updatedFields[props.rowIndex][props.field] = value;
        this.setState({listaObjetivo: updatedFields});
    }

    editorForRowEditing(props, field) {
        return <InputText type="text" value={props.rowData[field]} 
            onChange={(e) => this.onEditorValueChangeForRowEditing(props, e.target.value)} />;
    }

    onRowEditorValidator(rowData) {
        let value = rowData['percentual'];
        return value >= 0 && value.length > 0;        
    }

    onRowEditInit(event) {
        this.objCopia[event.data._id] = {...event.data}; 
    }

    onRowEditSave(event) {
        if (this.onRowEditorValidator(event.data)) {

            axios.put(objBaseUrl+event.data._id, {...event.data}).then ( 
                () => {
                    delete this.objCopia[event.data._id]
                    if (this.totalPercentual()>100) 
                        this.messages.show({severity: 'warn', summary: 'Atenção!', detail: 'Percentual maior do que 100%'});
                    else
                        this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Objetivo atualizado com Sucesso'});
                }
            )
        }
        else {
            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Percentual inválido'});
        }
    }

    onRowEditCancel(event) {
        let objOriginal = [...this.state.listaObjetivo];
        objOriginal[event.index] = this.objCopia[event.data._id];
        this.setState({
            listaObjetivo: objOriginal
        })
    }

    totalPercentual() {
        var total = 0
        var lista = this.state.listaObjetivo
        for ( var i = 0; i < lista.length; i++ )
            total += parseFloat(lista[i].percentual)
        return parseFloat(total)
    }

    render() {
        let footer = <div className="p-clearfix">
            <div style={{float:'right', width:'25%'}}>
               {this.totalPercentual().toFixed(2)}%
            </div>
        </div>

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <Toast ref={(el) => this.messages = el}/>
                </div>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Seus Objetivos</h1>
                            <DataTable value={this.state.listaObjetivo} 
                                resizableColumns={true} columnResizeMode="fit" footer={footer} editMode="row" 
                                rowEditorValidator={this.onRowEditorValidator} onRowEditInit={this.onRowEditInit} 
                                onRowEditSave={this.onRowEditSave} onRowEditCancel={this.onRowEditCancel}>

                                <Column field="nome" header="Classe"/>
                                <Column field="percentual" header="Objetivo"
                                    body={this.percentualTenplate}
                                    editor={(props) => this.editorForRowEditing(props, 'percentual')}
                                    headerStyle={{textAlign:'center'}} style={{textAlign:'right', width:'20%'}}/>
                                <Column rowEditor={true} style={{'width': '70px', 'textAlign': 'center'}}></Column>

                            </DataTable>
                    </div>
                </div>
            </div>
        )
    }
}