import React, { Component } from 'react'
import {Constantes} from '../generico/Constantes'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {Utils} from '../generico/Utils'
import { Sidebar } from 'primereact/sidebar'
import {ConfirmDialog} from '../generico/ConfirmDialog'
import axios from 'axios'

const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

export class MovimentacaoDialog extends Component {

    constructor(props) {        
        super(props)
        this.state = {
            excluirDialog: false,
            rendimento: {},
            lista: {},
            dolar: 1
        }
        Utils.buscarCotacaoDolar().then(valor => this.setState({dolar:valor}))
        this.fecharDialog = this.fecharDialog.bind(this)
    }

    fecharDialog() {
        this.props.onHideFunction()
    }

    actionBodyTemplate(mov) {
        return (
            <Button icon="pi pi-trash" className="p-button-secondary p-button-sm"
                tooltip="Excluir" onClick={ () => {
                    this.setState({movimentacao: {...mov}})
                    this.setState({excluirDialog: true})
                } }/> 
        );
    }

    buscarValor(row) {
        let valor = row.preco
        if (this.props.tipoClasse === 'stock' ||  this.props.tipoClasse === 'reit')
            valor = valor * this.state.dolar

        return (<span>{Utils.formatarDinheiro(valor)}</span>)
    }

    tipoMovimentacao(mov) {
        if (mov.operacao === '-') return 'Venda'
        else return 'Compra'
    }

    quantidadeComTipoMovimentacao(mov) {
        if (mov.operacao === '-') return '-'+mov.quantidade
        else return mov.quantidade
    }

    render() {

        return (


            <Sidebar visible={this.props.display} onHide={this.fecharDialog} 
                baseZIndex={1000} position="right" style={{width: '40%', overflow: 'scroll'}}>
                
                <span className="p-card-title">Movimentações</span>
                <br/><br/><br/>
                <DataTable value={this.props.ativo.movimentacao} footer={this.tableFooter}>

                    <Column field="data" header="Data" sortable={true}
                        headerStyle={{textAlign:'center'}} style={{textAlign:'center'}}
                        body={(row) => <span>{Utils.formatarData(row.data)}</span>} />

                    <Column field="operacao" header="Tipo" sortable={true}
                        headerStyle={{textAlign:'center'}} style={{textAlign:'center'}}
                        body={(row) => <span>{this.tipoMovimentacao(row)}</span>} />

                    <Column field="quantidade" header="Qtd"
                        headerStyle={{textAlign:'center'}} style={{textAlign:'center'}}
                        body={(row) => <span>{this.quantidadeComTipoMovimentacao(row)}</span>} />

                    <Column field="valor" header="Valor"
                        headerStyle={{textAlign:'center'}} style={{textAlign:'right', with: '1%'}}
                        body={ (row) => this.buscarValor(row)  }/>

                    <Column body={(row) => this.actionBodyTemplate(row)}  headerStyle={{width: '8em', textAlign: 'center'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />

                </DataTable>

                <ConfirmDialog header="Excluir Rendimento"
                    visible={this.state.excluirDialog} 
                    message="Tem certeza que deseja excuir?"
                    funcaoSim={ () => {

                        var acao = this.props.ativo
                        var mov = this.state.movimentacao

                        acao.movimentacao.splice(acao.movimentacao.indexOf(mov), 1);
                
                        let quantidadeTotal = 0
                        for(const m of acao.movimentacao) {
                
                            if ( m.operacao === '+' ) {
                                quantidadeTotal += m.quantidade
                            }
                            else if (m.operacao === '-') {
                                quantidadeTotal -= m.quantidade
                            }
                        }
                        
                        acao.quantidadeAtual = quantidadeTotal

                        axios.put(acaoBaseUrl+acao._id,{...acao}).then(resp => {     
                        if ( resp.status === 201 || resp.status === 200) {
                            this.props.onDeleteFunction()
                        }
                        else  {  
                            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao remover Movimentação'});
                        }
                        
                        this.setState({excluirDialog: false})
                        })

                        }
                    }
                    funcaoNao={() => this.setState({excluirDialog: false})}
                />


            </Sidebar>

            


        )
    }
}