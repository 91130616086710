import {Constantes} from '../generico/Constantes'
import axios from 'axios'

const objetivoUrl = Constantes.buscarUrlBase()+'objetivo/'
const acaoUrl = Constantes.buscarUrlBase()+'acao/'
const contaUrl = Constantes.buscarUrlBase()+'conta/'
const rendaFixaUrl = Constantes.buscarUrlBase()+'rendafixa/'
const paramUrl = Constantes.buscarUrlBase()+'parametro/'

const moneyFormat = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  
var parseString = require('xml2js').parseString

export class Utils {

    static formatarData(d) {
        let data = new Date(d)

        let dia = data.getDate()
        if ( dia<10 ) dia = '0'+dia

        let mes = (data.getMonth()+1)
        if ( mes<10 ) mes = '0'+mes


        let datestring =  dia  + "/" + mes + "/" +  data.getFullYear() 
        return datestring
    }

    static formatarDataHora(d) {
        let data = new Date(d)

        let dia = data.getDate()
        if ( dia<10 ) dia = '0'+dia

        let mes = (data.getMonth()+1)
        if ( mes<10 ) mes = '0'+mes

        let hora = data.getHours()
        let min = data.getMinutes()

        if (min < 10) min = '0'+min

        let datestring =  dia  + "/" + mes + "/" +  data.getFullYear() +' '+ hora + ':' +min
        return datestring
    }

    static formatarDataHoraMobile(d) {
        let data = new Date(d)
        let hoje = new Date()
        let isHoje = true

        let dia = data.getDate()
        if (dia !== hoje.getDate()) isHoje=false
        if ( dia<10 ) dia = '0'+dia

        let mes = (data.getMonth()+1)
        if (mes !== (hoje.getMonth()+1)) isHoje=false
        if ( mes<10 ) mes = '0'+mes

        if (data.getFullYear() !== hoje.getFullYear()) isHoje=false

        let hora = data.getHours()
        let min = data.getMinutes()

        if (min < 10) min = '0'+min

        let datestring = "";
        if (isHoje) datestring =  hora + ':' +min
        else datestring =  dia  + "/" + mes + "/" +  data.getFullYear() +' '+ hora + ':' +min
        return datestring
    }

    static formatarDinheiro(valor,showMoney) {
        if (showMoney==='false') return ''
        else return moneyFormat.format(valor)
    }

    static formatarPercentual(valor) {
        let result = 0
        if ( valor != null) result = valor
        return result.toFixed(2)+'%'
    }

    static async calcularValorPorObjetivo() {
                
        return new Promise( async (resolve, reject) => { 
            const userUrlQuery = '?userId='+Constantes.getUserId();
            const objUrlQuery = userUrlQuery+'&objetivoId='

            var listaObjetivo = {}
    
            await axios.get(objetivoUrl+userUrlQuery+'&sort=ordem').then(
                async objs => {
                    listaObjetivo = objs.data
    
                    for (let o of listaObjetivo) {
                          
                       switch ( o.tipo ) {
                            case 're':
                            case 'td':
                            case 'rf':
                                await axios.get(rendaFixaUrl+objUrlQuery+o._id).then(
                                    ativos => {
                                        let total = 0
                                        for (let c of ativos.data) total += c.extrato.slice(-1)[0].saldo
                                        o.valorTotal = total
                                    }
                                )
                            break

                            case 'fii':
                                await axios.get(acaoUrl+objUrlQuery+o._id).then(
                                    async ativos => {
                                        let total = 0                                        
                                        for (let acao of ativos.data) {
                                            await Utils.buscarCotacaoAcao(acao,'.SAO').then(
                                                cotacao => total += acao.quantidadeAtual * cotacao.valor
                                            )
                                        }
                                        o.valorTotal = total
                                    }
                                )
                            break

                            case 'acao':
                                await axios.get(acaoUrl+objUrlQuery+o._id).then(
                                    async ativos => {
                                        let total = 0                                        
                                        for (let acao of ativos.data) {
                                            await Utils.buscarCotacaoAcao(acao,'.SA').then(
                                                cotacao => total += acao.quantidadeAtual * cotacao.valor
                                            )
                                        }
                                        o.valorTotal = total
                                    }
                                )
                            break

                            case 'reit':
                            case 'stock':
                                await axios.get(acaoUrl+objUrlQuery+o._id).then(
                                    async ativos => {
                                        let total = 0                                        
                                        for (let acao of ativos.data) {
                                            await Utils.buscarCotacaoAcao(acao,'').then(
                                                cotacao => total += acao.quantidadeAtual * cotacao.valor
                                            )
                                        }
                                        o.valorTotal = total
                                    }
                                )
                            break

                            default:
                            break
                       }
                    }
                }
            )  
            resolve(listaObjetivo)
        })
    }

    static async buscarCotacaoAcao(acao, sufixo) {
        return new Promise( async (resolve, reject) => {
            
            let cotacao = {valor: acao.cotacaoAtual, valido: false}

            var dataAtualizacao = new Date( acao.dataAtualizacaoCotacao )
            var agora = new Date()

            //Atualizar apenas após 15 Minutos
            if ( ((agora - dataAtualizacao) / 60000) < 15 ) {
                cotacao.valido = true
                resolve(cotacao)

                return
            }

            const codigo = acao.nome.toUpperCase()// + sufixo
            
            let variacao = acao.ultimaVariacaoPercent

            if ( sufixo==='' ) {
                let dolar = 1
                await Utils.buscarCotacaoDolar().then( valor => dolar = valor)

                await axios.get(Constantes.urlCotacao()+codigo).then(
                    resp => {  
    
                        try {
                            cotacao.valor = parseFloat(resp.data['Global Quote']['05. price'])
                            variacao = parseFloat(resp.data['Global Quote']['10. change percent'])
                            cotacao.valido = true                           
                            
                            cotacao.valor = cotacao.valor*dolar                            
                        }
                        catch(e) {
                            //console.log('Erro ao buscar Cotacao de: '+codigo)
                        }    
                            
                    }
                )
            }
            else {
                await axios.get(Constantes.urlCotacaoB3()+codigo).then(
                    respCotacao => {

                        try {
                            var xml = respCotacao.data
                            parseString(xml, function (err, result) {
                                cotacao.valor =parseFloat(result.ComportamentoPapeis.Papel[0]["$"].Ultimo.replace(',','.'))
                                variacao = parseFloat(result.ComportamentoPapeis.Papel[0]["$"].Oscilacao.replace(',','.'))
                                cotacao.valido = true
                            });
                        }
                        catch(e) {
                            console.log('[INFO] Erro ao buscar Cotacao de: '+codigo)
                            console.log('[INFO] '+e)
                        }
                        
                    }
                )
            }   
            
            acao.cotacaoAtual = cotacao.valor
            acao.ultimaVariacaoPercent = variacao
            if ( cotacao.valido ) acao.dataAtualizacaoCotacao = new Date()

            axios.put(acaoUrl+acao._id,{...acao})
            resolve(cotacao)
        })
    }

    static async buscarCotacaoDolar() {

        return new Promise( async (resolve, reject) => {

            let valorDolar = 1
            var parametroDolar = {nome:'dolar'}

            await axios.get(paramUrl+'?nome=dolar').then( async param => {

                if (typeof param.data[0] != 'undefined' && typeof param.data[0]._id != 'undefined') {

                    parametroDolar._id = param.data[0]._id
                    parametroDolar.nome = param.data[0].nome
                    parametroDolar.valor = param.data[0].valor
                    parametroDolar.dataAtualizacao = param.data[0].dataAtualizacao

                    valorDolar = parametroDolar.valor

                    var diffMs = (new Date() - new Date(parametroDolar.dataAtualizacao))
                    var diffHrs = Math.floor((diffMs % 86400000) / 3600000)

                    if ( diffHrs<2 ) {
                        resolve(valorDolar)
                        return
                    }
                }

            })

            let d = new Date()
            let datestring = (d.getMonth()+1) + "-" + (d.getDate())  + "-" +  d.getFullYear() 

            await axios.get(Constantes.urlDolar()+"'"+datestring+"'").then(
                async resp => {
                
                    try {
                        let valor = parseFloat(resp.data.value[0].cotacaoCompra).toFixed(2)
                        valorDolar = valor
                        parametroDolar.valor = valor
                        parametroDolar.dataAtualizacao = new Date()

                        if (typeof parametroDolar._id === 'undefined') {                  
                            axios.post(paramUrl,{...parametroDolar})
                        }
                        else {
                            axios.put(paramUrl+parametroDolar._id,{...parametroDolar})
                        }
                    }
                    catch(e) { 
                        console.log("[INFO] "+e)                               
                    } 
                    resolve(valorDolar)
                } 
            )
        })
    }

    static async calcularValorPorObjetivoSemServico() {
        
        return new Promise( async (resolve, reject) => { 
            const userUrlQuery = '?userId='+Constantes.getUserId();
            const objUrlQuery = userUrlQuery+'&objetivoId='

            var listaObjetivo = {}
    
            await axios.get(objetivoUrl+userUrlQuery+'&sort=ordem').then(
                async objs => {
                    listaObjetivo = objs.data
    
                    for (let o of listaObjetivo) {
                          
                       switch ( o.tipo ) {
                            case 're':                            
                            case 'td':
                            case 'rf':
                                await axios.get(rendaFixaUrl+objUrlQuery+o._id).then(
                                    ativos => {
                                        let total = 0
                                        for (let c of ativos.data) total += c.extrato.slice(-1)[0].saldo
                                        o.valorTotal = total
                                    }
                                )
                            break

                            case 'fii':
                                await axios.get(acaoUrl+objUrlQuery+o._id).then(
                                    async ativos => {
                                        let total = 0                                        
                                        for (let acao of ativos.data) {                                            
                                            total += acao.quantidadeAtual * acao.cotacaoAtual                                            
                                        }
                                        o.valorTotal = total
                                    }
                                )
                            break

                            case 'acao':
                                await axios.get(acaoUrl+objUrlQuery+o._id).then(
                                    async ativos => {
                                        let total = 0                                        
                                        for (let acao of ativos.data) {                                           
                                            total += acao.quantidadeAtual * acao.cotacaoAtual
                                        }
                                        o.valorTotal = total
                                    }
                                )
                            break

                            case 'reit':
                            case 'stock':
                                await axios.get(acaoUrl+objUrlQuery+o._id).then(
                                    async ativos => {
                                        let total = 0                                        
                                        for (let acao of ativos.data) {               
                                            total += acao.quantidadeAtual * acao.cotacaoAtual
                                        }
                                        o.valorTotal = total
                                    }
                                )
                            break
                       }
                    }
                }
            )  
            resolve(listaObjetivo)
        })
    }

    static getMesAno(data) {
        let mes = data.getMonth()+1
        if ( mes < 10 ) mes = '0'+mes 
        return (mes) + "/" +  data.getFullYear()
    }

    static getMesAnoKey(data) {
        let mes = data.getMonth()+1
        if ( mes < 10 ) mes = '0'+mes 
        return (mes) + "/" +  data.getFullYear()
    }

    static getAnoKey(data) {
        return data.getFullYear()
    }


    static async calcularPatrimonioPorMes() {

        let dolar = 1
        await Utils.buscarCotacaoDolar().then( valor => dolar = valor)
        
        return new Promise( async (resolve, reject) => { 
            const userUrlQuery = '?userId='+Constantes.getUserId();
            const objUrlQuery = userUrlQuery+'&objetivoId='

            var listaObjetivo = {}
            var patrimonio = {}
    
            await axios.get(objetivoUrl+userUrlQuery+'&sort=ordem').then(
                async objs => {
                    listaObjetivo = objs.data
    
                    for (let o of listaObjetivo) {
                          
                       switch ( o.tipo ) {   
                            case 're':
                                await axios.get(contaUrl+userUrlQuery).then(
                                    contas => {
                                        for (let c of contas.data) {

                                            let contaPorMes = {}
                                            for (let e of c.extrato) {

                                                let data = this.getMesAno(new Date(e['dataAtualizacao']))
                                                let key = this.getMesAnoKey(new Date(e['dataAtualizacao']))

                                                if (typeof contaPorMes[key] === 'undefined') {
                                                    contaPorMes[key] = {
                                                        valor: 0,
                                                        data: data
                                                    }
                                                }
                                                contaPorMes[key].valor = parseFloat(e['saldo'])                                                
                                            }

                                            let keys = Object.keys(contaPorMes)
                                            for( let key of keys ) {
                                                if (typeof patrimonio[key] === 'undefined') {
                                                    patrimonio[key] = {
                                                        valor: 0,
                                                        data: contaPorMes[key].data
                                                    }
                                                }
                                                //patrimonio[key].valor = parseFloat(contaPorMes[key].valor)
                                            }
                                        }
                                    }
                                )
                            break
                            
                            case 'td':
                            case 'rf':
                                await axios.get(rendaFixaUrl+objUrlQuery+o._id).then(
                                    ativos => {
                                        for (let c of ativos.data) {
                                            
                                            let contaPorMes = {}
                                            for (let e of c.extrato) {

                                                let data = this.getMesAno(new Date(e['dataAtualizacao']))
                                                let key = this.getMesAnoKey(new Date(e['dataAtualizacao']))

                                                if (typeof contaPorMes[key] === 'undefined') {
                                                    contaPorMes[key] = {
                                                        valor: 0,
                                                        data: data
                                                    }
                                                }
                                                contaPorMes[key].valor = parseFloat(e['saldo'])                                                
                                            }

                                            let keys = Object.keys(contaPorMes)
                                            for( let key of keys ) {
                                                if (typeof patrimonio[key] === 'undefined') {
                                                    patrimonio[key] = {
                                                        valor: 0,
                                                        data: contaPorMes[key].data
                                                    }
                                                }
                                                //patrimonio[key].valor += parseFloat(contaPorMes[key].valor)
                                            }

                                        }
                                    }
                                )
                            break

                            case 'fii':
                            case 'acao':
                                await axios.get(acaoUrl+objUrlQuery+o._id).then(
                                    async ativos => {                                     
                                        for (let acao of ativos.data) { 
                                            for (let e of acao.movimentacao) {                                                
                                                let total = e.quantidade * e.preco
                                                let data = this.getMesAno(new Date(e['data']))
                                                let key = this.getMesAnoKey(new Date(e['data']))
                                                
                                                if (typeof patrimonio[key] === 'undefined') {
                                                    patrimonio[key] = {
                                                        valor: 0,
                                                        data: data
                                                    }
                                                }

                                                if (e.operacao === '+')
                                                    patrimonio[key].valor += parseFloat(total)
                                                else if (e.operacao === '-')
                                                    patrimonio[key].valor -= parseFloat(total)
                                            }                                          
                                        }
                                    }
                                )
                            break

                            case 'reit':
                            case 'stock':
                                await axios.get(acaoUrl+objUrlQuery+o._id).then(
                                    async ativos => {                                   
                                        for (let acao of ativos.data) {               
                                            for (let e of acao.movimentacao) {                                                
                                                let total = (e.quantidade * e.preco) * dolar
                                                let data = this.getMesAno(new Date(e['data']))
                                                let key = this.getMesAnoKey(new Date(e['data']))
                                                
                                                if (typeof patrimonio[key] === 'undefined') {
                                                    patrimonio[key] = {
                                                        valor: 0,
                                                        data: data
                                                    }
                                                }
                                                
                                                if (e.operacao === '+')
                                                    patrimonio[key].valor += parseFloat(total)
                                                else if (e.operacao === '-')
                                                    patrimonio[key].valor -= parseFloat(total)
                                            }  
                                        }
                                    }
                                )
                            break
                       }
                    }
                }
            )  
            resolve(patrimonio)
        })
    }

    static async calcularPatrimonioAcao(classe) {

        let dolar = 1

        if ( classe.tipo === 'stock' || classe.tipo === 'reit' ) { 
            await Utils.buscarCotacaoDolar().then( valor => dolar = valor)
        }
        
        return new Promise( async (resolve, reject) => { 
            const userUrlQuery = '?userId='+Constantes.getUserId();
            const objUrlQuery = userUrlQuery+'&objetivoId='

            var patrimonio = {}

            await axios.get(acaoUrl+objUrlQuery+classe._id).then(
                async ativos => {                                     
                    for (let acao of ativos.data) { 
                        for (let e of acao.movimentacao) {                                                
                            let total = (e.quantidade * e.preco) * dolar
                            let data = this.getMesAno(new Date(e['data']))
                            let key = this.getMesAnoKey(new Date(e['data']))
                            
                            if (typeof patrimonio[key] === 'undefined') {
                                patrimonio[key] = {
                                    valor: 0,
                                    data: data
                                }
                            }

                            if (e.operacao === '+')
                                patrimonio[key].valor += parseFloat(total)
                            else if (e.operacao === '-')
                                patrimonio[key].valor -= parseFloat(total)
                        }                                          
                    }
                }
            )
              
            resolve(patrimonio)
        })
    }    

    static async calcularPatrimonioAcaoAno(classe) {

        let dolar = 1

        if ( classe.tipo === 'stock' || classe.tipo === 'reit' ) { 
            await Utils.buscarCotacaoDolar().then( valor => dolar = valor)
        }
        
        return new Promise( async (resolve, reject) => { 
            const userUrlQuery = '?userId='+Constantes.getUserId();
            const objUrlQuery = userUrlQuery+'&objetivoId='

            var patrimonio = {}

            await axios.get(acaoUrl+objUrlQuery+classe._id).then(
                async ativos => {                                     
                    for (let acao of ativos.data) { 
                        for (let e of acao.movimentacao) {                                                
                            let total = (e.quantidade * e.preco) * dolar
                            let data = this.getAnoKey(new Date(e['data']))
                            let key = this.getAnoKey(new Date(e['data']))
                            
                            if (typeof patrimonio[key] === 'undefined') {
                                patrimonio[key] = {
                                    valor: 0,
                                    data: data
                                }
                            }

                            if (e.operacao === '+')
                                patrimonio[key].valor += parseFloat(total)
                            else if (e.operacao === '-')
                                patrimonio[key].valor -= parseFloat(total)
                        }                                          
                    }
                }
            )
              
            resolve(patrimonio)
        })
    }


    static async calcularRendimentosAcao(classe) {

        let dolar = 1

        if ( classe.tipo === 'stock' || classe.tipo === 'reit' ) { 
            await Utils.buscarCotacaoDolar().then( valor => dolar = valor)
        }
        
        return new Promise( async (resolve, reject) => { 
            const userUrlQuery = '?userId='+Constantes.getUserId();
            const objUrlQuery = userUrlQuery+'&objetivoId='

            var patrimonio = {}

            await axios.get(acaoUrl+objUrlQuery+classe._id).then(
                async ativos => {                                     
                    for (let acao of ativos.data) { 
                        for (let e of acao.rendimentos) {                                                
                            let total = e.valor * dolar
                            let data = this.getMesAno(new Date(e['data']))
                            let key = this.getMesAnoKey(new Date(e['data']))
                            
                            if (typeof patrimonio[key] === 'undefined') {
                                patrimonio[key] = {
                                    valor: 0,
                                    data: data
                                }
                            }

                            patrimonio[key].valor += parseFloat(total)
                        }                                          
                    }
                }
            )
              
            resolve(patrimonio)
        })
    }

    static async calcularRendimentosAcaoAno(classe) {

        let dolar = 1

        if ( classe.tipo === 'stock' || classe.tipo === 'reit' ) { 
            await Utils.buscarCotacaoDolar().then( valor => dolar = valor)
        }
        
        return new Promise( async (resolve, reject) => { 
            const userUrlQuery = '?userId='+Constantes.getUserId();
            const objUrlQuery = userUrlQuery+'&objetivoId='

            var patrimonio = {}

            await axios.get(acaoUrl+objUrlQuery+classe._id).then(
                async ativos => {                                     
                    for (let acao of ativos.data) { 
                        for (let e of acao.rendimentos) {                                                
                            let total = e.valor * dolar
                            let data = this.getAnoKey(new Date(e['data']))
                            let key = this.getAnoKey(new Date(e['data']))
                            
                            if (typeof patrimonio[key] === 'undefined') {
                                patrimonio[key] = {
                                    valor: 0,
                                    data: data
                                }
                            }

                            patrimonio[key].valor += parseFloat(total)
                        }                                          
                    }
                }
            )
              
            resolve(patrimonio)
        })
    }
}