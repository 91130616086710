import React, { Component } from 'react'
import {Toast} from 'primereact/toast'
import {Calendar} from 'primereact/calendar'
import {Button} from 'primereact/button'
import {Chart} from 'primereact/chart'
import {Dropdown} from 'primereact/dropdown'
import {Utils} from '../generico/Utils'
import {Constantes} from '../generico/Constantes'

import axios from 'axios'
const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'

export class RelatorioRendimentos extends Component {

    constructor(props) {
        super(props)

        this.state = {
            dataChart: [],
            listaObjetivo: [],
            dtIni: null,
            dtFim: null,
            classe: 'Todas',
            classes: ['Todas','Ações','FIIs','Stocks','REITs'],
            anual: 'Mensal',
            isLoadingChart: true,
            isLoading: true
        }

        this.buscar = this.buscar.bind(this)
        this.printChart = this.printChart.bind(this)
    }

    componentDidMount() {
        this.buscar()
    }

    buscar() {
        this.setState({isLoadingChart: true})
        axios.get(objBaseUrl+'?sort=ordem&userId='+Constantes.getUserId()).then(
            async resp => {
                let listaObjetivo = resp.data

                var data = {
                    dataAcao: {},
                    dataFii: {},
                    dataStock: {},
                    dataReit: {}
                }

                //Buscar todos os dados
                for(var o of listaObjetivo ) {
                    
                    var dataValues = {}

                    if ( this.state.anual == 'Anual' ) {
                        await Utils.calcularRendimentosAcaoAno(o).then(
                            patrimonio => {
                
                                let sortedKey =  Object.keys(patrimonio).sort( (a,b) => {
                                    return a-b
                                })
                
                                for( let key of sortedKey ) {                                    
                                    dataValues[key] = { 
                                        tipo: o.tipo,
                                        data: key,
                                        valor: parseFloat( patrimonio[key].valor ) 
                                    }        
                                }

                                switch(o.tipo) {
                                    case 'acao':
                                        data.dataAcao = dataValues
                                    break
                                    case 'fii':
                                        data.dataFii = dataValues
                                    break
                                    case 'stock':
                                        data.dataStock = dataValues
                                    break
                                    case 'reit':
                                        data.dataReit = dataValues
                                    break
                                }
                
                            }
                        ) 
                    }
                    else {
                    
                        await Utils.calcularRendimentosAcao(o).then(
                            patrimonio => {
                
                                let sortedKey =  Object.keys(patrimonio).sort( (a,b) => {
                                    let s1 = a.split('/')                    
                                    let s2 = b.split('/')
                
                                    let d1 = new Date(s1[1],s1[0]-1,1)
                                    let d2 = new Date(s2[1],s2[0]-1,1)
                
                                    return d1-d2
                                })
                
                                for( let key of sortedKey ) {
                                    let sKey = key.split('/')
                                    let dtKey = new Date(sKey[1],sKey[0]-1,1)

                                    if (this.state.dtIni !==null 
                                        && typeof this.state.dtIni !== 'undefined'
                                        && this.state.dtIni-dtKey > 0 ) continue

                                    if (this.state.dtFim !==null 
                                        && typeof this.state.dtFim !== 'undefined'
                                        && this.state.dtFim-dtKey < 0 ) continue

                                    dataValues[key] = { 
                                        tipo: o.tipo,
                                        data: key,
                                        valor: parseFloat( patrimonio[key].valor ) 
                                    }        
                                }

                                switch(o.tipo) {
                                    case 'acao':
                                        data.dataAcao = dataValues
                                    break
                                    case 'fii':
                                        data.dataFii = dataValues
                                    break
                                    case 'stock':
                                        data.dataStock = dataValues
                                    break
                                    case 'reit':
                                        data.dataReit = dataValues
                                    break
                                }
                
                            }
                        ) 

                    }

                }

                //Criar chaves únicas e ordenadas
                var allKeys = Object.keys(data.dataAcao)
                allKeys = allKeys.concat(Object.keys(data.dataFii))
                allKeys = allKeys.concat(Object.keys(data.dataStock))
                allKeys = allKeys.concat(Object.keys(data.dataReit))

                let sortedKey = allKeys.sort( (a,b) => {

                    if ( this.state.anual == 'Anual' ) {
                        return a-b
                    }
                    else {
                        let s1 = a.split('/')                    
                        let s2 = b.split('/')

                        let d1 = new Date(s1[1],1,s1[0])
                        let d2 = new Date(s2[1],1,s2[0])

                        return d1-d2
                    }
                }).filter(function(item, pos, ary) {
                    return !pos || item !== ary[pos - 1];
                })
                
                //Popular o array final
                var datasetAcao = {
                    type: 'bar',
                    label: 'Ações',
                    backgroundColor: '#109618',
                    data: []
                }
                var datasetFii = {
                    type: 'bar',
                    label: 'FIIs',
                    backgroundColor: '#990099',
                    data: []
                }
                var datasetStock = {
                    type: 'bar',
                    label: 'Stocks',
                    backgroundColor: '#0099C6',
                    data: []
                }
                var datasetReit = {
                    type: 'bar',
                    label: 'RETIs',
                    backgroundColor: '#DD4477',
                    data: []
                }
                var datasetMedia = {
                    type: 'line',
                    label: 'Média',
                    fill: false,   
                    borderColor: '#42A5F5',
                    data: []
                }
                var dataTotal = {
                    type: 'bar',
                    label: 'Total',
                    backgroundColor: '#109618',
                    data: []
                }
                var dataTotalKey = {
                    type: 'bar',
                    label: 'Total',
                    backgroundColor: '#109618',
                    data: []
                }

                let soma = 0;
                let meses = 0;
                for( let key of sortedKey ) {

                    var acao=0
                    var fii=0
                    var stock=0
                    var reit=0
                    let totalKey = 0

                    if ( typeof data.dataAcao[key] !== 'undefined' ) {
                        acao = data.dataAcao[key].valor.toFixed(2)
                        totalKey += parseFloat(acao)
                        soma += parseFloat(acao)
                    }
                    if ( typeof data.dataFii[key] !== 'undefined' ){
                        fii = data.dataFii[key].valor.toFixed(2)
                        totalKey += parseFloat(fii)
                        soma += parseFloat(fii)
                    }
                    if ( typeof data.dataStock[key] !== 'undefined' ){
                        stock = data.dataStock[key].valor.toFixed(2)
                        totalKey += parseFloat(stock)
                        soma += parseFloat(stock)
                    }
                    if ( typeof data.dataReit[key] !== 'undefined' ){
                        reit = data.dataReit[key].valor.toFixed(2)
                        totalKey += parseFloat(reit)
                        soma += parseFloat(reit)
                    }

                    meses++

                    datasetAcao.data.push(acao)
                    datasetFii.data.push(fii)
                    datasetStock.data.push(stock)
                    datasetReit.data.push(reit)
                    datasetMedia.data.push((soma/meses).toFixed(2))
                    dataTotal.data.push(soma.toFixed(2))
                    dataTotalKey.data.push(totalKey.toFixed(2))
                }

                var dados = [datasetMedia, datasetAcao, datasetFii, datasetStock, datasetReit]
                switch (this.state.classe) {
                    case 'Ações':
                        dados = [datasetMedia, datasetAcao]
                    break
                    case 'FIIs':
                        dados = [datasetMedia, datasetFii]
                    break
                    case 'Stocks':
                        dados = [datasetMedia, datasetStock]
                    break
                    case 'REITs':
                        dados = [datasetMedia, datasetReit]
                    break
                    default:
                        dados = [datasetMedia, datasetAcao, datasetFii, datasetStock, datasetReit]
                }

                const stackedData = {
                    labels: sortedKey,
                    datasets: dados
                }

                const totalData = {
                    labels: sortedKey,
                    datasets: [dataTotal]
                }

                const totalDatKey = {
                    labels: sortedKey,
                    datasets: [datasetMedia,dataTotalKey]
                }
                
                this.setState({stackedData: stackedData, totalData: totalData, totalDatKey: totalDatKey})
                this.setState({isLoading: false, isLoadingChart: false})
            }
        ) 
    }

    printChart() {
        const stackedOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                position: 'bottom'
            },
            responsive: true,
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        }

        if ( !this.state.isLoadingChart )
            return <Chart type="bar" data={this.state.stackedData} options={stackedOptions}/>
        else
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
    }

    printChartTotalKey() {
        const options = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                position: 'bottom'
            },
            responsive: true
        }

        if ( !this.state.isLoadingChart )
            return <Chart type="bar" data={this.state.totalDatKey} options={options}/>
        else
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
    }

    printChartTotal() {
        const options = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            legend: {
                position: 'bottom'
            },
            responsive: true
        }

        if ( !this.state.isLoadingChart )
            return <Chart type="bar" data={this.state.totalData} options={options}/>
        else
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
    }

    render() {

        if ( this.state.isLoading )
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
        else 
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <Toast ref={(el) => this.messages = el}/>
                </div>

                <div className="p-col-12">  
                    <div className="card card-w-title">
                        <h1>Rendimentos</h1>

                    </div> 

                    <div className="card">
                        <div className="p-grid p-fluid p-align-end">
                            <div className="p-md-2 p-sm-12">
                                Início
                                <Calendar value={this.state.dtIni} onChange={(e) => this.setState({ dtIni: e.value })} 
                                    view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="1900:2030" />
                            </div>
                            <div className="p-md-2 p-sm-12">
                                Fim
                                <Calendar value={this.state.dtFim} onChange={(e) => this.setState({ dtFim: e.value })} 
                                    view="month" dateFormat="mm/yy" yearNavigator={true} yearRange="1900:2030" />
                            </div>                            
                            <div className="p-md-2 p-sm-12">
                                Classe
                                <Dropdown value={this.state.classe} options={this.state.classes} 
                                    onChange={(e) => {this.setState( {classe: e.target.value })} }/>
                            </div> 
                            <div className="p-md-2 p-sm-12">
                                Agrupamento
                                <Dropdown value={this.state.anual} options={['Mensal','Anual']} 
                                    onChange={(e) => {this.setState( {anual: e.target.value })} }/>
                            </div>      
                            <div className="p-md-2 p-sm-12">
                                <Button label="Buscar" icon="pi pi-check" onClick={this.buscar}/>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        {this.printChart()}
                    </div>  
                    <div className="card">
                        {this.printChartTotalKey()}
                    </div>  
                    <div className="card">
                        {this.printChartTotal()}
                    </div>               
                </div>

            </div>
        )
    }
}