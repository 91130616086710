import React, { Component } from 'react'
import {Constantes} from '../generico/Constantes'
import { Sidebar } from 'primereact/sidebar'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {Utils} from '../generico/Utils'
import {ConfirmDialog} from '../generico/ConfirmDialog'
import axios from 'axios'

const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

export class RendimentosDialog extends Component {

    constructor(props) {        
        super(props)
        this.state = {
            excluirDialog: false,
            rendimento: {},
            lista: {},
            dolar: 1
        }
        Utils.buscarCotacaoDolar().then(valor => this.setState({dolar:valor}))
        this.fecharDialog = this.fecharDialog.bind(this)
    }

    fecharDialog() {
        this.props.onHideFunction()
    }

    actionBodyTemplate(rendimento) {
        return (
            <Button icon="pi pi-trash" className="p-button-secondary"
                tooltip="Excluir" onClick={ () => {
                    this.setState({rendimento: {...rendimento}})
                    this.setState({excluirDialog: true})
                } }/> 
        );
    }

    buscarValor(row) {
        let valor = row.valor
        if (this.props.tipoClasse === 'stock' ||  this.props.tipoClasse === 'reit')
            valor = valor * this.state.dolar

        return (<span>{Utils.formatarDinheiro(valor)}</span>)
    }

    render() {
        /*const footer = (
            <div>
                <Button label="Fechar" icon="pi pi-times" onClick={this.fecharDialog}/>
            </div>
        )*/

        return (
            <Sidebar visible={this.props.display} onHide={this.fecharDialog} 
                baseZIndex={1000} position="right" style={{width: '40%', overflow: 'scroll'}}>

                    <span className="p-card-title">Rendimentos</span>
                    <br/><br/><br/>
                    <DataTable value={this.props.ativo.rendimentos} footer={this.tableFooter}>

                        <Column field="data" header="Data" sortable={true}
                            headerStyle={{textAlign:'center'}} style={{textAlign:'center'}}
                            body={(row) => <span>{Utils.formatarData(row.data)}</span>} />

                        <Column field="tipo" header="Tipo"
                            headerStyle={{textAlign:'center'}} style={{textAlign:'center'}}
                            body={(row) => <span>{row.tipo.toUpperCase()}</span>} />

                        <Column field="valor" header="Valor"
                            headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}
                            body={ (row) => this.buscarValor(row)  }/>

                        <Column body={(row) => this.actionBodyTemplate(row)}  headerStyle={{width: '8em', textAlign: 'center'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />

                    </DataTable>

                    <ConfirmDialog header="Excluir Rendimento"
                        visible={this.state.excluirDialog} 
                        message="Tem certeza que deseja excuir?"
                        funcaoSim={ () => {

                            var acao = this.props.ativo
                            var mov = this.state.rendimento

                            var novosRendimentos = []
                            for(var r of acao.rendimentos) {
                                if ( r._id !== mov._id ) {
                                    novosRendimentos.push(r)
                                }
                            }
                            acao.rendimentos = novosRendimentos

                            axios.put(acaoBaseUrl+acao._id,{...acao}).then(resp => {                                                                    
                                this.setState({excluirDialog: false})
                            })
                            }
                        }
                        funcaoNao={() => this.setState({excluirDialog: false})}
                    />
            </Sidebar>


        )
    }
}