import React, { Component } from 'react'
import {Constantes} from '../generico/Constantes'
import { ResponsiveTreeMap } from '@nivo/treemap'
import {Utils} from '../generico/Utils'
import {AcaoUtils} from '../generico/AcaoUtils'
import axios from 'axios'

const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'
const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'

export class TreeMap extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
          isLoading: true
        }

        Utils.buscarCotacaoDolar().then(
          dolar => {
              this.setState({dolar: dolar})
          }
        )

        const data = {
          "name": "Portifólio",
          "children": []
        }

        this.state = {data: data}
    }

    async componentDidMount() {

      await axios.get(acaoBaseUrl+'?userId='+Constantes.getUserId()+'&sort=nome').then(
        async resp => {
          var listaAcao = resp.data
        
          var childrenAcao = []
          var childrenFii = []
          var childrenStock = []
          var childrenReit = []

          var idAcao = 0
          var idFii = 0
          var idStock = 0
          var idReit = 0

          await axios.get(objBaseUrl+'?sort=ordem&userId='+Constantes.getUserId()).then(
            respObj => {
              var listaObjetivo = respObj.data

              for(var o of listaObjetivo) {
                switch(o.tipo) {
                  case 'acao':
                    idAcao = o._id
                  break
                  case 'fii':
                    idFii = o._id
                  break
                  case 'stock':
                    idStock = o._id
                  break
                  case 'reit':
                    idReit = o._id
                  break
                }
              }

              for (var a of listaAcao) {                    
                var valorAtual = AcaoUtils.calcularValorAtual(a,'tipoClasse',this.state.dolar)
                switch(a.objetivoId) {
                  case idAcao:
                    childrenAcao.push({name: a.nome, loc: valorAtual})
                  break
                  case idFii:
                    childrenFii.push({name: a.nome, loc: valorAtual})
                  break
                  case idStock:
                    childrenStock.push({name: a.nome, loc: valorAtual})
                  break
                  case idReit:
                    childrenReit.push({name: a.nome, loc: valorAtual})
                  break
                }
              }

              var data = {
                "name": "Portifólio",
                "children": []
              }

              data.children.push ({
                name: "Ações",
                children: childrenAcao
              })

              data.children.push ({
                name: "FIIs",
                children: childrenFii
              })

              data.children.push ({
                name: "Stocks",
                children: childrenStock
              })

              data.children.push ({
                name: "REITs",
                children: childrenReit
              })

              this.setState({data: data})
              this.setState({isLoading: false})
            }
          )
        }
      )
        
    }

    render() {

      if ( this.state.isLoading )
        return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
      else 
        return (
          <ResponsiveTreeMap
              data={this.state.data}
              identity="name"
              value="loc"
              valueFormat=" >-$.2~f"
              margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
              label={function(e){return e.id+" ("+e.formattedValue+")"}}
              labelSkipSize={12}
              labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.2 ] ] }}
              parentLabelTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
              borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.1 ] ] }}
          />
        )
    }
        
    
}