import React, { Component } from 'react'
import {Utils} from '../generico/Utils'
import EditableLabel from 'react-editable-label'
import {Constantes} from '../generico/Constantes'
import {Toast} from 'primereact/toast'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import axios from 'axios'

import '../generico/GenericCSS.css'

const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'

export class CardAcao extends Component {

    constructor(props) {
        super(props)

        this.items = [{
            label: 'Menu',
            items: [
                {
                    label: 'Delete',
                    icon: 'pi pi-times',
                    command: () => {
                       console.log('Delete')
                    }
                }
            ]

        }]

    }

    render() {

        return (
            <div className="p-card p-component">
                <div className="p-grid p-align-top" style={{padding: '10px'}}>
                    <Toast ref={(el) => this.messages = el} />

                    <div className="p-col-9"  style={{textAlign: 'left', paddingBottom: '0px'}}>
                        <span className="p-card-title">
                            {this.props.item.nome} 
                        </span><br/>

                        <span className="blackText">
                            {'Quantidade: '}
                        </span>    
                        <scap className="p-card-subtitle">
                            {this.props.item.quantidadeAtual}
                        </scap><br/>

                        <span className="blackText">
                            {'Segmento: '}
                        </span>  
                        <span className="p-card-subtitle">
                            <EditableLabel
                                initialValue={typeof this.props.item.setor === 'undefined'? 'Editar':this.props.item.setor}
                                save={value => {
                                    var novaAcao = {...this.props.item}
                                    novaAcao.setor = value
                                    
                                    axios.put(acaoBaseUrl+novaAcao._id,{...novaAcao}).then(resp => {
                                        console.log(resp)
                                        if ( resp.status === 201 || resp.status === 200) {
                                            this.messages.show({severity: 'success', summary: 'Sucesso!', detail: 'Segmento atualziado com Sucesso'})
                                        }
                                        else    
                                            this.messages.show({severity: 'error', summary: 'Erro!', detail: 'Erro ao atualizar Segmento'});
                                        }
                                    )
                                }}
                            />
                        </span>

                    </div>

                    <div className="p-col-3" style={{textAlign: 'right', paddingBottom: '0px'}}>
                            <Menu model={[{
                                label: 'Opções',
                                items: [
                                    {
                                        label: 'Ajustar Posição',
                                        icon: 'pi pi-plus-circle',
                                        command: () => {
                                            this.props.posicaoCallBack(this.props.item)
                                        }
                                    },
                                    {
                                        label: 'Movimentações',
                                        icon: 'pi pi-dollar',
                                        command: () => {
                                            this.props.movimentacaoCallBack(this.props.item)
                                        }
                                    },
                                    {
                                        label: 'Rendimentos',
                                        icon: 'pi pi-bars',
                                        command: () => {
                                            this.props.rendimentosCallBack(this.props.item)
                                        }
                                    },
                                    {
                                        label: 'Atualizar Cotação',
                                        icon: 'pi pi-refresh',
                                        command: () => {
                                            this.props.cotacaoCallBack(this.props.item)
                                        }
                                    },
                                    {
                                        label: 'Excluir',
                                        icon: 'pi pi-times',
                                        command: () => {
                                            this.props.exclusaoCallBack(this.props.item._id)
                                        }
                                    },
                                ]

                            }]}
                            style={{textAlign:'left'}} 
                            popup ref={el => this.menu = el} id="popup_menu" />

                            <Button icon="pi pi-ellipsis-v"
                                style={{width:'2em', height:'2em'}}
                                className="p-button-secondary p-button-rounded"
                                onClick={(e) => this.menu.toggle(e)} 
                                aria-controls="popup_menu" aria-haspopup/>
                    </div>

                    <div className="p-col-8" style={{textAlign: 'left', paddingTop: '0px'}} >
                        <span className="blackText">
                            {Utils.formatarDinheiro(this.props.item.cotacaoAtual)}
                        </span>      
                    </div>
                    <div className="p-col-4" style={{textAlign: 'right', paddingTop: '0px'}}>
                        <span className={this.props.item.ultimaVariacaoPercent<0?'redText':'greenText'}>
                            { Utils.formatarPercentual(this.props.item.ultimaVariacaoPercent) }
                        </span>
                            
                    </div>

                    <div className="p-col-12">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

}