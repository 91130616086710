const baseRestUrl = 'https://api-dot-sigmamoney.rj.r.appspot.com/api/'//'http://localhost:3001/api/'
const urlCotacao = 'https://www.alphavantage.co/query?function=GLOBAL_QUOTE&apikey=$apikey&symbol='
const urlDolar = 'https://olinda.bcb.gov.br/olinda/servico/PTAX/versao/v1/odata/CotacaoDolarDia(dataCotacao=@dataCotacao)?$format=json&$select=cotacaoCompra&@dataCotacao='
const urlCotacaoB3 = 'https://cors-anywhere.herokuapp.com/http://bvmf.bmfbovespa.com.br/cotacoes2000/FormConsultaCotacoes.asp?strListaCodigos='
//const urlCotacaoB3 = 'http://bvmf.bmfbovespa.com.br/cotacoes2000/FormConsultaCotacoes.asp?strListaCodigos='
const tamanhoDialog = '60%'

export class Constantes {

    static buscarUrlBase() {
        return baseRestUrl
    }
    
    static urlCotacaoB3() {
        return urlCotacaoB3
    }

    static urlCotacao() {
        return urlCotacao
    }

    static urlDolar() {
        return urlDolar
    }

    static tamanhoDialog() {
        return tamanhoDialog
    }

    static getUserId() {
        return sessionStorage.getItem('userId')
    }
}