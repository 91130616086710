import React, { Component } from 'react'
import {ObjetivoPieChart} from '../chart/ObjetivoPieChart'
import {Panel} from 'primereact/panel'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {TopValores} from '../acao/TopValores'
import {Utils} from '../generico/Utils'
import {SugestaoAporte} from '../acao/SugestaoAporte'
import {ListaAtivos} from './ListaAtivos'
import {TreeMap} from './TreeMap'

import '../generico/GenericCSS.css'

export class MoneyDash extends Component {
     
    constructor() {
        super();

        this.state = {
            totalRE: 0,
            totalRF: 0,
            totalTD: 0,
            totalAcao: 0,
            totalFii: 0,
            totalStock: 0,
            totalReit: 0,

            totalFixo: 0,
            totalVariavelBr: 0,
            totalVariavelUS: 0,
            totalGeral: 0,

            dolar: 1,

            objetivos: {},

            showMoney: true,
            
            isLoading: true
        }

        
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this)
        this.atualizarObjetivos = this.atualizarObjetivos.bind(this)
        this.atualizarSugestaoAporte = this.atualizarSugestaoAporte.bind(this)
        this.showMoney = this.showMoney.bind(this)
    }

    componentDidMount() {
        this.calcularValorPorObjetivoSemServico()
        this.calcularValorPorObjetivo()
    }

    async calcularValorPorObjetivoSemServico() {
        await  Utils.calcularValorPorObjetivoSemServico().then(
            objetivos => this.atualizarObjetivos(objetivos)                
        )
    }

    async calcularValorPorObjetivo() {
        await  Utils.calcularValorPorObjetivo().then(
            objetivos => this.atualizarObjetivos(objetivos)                
        )
    }

    atualizarObjetivos(objetivos) {
        let totalGeral = 0
        let totalFixo = 0
        let totalVariavelBr = 0
        let totalVariavelUS = 0

        for( let obj of objetivos ) {    
            switch ( obj.tipo ) {
                case 're': 
                    this.setState( {totalRE: obj.valorTotal} )
                    totalFixo += obj.valorTotal
                break
                
                case 'td':
                    this.setState( {totalTD: obj.valorTotal} )
                    totalFixo += obj.valorTotal
                break

                case 'rf':
                    this.setState( {totalRF: obj.valorTotal} )
                    totalFixo += obj.valorTotal
                break

                case 'fii':
                    this.setState( {totalFii: obj.valorTotal} )
                    totalVariavelBr += obj.valorTotal
                break

                case 'acao':
                    this.setState( {totalAcao: obj.valorTotal} )
                    totalVariavelBr += obj.valorTotal
                break

                case 'reit':
                    this.setState( {totalReit: obj.valorTotal} )
                    totalVariavelUS += obj.valorTotal
                break

                case 'stock':
                    this.setState( {totalStock: obj.valorTotal} )
                    totalVariavelUS += obj.valorTotal
                break

                default:
                    console.log('Tipo de Objetivo inválido: '+obj.tipo)
            }
        }

        totalGeral = totalFixo+totalVariavelBr+totalVariavelUS

        this.setState({totalFixo: totalFixo})
        this.setState({totalVariavelBr: totalVariavelBr})
        this.setState({totalVariavelUS: totalVariavelUS})
        this.setState({totalGeral: totalGeral===0?1:totalGeral})
        this.setState({totalPatrimonio: totalGeral})
        this.setState({objetivos: objetivos})
        this.atualizarSugestaoAporte()
        
        this.setState({isLoading: false})
    }

    atualizarSugestaoAporte() {
        var objetivos = this.state.objetivos

        var result = objetivos[0]
        
        var valorMeta = (this.state.totalGeral*(result.percentual))/100
        var diferenca = result.valorTotal - valorMeta
        for ( var o of objetivos ) {
            valorMeta = (this.state.totalGeral*(o.percentual))/100
            var tempDif = o.valorTotal - valorMeta

            if (tempDif < diferenca) {
                result = o
                diferenca = tempDif
            }
        }

        this.setState({aporte: result})
    }

    statusBodyTemplate(rowData) {
        let percentualAtual = (rowData.valorTotal/this.state.totalGeral)*100
        let valorMeta = (this.state.totalGeral*(rowData.percentual))/100

        return <span className={percentualAtual<rowData.percentual?'customer-badge status-negotiation':'customer-badge status-qualified'}>
            {Utils.formatarDinheiro(rowData.valorTotal-valorMeta,this.state.showMoney)}
        </span>
    }

    showMoney() {
        let show = sessionStorage.getItem('showMoney')
        if ( show === 'true' ) {
            this.setState({showMoney: 'false'})
            sessionStorage.setItem('showMoney', 'false')
        }
        else {
            this.setState({showMoney: 'true'})
            sessionStorage.setItem('showMoney', 'true')
        }
    }
    
    render() {
        
        if ( this.state.isLoading )
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
        else
        return (
            <div className="p-grid p-fluid dashboard">
                <div className="p-col-12 p-lg-12">
                    <div className="card summary">
                        <span className="title">Patrimônio  <i className={this.state.showMoney==='false'?'pi pi-eye':'pi pi-eye-slash'} onClick={this.showMoney} style={{cursor: 'pointer'}} /></span>
                        <span className="detail">Soma de Todos os Investimentos</span>
                        <span className="count visitors">{Utils.formatarDinheiro(this.state.totalPatrimonio,this.state.showMoney)}</span>
                    </div>
                </div>
                

                <div className="p-col-12 p-lg-4">
                    <div className="highlight-box">
                        <div className="initials" 
                            style={{backgroundColor:'#007be5', color:'#00448f', paddingTop: '25px', paddingBottom: '25px'}}>
                            <span>
                                {(((this.state.totalFixo)/this.state.totalGeral)*100).toFixed(2)}%
                            </span>
                        </div>
                        <div className="highlight-details ">
                            <i className="pi pi-money-bill"/>
                            <span>Renda Fixa</span>
                            <div className="p-col-12" style={{fontSize: 23, color: '##70707', textAlign: "right"}}>
                                {Utils.formatarDinheiro(this.state.totalFixo,this.state.showMoney)}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="p-col-12 p-lg-4">
                    <div className="highlight-box">
                        <div className="initials" 
                            style={{backgroundColor:'#20d077',color:'#038d4a', paddingTop: '25px', paddingBottom: '25px'}}>
                            <span>
                                {(((this.state.totalVariavelBr)/this.state.totalGeral)*100).toFixed(2)}%
                            </span>
                        </div>
                        <div className="highlight-details ">
                            <i className="pi pi-chart-bar"/>
                            <span>Renda Variável BR</span>
                            <div className="p-col-12" style={{fontSize: 23, color: '##70707', textAlign: "right"}}>
                                {Utils.formatarDinheiro(this.state.totalVariavelBr,this.state.showMoney)}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="p-col-12 p-lg-4">
                    <div className="highlight-box">
                        <div className="initials" 
                            style={{backgroundColor:'#FFCE56',color:'#db9a00', paddingTop: '25px', paddingBottom: '25px'}}>
                            <span>
                                {(((this.state.totalVariavelUS)/this.state.totalGeral)*100).toFixed(2)}%
                            </span>
                        </div>
                        <div className="highlight-details ">
                            <i className="pi pi-chart-bar"/>
                            <span>Renda Variável US</span><br/>
                            <div className="p-col-12" style={{fontSize: 23, color: '##70707', textAlign: "right"}}>
                                {Utils.formatarDinheiro(this.state.totalVariavelUS,this.state.showMoney)}
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="p-col-12 p-md-4">
                    <TopValores patrimonio={this.state.totalPatrimonio} showMoney={this.state.showMoney} 
                        header="Maiores % Patrimônio"/>
                </div>

                <div className="p-col-12 p-md-4">
                    <TopValores patrimonio={this.state.totalPatrimonio} showMoney={this.state.showMoney}
                        header="Menores % Patrimônio" sentido='bottom'/>
                </div>

                <div className="p-col-12 p-md-4">
                    <Panel header="Sugestão de Aporte">
                        <SugestaoAporte objetivo={this.state.aporte} patrimonio={this.state.totalPatrimonio} showMoney={this.state.showMoney} />
                    </Panel>
                </div>

                <div className="p-col-12 div-mobile-hidden">
                    <Panel header="Objetivos">
                    
                        <div className="p-grid">

                            <div className="p-md-8 p-sm-12">
                                <DataTable value={this.state.objetivos} lazy={true} responsive={true}>
                                    <Column field="nome" header="Classe" />

                                    <Column field="percentual" header="Meta"
                                        body={(row) => <span>{row.percentual.toFixed(2)}% <br/>
                                                    {Utils.formatarDinheiro((this.state.totalGeral*row.percentual)/100,this.state.showMoney)}
                                                </span>} 
                                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>                            

                                    <Column field="atual" header="Atual" 
                                        body={(row) => <span>{((row.valorTotal/this.state.totalGeral)*100).toFixed(2)}% <br/>
                                                    {Utils.formatarDinheiro(row.valorTotal,this.state.showMoney)}
                                                </span>}  
                                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>

                                    <Column field="status" header="Diferença" body={this.statusBodyTemplate} 
                                        headerStyle={{textAlign:'center'}} style={{textAlign:'right'}}/>
                                </DataTable>
                            </div>       
                            <div className="p-md-4"> 
                                <ObjetivoPieChart objetivos={this.state.objetivos} totalGeral={this.state.totalGeral}/>
                            </div>                 
                        </div>

                    </Panel>
                </div>

                
                <div className="p-col-12">
                    <Panel header="Alocação">
                        <div style={{height: '500px'}}>
                            <TreeMap />
                        </div>
                    </Panel>
                </div>

                <div className="p-col-12">
                    <ListaAtivos />
                </div>

            </div>
        )
    }
}
