import React, { Component } from 'react'
import {Constantes} from '../generico/Constantes'
import { Avatar } from 'primereact/avatar';
import {Utils} from '../generico/Utils'
import {Panel} from 'primereact/panel'
import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'
import { Tag } from 'primereact/tag'

import axios from 'axios'
import { AcaoUtils } from '../generico/AcaoUtils'
const acaoBaseUrl = Constantes.buscarUrlBase()+'acao/'
const objBaseUrl = Constantes.buscarUrlBase()+'objetivo/'

export class ListaAtivos extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            dolar: 1,
            classe: 'Todas',
            classes: ['Todas','Ações','FIIs','Stocks','REITs']
        }

        this.objetivoEmDolar = this.objetivoEmDolar.bind(this)
        this.gerarCard = this.gerarCard.bind(this)       
    }

    componentDidMount() {
        //Buscar dados
        axios.get(objBaseUrl+'?sort=ordem&userId='+Constantes.getUserId()).then(
            resp => {
                this.setState({listaObjetivo: resp.data})
            }
        )  

        axios.get(acaoBaseUrl+'?userId='+Constantes.getUserId()+'&sort=nome').then(
            resp => {
                var listaAcao = resp.data.sort( (a,b) => {
                    if (a.objetivoId > b.objetivoId) {
                        return 1;
                      }
                      if (a.objetivoId < b.objetivoId) {
                        return -1;
                      }
                      return 0;
                })
                this.setState({listaAcao: listaAcao, isLoading: false})
            }
        )

        Utils.buscarCotacaoDolar().then(
            dolar => {
                this.setState({dolar: dolar})
            }
        )
    }

    percentualTemplate(rowData) {
        return <span className={rowData.ultimaVariacaoPercent<0?'customer-badge status-unqualified':'customer-badge status-qualified'}>
            { Utils.formatarPercentual(rowData.ultimaVariacaoPercent) }
        </span>
    }

    onChangeClasse(e) {
        this.setState({classe: e.target.value })
        this.setState({isLoading: true})

        if ( e.target.value === 'Todas' ) {
            axios.get(acaoBaseUrl+'?userId='+Constantes.getUserId()+'&sort=nome').then(
                resp => {
                    var listaAcao = resp.data.sort( (a,b) => {
                        if (a.objetivoId > b.objetivoId) {
                            return 1;
                          }
                          if (a.objetivoId < b.objetivoId) {
                            return -1;
                          }
                          return 0;
                    })

                    this.setState({listaAcao: listaAcao, isLoading: false})
                }
            )
        }
        else {
            var tipo = ''
            switch(e.target.value) {
                case 'Ações':
                    tipo = 'acao'
                break
                case 'FIIs':
                    tipo = 'fii'
                break
                case 'Stocks':
                    tipo = 'stock'
                break
                case 'REITs':
                    tipo = 'reit'
                break
                default:
                    tipo = ''
            }

            for(var o of this.state.listaObjetivo) {
                if ( o.tipo === tipo ) {
                    axios.get(acaoBaseUrl+'?objetivoId='+o._id+'&sort=nome').then(
                        resp => {
                            var listaAcao = resp.data
        
                            this.setState({listaAcao: listaAcao, isLoading: false})
                        }
                    ) 
                    break 
                }
            }
        }
    }

    objetivoEmDolar(idObjetivo) {
        const objetivos = this.state.listaObjetivo
        return AcaoUtils.objetivoEmDolar(idObjetivo,objetivos)
    }

    gerarCard(acao) {
        return(
            <div className="p-col-6 p-md-4 p-lg-2">
                <Tag severity={acao.ultimaVariacaoPercent<0?'danger':'success'} style={{'width':'100%'}}>
                    <div className="p-grid" >
                        <div className="p-col-8">
                            <span style={{'fontSize':'1.8em'}}>
                                {acao.nome} 
                            </span>
                        </div>
                        <div className="p-col-4" style={{'textAlign':'right'}}>
                            <span style={{'fontSize':'0.8em'}}>
                               {Utils.formatarDataHoraMobile(acao.dataAtualizacaoCotacao)}
                            </span>
                        </div>
                        <div className="p-col-6" >
                            <span style={{'fontSize':'1.1em'}}>
                                {Utils.formatarDinheiro(acao.cotacaoAtual)} 
                            </span>
                        </div>
                        <div className="p-col-6" style={{'textAlign':'right'}}>
                            <span style={{'fontSize':'1.1em'}}>
                                {Utils.formatarPercentual(acao.ultimaVariacaoPercent)}
                            </span>
                        </div>
                    </div>
                </Tag>
            </div>
        )
    }

    render() {
        const header = (
            <div style={{'textAlign':'left'}} className="p-grid">
                
                <div className="p-col-4">             
                    <InputText type="search" onInput={(e) => this.setState({globalFilter: e.target.value})} placeholder="" />
                </div>
                
                <div className="p-col-4">
                    <Dropdown value={this.state.classe} options={this.state.classes} 
                        onChange={(e) => {this.onChangeClasse(e)} }/>
                </div>

                <div className="p-col-4" style={{'textAlign': 'right'}}>
                    Dolar: {this.state.dolar}
                </div>

            </div>
        )

        if ( this.state.isLoading )
            return <i className="pi pi-spin pi-spinner" style={{'fontSize': '3em'}}></i>
        else 
        return(

            <Panel header="Cotações">
                <div className="p-grid">
                    {this.state.listaAcao.map(this.gerarCard)}
                </div> 
                <div className="p-col-12" style={{'textAlign': 'right'}}>               
                    Dolar: {this.state.dolar}
                </div>
            </Panel>
            
        )
    }
}