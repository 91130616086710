import React, {Component} from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppMenu} from './AppMenu';
import {AppProfile} from './AppProfile';
import {Route} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel'

//import './layout/nova-light/theme.css';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';

import { MoneyDash } from './components/money/MoneyDash'
import {ListaObjetivo} from './components/objetivo/ListaObjetivo'
import {ListaAcao} from './components/acao/ListaAcao'
import {ListaRF} from './components/rendafixa/ListaRF'
import {RelatorioAporte} from './components/relatorio/RelatorioAporte'
import {RelatorioIRPF} from './components/relatorio/RelatorioIRPF'
import { UserUtils } from './components/generico/UserUtils'
import { RelatorioExtrato } from './components/relatorio/RelatorioExtrato';
import { RelatorioRendimentos } from './components/relatorio/RelatorioRendimentos';

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false
        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.createMenu();
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
       
        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu() {
        this.menu = [
            {label: 'Dashboard', icon: 'pi pi-fw pi-home', command: () => {window.location = '#/'}},
            {label: 'Objetivos', icon: 'pi pi-fw pi-star-o', to: '/objetivos'},
            {label: 'Reserva Emergência', icon: 'pi pi-fw pi-money-bill', to: '/listaRE'},  
            {label: 'Renda Fixa', icon: 'pi pi-fw pi-lock', to: '/listaRF'},  
            {label: 'Tesouro Direto', icon: 'pi pi-fw pi-briefcase', to: '/listaTD'},   
            {label: 'Ações', icon: 'pi pi-fw pi-chart-bar', to: '/listaAcao'},   
            {label: 'FIIs', icon: 'pi pi-fw pi-map-marker', to: '/listaFii'},   
            {label: 'Stocks', icon: 'pi pi-fw pi-globe', to: '/listaStock'},   
            {label: 'REITs', icon: 'pi pi-fw pi-tag', to: '/listaReit'},
            {label: 'Relatórios', icon: 'pi pi-fw pi-print', 
                items: [
                    {label: 'Aportes', to: '/relatorio'},
                    {label: 'Rendimentos', to: '/rendimentos'},
                    {label: 'Extrato', to: '/extrato'},
                    {label: 'IRPF', to: '/irpf'}
                ]    
            }
        ];
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    componentDidMount() {
        //Login Google
        const successCallback = this.onSuccess.bind(this);
    
        window.gapi.load('auth2', () => {
          this.auth2 = window.gapi.auth2.init({
            client_id: '224091015527-4os2li732f5fhk0pktcueiiouulkjrti.apps.googleusercontent.com',
          })
    
          this.auth2.then( async () => {
            
            if ( this.auth2.isSignedIn.get() ) {
                await this.onSuccess()
            }   

            this.setState({
              isSignedIn: this.auth2.isSignedIn.get(),
            });
            
          });
        });    
    
        window.gapi.load('signin2', function() {
          var opts = {
            scope: 'profile email',
            width: 240,
            height: 50,
            longtitle: true,
            client_id: '224091015527-4os2li732f5fhk0pktcueiiouulkjrti.apps.googleusercontent.com',
            onsuccess: successCallback
          }
          window.gapi.signin2.render('loginButton', opts)
        })


    }

    async onSuccess() {
        var profile = this.auth2.currentUser.get().getBasicProfile()
        let email = profile.getEmail()
        let nome = profile.getName()

        await UserUtils.login(email,nome).then( () => {
                this.setState({
                    isSignedIn: true,
                    err: null
                }) 
            }
        )
    }
    
    onLoginFailed(err) {
        console.log('on err')    
        console.log(err)
        this.setState({
          isSignedIn: false,
          error: err,
        })
    }

    render() {
        const logo = this.state.layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg': 'assets/layout/images/logo.svg';

        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
            'layout-sidebar-light': this.state.layoutColorMode === 'light'
        });


        if ( !this.state.isSignedIn ) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px'}}>
                    <Panel header="Bem-Vindo!" style={{marging: '20px'}}>
                        <div className="p-col" style={{textAlign: 'center'}}>
                            <div className="layout-logo" style={{padding: '30px'}}>
                                <img alt="Logo" src={'assets/layout/images/logo.svg'} />
                            </div>
                            <Button id="loginButton" />
                        </div>
                    </Panel>
                </div>
            )
        } 
        else
        return (
            <div className={wrapperClass} onClick={this.onWrapperClick}>
                <AppTopbar onToggleMenu={this.onToggleMenu}/>

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                    <div className="layout-logo">
                        <img alt="Logo" src={logo} />
                    </div>
                    <AppProfile />
                    <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                </div>

                <div className="layout-main">                    
                    <Route path="/" exact component={MoneyDash} />
                    <Route path="/objetivos" component={ListaObjetivo} />
                    <Route path="/listaRE" 
                        render={(props) => <ListaRF {...props} tipoClasse={'re'}  />}
                        />
                    <Route path="/listaRF" 
                        render={(props) => <ListaRF {...props} tipoClasse={'rf'}  />}
                        />
                    <Route path="/listaTD" 
                        render={(props) => <ListaRF {...props} tipoClasse={'td'}  />}
                        />
                    <Route path="/listaAcao" 
                        render={(props) => <ListaAcao {...props} tipoClasse={'acao'} sufixoCotacao={'.SA'} />}
                        />
                    <Route path="/listaFii" 
                        render={(props) => <ListaAcao {...props} tipoClasse={'fii'} sufixoCotacao={'.SAO'} />}
                        />
                    <Route path="/listaStock" 
                        render={(props) => <ListaAcao {...props} tipoClasse={'stock'} sufixoCotacao={''} />}
                        />
                    <Route path="/listaReit" 
                        render={(props) => <ListaAcao {...props} tipoClasse={'reit'} sufixoCotacao={''} />}
                        />
                    
                    <Route path="/relatorio" component={RelatorioAporte} />
                    <Route path="/extrato" component={RelatorioExtrato} />
                    <Route path="/rendimentos" component={RelatorioRendimentos} />
                    <Route path="/irpf" component={RelatorioIRPF} />

                </div>

                <div className="layout-mask"></div>
            </div>
        );
    }
}

export default App;
