
export class AcaoUtils {

    static calculaTotalRetorno(acao, tipoClasse, valorDolar) {
        var rendimentos = AcaoUtils.calculaTotalRendimentos(acao, tipoClasse, valorDolar)
        var valorAtual = AcaoUtils.calcularValorAtual(acao, tipoClasse, valorDolar)
        var valorPago = AcaoUtils.calcularValorPago(acao, tipoClasse, valorDolar)

        var retorno = (valorAtual+rendimentos)-valorPago
        return retorno
    }

    static objetivoEmDolar(idObjetivo, objetivos) {
        for( var o of objetivos ) {
            if ( o._id === idObjetivo ) {
                var result = false

                switch(o.tipo) {                   
                    case 'stock':
                        result = true
                    break
                    case 'reit':
                        result = true
                    break
                    default:
                        result = false
                }

                return result
            }
        }
        return false
    }

    static calculaTotalRendimentos(acao, tipoClasse, valorDolar) {
        var total = 0

        for (var r of acao.rendimentos) {
            total += r.valor
        }

        if ( tipoClasse === 'stock' || tipoClasse === 'reit' ) {  
            total = total*valorDolar   
        }

        return total
    }

    static calcularValorPago(acao, tipoClasse, valorDolar) {
        let precoTotal = 0
        for(const m of acao.movimentacao) {

            if ( m.operacao === '+' ) {
                precoTotal += (m.preco * m.quantidade)+m.taxas
            }
            else if (m.operacao === '-') {
                precoTotal -= ((m.preco * m.quantidade)-m.taxas)
            }
        }

        if ( tipoClasse === 'stock' || tipoClasse === 'reit' ) {  
            precoTotal = precoTotal*valorDolar   
        }

        return precoTotal
    }

    static calcularValorAtual(acao, tipoClasse, valorDolar) {
        let precoTotal = 0
        for(const m of acao.movimentacao) {

            if ( m.operacao === '+' ) {
                precoTotal += (acao.cotacaoAtual * m.quantidade)+m.taxas
            }
            else if (m.operacao === '-') {
                precoTotal -= ((acao.cotacaoAtual * m.quantidade)-m.taxas)
            }
        }


        return precoTotal
    }

    static calcularPrecoMedio(acao, tipoClasse, valorDolar) {
        let precoTotal = 0
        let quantidadeTotal = 0
        for(const m of acao.movimentacao) {

            if ( m.operacao === '+' ) {
                quantidadeTotal += m.quantidade
                precoTotal += (m.preco * m.quantidade)+m.taxas
            }
            else if (m.operacao === '-') {
                quantidadeTotal -= m.quantidade
                precoTotal -= ((m.preco * m.quantidade)-m.taxas)
            }
        }
        
        let precoMedio = precoTotal/quantidadeTotal
        if ( tipoClasse === 'stock' || tipoClasse === 'reit' ) {  
            precoMedio = precoMedio*valorDolar   
        }

        return precoMedio
    }

}