import {Constantes} from '../generico/Constantes'
import axios from 'axios'

const objetivoUrl = Constantes.buscarUrlBase()+'objetivo/'
const userUrl = Constantes.buscarUrlBase()+'user/'

export class UserUtils {

    static async login(email, nome) {
        return new Promise( async (resolve, reject) => { 
            await axios.get(userUrl+'?email='+email).then(
                async user => {

                    //Novo Usuário
                    if ( typeof user.data[0] === 'undefined' || typeof user.data[0]._id === 'undefined') {
                        var novoUser = {
                            nome: nome,
                            email: email
                        }

                        await axios.post(userUrl,{...novoUser}).then( 
                            resp => {
                                sessionStorage.setItem('userId', resp.data._id)
                                this.criarObjetivos(resp.data._id)
                            }
                        )
                    }
                    else {
                        sessionStorage.setItem('userId', user.data[0]._id)
                    }

                    sessionStorage.setItem('showMoney', true)
                    resolve(user)
                }
            )
        })
        
    }

    static criarObjetivos(userId) {
        var objetivoRE = {
            userId: userId,
            nome: 'Reserva de Emergência',
            tipo: 're',
            ordem: 1,
            percentual: 0
        }
        axios.post(objetivoUrl,{...objetivoRE})

        var objetivoRF = {
            userId: userId,
            nome: 'Renda Fixa',
            tipo: 'rf',
            ordem: 2,
            percentual: 0
        }
        axios.post(objetivoUrl,{...objetivoRF})

        var objetivoTD = {
            userId: userId,
            nome: 'Tesouro Direto',
            tipo: 'td',
            ordem: 3,
            percentual: 0
        }
        axios.post(objetivoUrl,{...objetivoTD})

        var objetivoAcao = {
            userId: userId,
            nome: 'Ações',
            tipo: 'acao',
            ordem: 4,
            percentual: 0
        }
        axios.post(objetivoUrl,{...objetivoAcao})

        var objetivoFii = {
            userId: userId,
            nome: 'FIIs',
            tipo: 'fii',
            ordem: 5,
            percentual: 0
        }
        axios.post(objetivoUrl,{...objetivoFii})
        
        var objetivoStock = {
            userId: userId,
            nome: 'Stocks',
            tipo: 'stock',
            ordem: 6,
            percentual: 0
        }
        axios.post(objetivoUrl,{...objetivoStock})
                
        var objetivoReits = {
            userId: userId,
            nome: 'REITs',
            tipo: 'reit',
            ordem: 7,
            percentual: 0
        }
        axios.post(objetivoUrl,{...objetivoReits})
    }
}