
export class CNPJs {

    static buscarCnpj(ticket) {
        for (var empresa of this.listaCnpj) {
            if ( empresa.indexOf(ticket) !== -1 ) {
                var arrayOfStrings = empresa.split(';')

                return arrayOfStrings[1]
            }
        }
        return '-'
    }

    static listaCnpj = [
        'BBAS11,BBAS12,BBAS3;00.000.000/0001-91;BANCODOBRASILS.A.'
        ,'BSLI3,BSLI4;00.000.208/0001-00;BRB-BANCODEBRASILIASA'
        ,'ELET3,ELET5,ELET6;00.001.180/0001-26;CENTRAISELETRICASBRASILEIRASSA'
        ,'CEBR3,CEBR5,CEBR6;00.070.698/0001-11;COMPANHIAENERGÉTICADEBRASÍLIA–CEB'
        ,'CMSA3,CMSA4;00.272.185/0001-93;CIMSSA'
        ,'TELB3,TELB4;00.336.701/0001-04;TELEC.BRASILEIRASS.A.–TELEBRÁS'
        ,'ATOM3;00.359.742/0001-08;ATOMEMPREENDIMENTOSEPARTICIPAÇÕESS.A.'
        ,'BIDI11,BIDI3,BIDI4;00.416.968/0001-01;BANCOINTERS.A.'
        ,'STKF3;00.622.416/0001-41;STATKRAFTENERGIASRENOVÁVEISS.A.'
        ,'LTEL3B;00.743.065/0001-27;LITELPARTICIPACOESSA'
        ,'BTOW3;00.776.574/0001-56;B2W–COMPANHIADIGITAL'
        ,'ENGI11,ENGI12,ENGI3,ENGI4;00.864.214/0001-06;ENERGISASA'
        ,'VSPT3,VSPT4;00.924.429/0001-75;FERROVIACENTROATLANTICASA'
        ,'CRTE3B,CRTE5B;00.938.574/0001-05;CONCESSIONARIARIO-TERESOPOLISSA'
        ,'CIEL3;01.027.058/0001-91;CIELOS.A.'
        ,'NEOE3;01.083.200/0001-18;NEOENERGIAS.A'
        ,'LIPR3;01.104.937/0001-70;ELETROBRÁSPARTICIPAÇÕESS.A.–ELETROPAR'
        ,'BBML3;01.107.327/0001-20;BBMLOGÍSTICAS.A.'
        ,'MRSA3B,MRSA5B,MRSA6B;01.417.222/0001-77;MRSLOGÍSTICAS/A'
        ,'GFSA3;01.545.826/0001-07;GAFISASA'
        ,'FIGE3,FIGE4;01.548.981/0001-79;INVESTIMENTOSBEMGES.A.'
        ,'BRFS3;01.838.723/0001-27;BRFS.A.'
        ,'QVQP3B;01.851.771/0001-55;524PARTICIPAÇOESSA'
        ,'CARD3;01.896.779/0001-38;CSUCARDSYSTEMS/A'
        ,'PEAB3,PEAB4;01.938.783/0001-11;CIADEPARTICIPAÇÕESALIANÇADABAHIA'
        ,'OPTS3B;01.957.772/0001-89;SUL116PARTICIPAÇÕESS.A.'
        ,'ECPR3,ECPR4;01.971.614/0001-83;EMP.NAC.DECOMÉRCIO,RÉDITOEPART.S.A.–ENCORPAR'
        ,'OPSE3B;02.062.747/0001-08;SUDESTESA'
        ,'PSSA3;02.149.205/0001-69;PORTOSEGUROSA'
        ,'UPKP3B;02.162.616/0001-94;UPTICKPARTICIPACOESSA'
        ,'GPCP3,GPCP4;02.193.750/0001-52;GPCPARTICIPACOESSA'
        ,'ALEF3B;02.217.319/0001-07;ALEFSA'
        ,'PRMN3B;02.291.077/0001-93;PRODUTORESENERGETICOSDEMANSOSA'
        ,'EMAE3,EMAE4;02.302.101/0001-42;EMAE-EMP.METROPOLITANAÁGUASENERGIAS.A'
        ,'OPHE3B;02.318.346/0001-68;OPPORTUNITYENERGIAEPARTICIPAÇÕESS.A.'
        ,'EKTR3,EKTR4;02.328.280/0001-97;ELEKTROREDESS.A.'
        ,'TGMA3;02.351.144/0001-18;TEGMAGESTÃOLOGÍSTICASA'
        ,'LWSA3;02.351.877/0001-52;LOCAWEBSERVIÇOSDEINTERNETS.A.'
        ,'LMED3;02.357.251/0001-53;LIFEMEDINDUSTRIALDEEQUIP.EART.MEDICOSEHOSP.S.A.'
        ,'IDNT3;02.365.069/0001-44;IDEIASNETSA'
        ,'RAIL3;02.387.241/0001-60;RUMOS.A.'
        ,'CPFE3;02.429.144/0001-93;CPFLENERGIASA'
        ,'EGIE3;02.474.103/0001-19;ENGIEBRASILENERGIAS.A.'
        ,'TIMP3;02.558.115/0001-21;TIMPARTICIPAÇÕESSA'
        ,'VIVT3,VIVT4;02.558.157/0001-62;TELEFÔNICABRASILS.A.'
        ,'CPTP3B;02.591.787/0001-39;CAPITALPARTPARTICIPAÇÕESSA'
        ,'SLCT3B;02.604.860/0001-60;INNCORPS.A.'
        ,'MNZC3B;02.664.042/0001-52;TERMINALGARAGEMMENEZESCORTESSA'
        ,'MMXM11,MMXM3;02.762.115/0001-49;MMXMINERAÇÃOEMETÁLICOSS.A.–EMRECUPERAÇÃOJUDICIAL'
        ,'STBP3;02.762.121/0001-04;SANTOSBRASILPARTICIPAÇÕESS.A.'
        ,'BETP3B;02.762.124/0001-30;BETAPARTPARTICIPAÇÕESSA'
        ,'OPGM3B;02.796.775/0001-40;GAMAPARTICIPAÇÕESS.A.'
        ,'COGN3;02.800.026/0001-40;KROTONEDUCACIONALS.A.'
        ,'CCRO3;02.846.056/0001-97;CCRS.A.'
        ,'SHOW3;02.860.694/0001-62;T4FENTRETENIMENTOSA'
        ,'JBSS3;02.916.265/0001-60;JBSSA'
        ,'HYPE3;02.932.074/0001-91;HYPERAS/A'
        ,'PDGR3;02.950.811/0001-89;PDGREALTYSAEMPREENDIMENTOSEPARTS–EMRECUPERAÇÃOJUDICIAL'
        ,'PRPT3B;02.992.449/0001-09;PROMPTPARTICIPAÇÕESS.A.'
        ,'GEPA3,GEPA4;02.998.301/0001-81;RIOPARANAPANEMAENERGIASA'
        ,'TRPL3,TRPL4;02.998.611/0001-04;CTEEP-CIATRANSMENERGIAELÉTR.PAULISTA'
        ,'TPIS3;03.014.553/0001-91;TPI–TRIUNFOPARTICIPACOESEINVESTIMENTOSS.A.'
        ,'EQTL3;03.220.438/0001-73;EQUATORIALENERGIAS/A'
        ,'DTCY3,DTCY4;03.303.999/0001-36;DTCOM–DIRECTTOCOMPANYS.A.'
        ,'LIGT3;03.378.521/0001-75;LIGHTSA'
        ,'ENMT3,ENMT4;03.467.321/0001-99;ENERGISAMATOGROSSO–DISTRIBUIDORADEENERGIAS.A.'
        ,'IVPR3B,IVPR4B;03.758.318/0001-24;INVEST.EPART.EMINFRA-ESTR.S/A-INVEPAR'
        ,'BSCS-CRIT2B0,BSCS-CRIT3B0;03.767.538/0001-14;BRAZILIANSECURITIESCIASECURITIZAÇÃO'
        ,'BRAP3,BRAP4;03.847.461/0001-92;BRADESPARS/A'
        ,'MRFG3;03.853.896/0001-40;MARFRIGGLOBALFOODSSA'
        ,'ENBR3;03.983.431/0001-03;EDPENERGIASDOBRASILS/A'
        ,'CABI3B;04.030.182/0001-02;CABINDAPARTICIPAÇÕESSA'
        ,'CACO3B;04.031.213/0001-31;CACONDEPARTICIPAÇÕESSA'
        ,'LIQO3;04.032.433/0001-80;LIQPARTICIPAÇÕESS.A.'
        ,'CAIA3B;04.038.763/0001-82;CAIANDAPARTICIPAÇÕESSA'
        ,'SQIA3;04.065.791/0001-99;SINQIAS.A.'
        ,'TIET11,TIET3,TIET4;04.128.563/0001-10;AESTIETÊENERGIAS.A'
        ,'ECOR3;04.149.454/0001-80;ECORODOVIASINFRAESTRUTURAELOGÍSTICAS.A.'
        ,'ENEV3;04.423.567/0001-21;ENEVAS.A.'
        ,'BIOM11,BIOM3;04.752.991/0001-10;BIOMMSA'
        ,'FRIO3;04.821.041/0001-08;METALFRIOSOLUTIONSS/A'
        ,'EQPA3,EQPA5,EQPA6,EQPA7;04.895.728/0001-80;CENTRAISELÉTRICASDOPARÁS.A.–CELPA'
        ,'BAZA3;04.902.979/0001-44;BANCODAAMAZÔNIAS.A.'
        ,'BPAR3;04.913.711/0001-08;BANCODOESTADODOPARÁS/A.'
        ,'SEER3;04.986.320/0001-13;SEREDUCACIONALS.A.'
        ,'HAPV3;05.197.443/0001-38;HAPVIDAPARTICIPAÇÕESEINVESTIMENTOSS.A.'
        ,'LTLA3B;05.495.546/0001-84;LITELAPARTICIPAÇÕESS.A.'
        ,'SMLS3;05.730.375/0001-20;SMILESFIDELIDADES.A.'
        ,'TESA12,TESA3;05.799.312/0001-20;TERRASANTAAGROS.A.'
        ,'ALSO3;05.878.397/0001-32;ALIANSCESONAESHOPPINGCENTERSS.A.'
        ,'WTVR-CRI11B0;06.137.677/0001-52;BRPR56SECURITZADORACREDIMOBSA'
        ,'GOLL11,GOLL12,GOLL4;06.164.253/0001-87;GOLLINHASAEREASINTELIGENTESSA'
        ,'EQMA3B,EQMA5B,EQMA6B;06.272.793/0001-84;COMPANHIAENERGÉTICADOMARANHÃOCEMAR'
        ,'LINX3;06.948.969/0001-75;LINXS.A.'
        ,'BRML3;06.977.745/0001-91;BRMALLSPARTICIPAÇOESS.A.'
        ,'BRPR3;06.977.751/0001-49;BRPROPERTIESS.A.'
        ,'CTCA3;06.981.381/0001-13;CTC–CENTRODETECNOLOGIACANAVIEIRAS.A.'
        ,'COCE3,COCE5,COCE6;07.047.251/0001-70;CIAENERGCEARA–COELCE'
        ,'BZRS-CRI11B0;07.119.838/0001-48;BRAZILREALTYCIASECURITIZADORADECREDITOSIMOLIARIOS'
        ,'MDIA3;07.206.816/0001-15;MDIASBRANCOSAINDECOMDEALIMENTOS'
        ,'BNBR3;07.237.373/0001-20;BANCODONORDESTEDOBRASILSA'
        ,'CNSY3;07.437.016/0001-05;CINESYSTEMS.A.'
        ,'ABEV3;07.526.557/0001-00;AMBEVS.A.'
        ,'SMFT3;07.594.978/0001-78;SMARTFITESCOLADEGINÁSTICAEDANÇAS.A.'
        ,'AGRO3;07.628.528/0001-59;BRASILAGROCIABRASDEPROPAGRICOLAS'
        ,'EMBR3;07.689.002/0001-89;EMBRAERS.A.'
        ,'SGPS3;07.718.269/0001-57;SPRINGSGLOBALPARTICIPAÇÕESS/A'
        ,'MULT3;07.816.890/0001-53;MULTIPLANEMP.IMOBILIARIOSS/A'
        ,'CRDE3;07.820.907/0001-46;CR2EMPREENDIMENTOSIMOBILIÁRIOSS/A'
        ,'TAEE11,TAEE3,TAEE4;07.859.971/0001-30;TRANSMISSORAALIANÇADEENERGIAELÉTRICAS.A.'
        ,'MTRE3;07.882.930/0001-65;MITREREALTYEMPREENDIMENTOSEPARTICIPAÇÕESS.A.'
        ,'CCXC3;07.950.674/0001-04;CCXCARVÃODACOLOMBIAS.A.'
        ,'TCSA3;08.065.557/0001-12;TECNISAS/A'
        ,'LPSB3;08.078.847/0001-09;LPSBRASILCONSULTORIADEIMOVEISS/A'
        ,'IGSN3;08.159.965/0001-33;IGUASANEAMENTOS.A.'
        ,'JHSF3;08.294.224/0001-65;JHSFPARTICIPAÇÕESSA'
        ,'EZTC3;08.312.229/0001-73;EZTECEMPREEND.EPARTICIPAÇÕESS/A'
        ,'CSRN3,CSRN5,CSRN6;08.324.196/0001-81;CIAENERGÉTICADORIOGRANDEDONORTE'
        ,'MRVE3;08.343.492/0001-20;MRVENGENHARIAEPARTICIPAÇÕESS/A'
        ,'ALUP11,ALUP3,ALUP4;08.364.948/0001-38;ALUPARINVESTIMENTOS/A'
        ,'GUAR3;08.402.943/0001-52;GUARARAPESCONFECÇÕESSA'
        ,'CPRE3;08.439.659/0001-50;CPFLENERGIASRENOVÁVEISS.A.'
        ,'CEED3,CEED4;08.467.115/0001-00;CIAESTADUALDEDISTRIBUIÇÃODEENERGIAELETRICA'
        ,'RNEW11,RNEW3,RNEW4;08.534.605/0001-74;RENOVAENERGIAS/A'
        ,'GPAR3;08.560.444/0001-93;COMPANHIACELGDEPARTICIPAÇÕES'
        ,'PTCA11,PTCA3;08.574.411/0001-00;PRÁTICAKLIMAQUIPINDÚSTRIAECOMÉRCIOS.A.'
        ,'BBRK3;08.613.550/0001-98;BRASILBROKERSPARTICIPAÇÕESSA'
        ,'BRCS-CRI1AB0;08.653.753/0001-08;BRCSECURITIZADORASA'
        ,'GSHP3;08.764.621/0001-53;GENERALSHOPPINGEOUTLETSDOBRASILS.A.'
        ,'MSRO3;08.795.211/0001-70;MAESTROLOCADORADEVEÍCULOSS.A.'
        ,'CCPR3;08.801.621/0001-86;CYRELACOMMERCIALPROPERTIESSAEMPPART'
        ,'YDUQ3;08.807.432/0001-10;ESTACIOPARTICIPAÇÕESSA'
        ,'TRIS3;08.811.643/0001-27;TRISULS/A'
        ,'DMMO11,DMMO3;08.926.302/0001-05;DOMMOENERGIAS.A.'
        ,'LOGG3;09.041.168/0001-10;LOGCOMMERCIALPROPERTIESEPARTICIPAÇÕESS.A.'
        ,'OSXB3;09.112.685/0001-32;OSXBRASILS.A.–EMRECUPERAÇÃOJUDICIAL'
        ,'OMGE3;09.149.503/0001-06;OMEGAGERAÇÃOS.A.'
        ,'ANIM3;09.288.252/0001-32;ANIMAHOLDINGS/A'
        ,'TECN3;09.295.063/0001-97;TECHNOSSA'
        ,'AZUL4;09.305.994/0001-29;AZULS.A.'
        ,'B3SA3;09.346.601/0001-25;B3S.A.–BRASIL,BOLSA,BALCÃO'
        ,'PDGS-CRI15B0;09.538.973/0001-53;PDGCOMPANHIASECURITIZADORA–EMRECUPERAÇÃOJUDICIAL'
        ,'INNT3;09.611.768/0001-76;INTERCONSTRUTORAEINCORPORADORAS.A'
        ,'LCAM3;10.215.988/0001-60;COMPANHIADELOCAÇÃODASAMERICAS'
        ,'AFLT3;10.338.320/0001-00;AFLUENTETRANSMISSÃODEENERGIAELETRICAS/A'
        ,'ADHM1,ADHM3;10.345.009/0001-98;ADVANCEDDIGITALHEALTHMEDICINAPREVENTIVAS.A.'
        ,'PRIO3;10.629.105/0001-68;PETRORIOS.A.'
        ,'CVCB3;10.760.260/0001-19;CVCBRASILOPERADORAEAGÊNCIADEVIAGENSSA'
        ,'CEPE3,CEPE5,CEPE6;10.835.932/0001-08;CIAENERGÉTICADEPERNAMBUCO–CELPE'
        ,'FLEX3;10.851.805/0001-00;FLEXGESTÃODERELACIONAMENTOS.A.'
        ,'BPHA3;11.395.624/0001-71;BRASILPHARMASA–EMRECUPERAÇÃOJUDICIAL'
        ,'ENAT3;11.669.021/0001-10;ENAUTAPARTICIPAÇÕESS.A.'
        ,'APER3;11.721.921/0001-60;ALPERCONSULTORIAECORRETORADESEGUROSS.A.'
        ,'QUAL3;11.992.680/0001-93;QUALICORPSA'
        ,'MDNE3;12.049.631/0001-84;MOURADUBEUXENGENHARIAS/A'
        ,'APCS-CRI05B0;12.130.744/0001-00;TRUESECURITIZADORAS.A.'
        ,'PLSC-CRI1CB0;12.261.588/0001-16;POLOCAPITALSECURITIZADORAS/A'
        ,'BGIP3,BGIP4;13.009.717/0001-46;BANCODOESTADODESERGIPESA'
        ,'CNTO3;13.217.485/0001-11;GRUPOSBFS.A.'
        ,'BKBR3;13.574.594/0001-96;BKBRASILOPERAÇÃOEASSESSORIAARESTAURANTESS.A.'
        ,'ELEK3,ELEK4;13.788.120/0001-47;ELEKEIROZS/A'
        ,'SNSY3,SNSY5,SNSY6;14.807.945/0001-24;SANSUYSAINDÚSTRIADEPLASTICOS'
        ,'GAFL-CRA02B0;14.876.090/0001-93;GAIAAGROSECURITIZADORAS.A.'
        ,'CORR3,CORR4;15.101.405/0001-93;CORREARIBEIROSACOMIND'
        ,'CRPG3,CRPG5,CRPG6;15.115.504/0001-24;TRONOXPIGMENTOSDOBRASILS.A.'
        ,'CEEB3,CEEB5,CEEB6;15.139.629/0001-94;CIAELETRICIDADEDABAHIA'
        ,'FESA3,FESA4;15.141.799/0001-03;CIAFERROLIGASBAHIAFERBASA'
        ,'CSAB3,CSAB4;15.144.017/0001-90;CIASEGUROSALIANCABAHIA'
        ,'BSEV3;15.527.906/0001-36;BIOSEVS.A.'
        ,'SUZB3;16.404.287/0001-55;SUZANOS.A.'
        ,'ARZZ3;16.590.234/0001-76;AREZZOINDÚSTRIAECOMERCIOS/A'
        ,'DIRR3;16.614.075/0001-00;DIRECIONALENGENHARIASA'
        ,'RENT3;16.670.085/0001-55;LOCALIZARENTACARSA'
        ,'CMIG3,CMIG4;17.155.730/0001-64;CIAENERGMINASGERAIS–CEMIG'
        ,'MMAQ3,MMAQ4;17.161.241/0001-15;MINASMAQUINASSA'
        ,'MEND3,MEND5;17.162.082/0001-73;MENDESJUNIORENGENHARIASA'
        ,'RPAD3,RPAD5,RPAD6;17.167.396/0001-69;ALFAHOLDINGSSA'
        ,'CRIV3,CRIV4;17.167.412/0001-13;FINANCEIRAALFAS.A.-C.F.I'
        ,'BMEB3,BMEB4;17.184.037/0001-10;BANCOMERCANTILBRASILSA'
        ,'BRGE11,BRGE12,BRGE3,BRGE5,BRGE6,BRGE7,BRGE8;17.193.806/0001-46;CONSORCIOALFADEADMINISTRAÇÃOSA'
        ,'CEDO3,CEDO4;17.245.234/0001-00;COMPANHIADEFIACAOETECIDOSCEDROECACHOEIRA'
        ,'CSMG3;17.281.106/0001-03;COMPANHIADESANEAMENTODEMINASGERAIS'
        ,'MEAL3;17.314.329/0001-20;INTERNATIONALMEALCOMPANYALIMENTAÇÃOS.A.'
        ,'BBSE3;17.344.597/0001-94;BBSEGURIDADEPARTICIPAÇÕESS.A.'
        ,'RLOG3;17.346.997/0001-39;COSANLOGÍSTICAS.A.'
        ,'PSVM11;18.494.485/0001-82;PORTOSUDESTEV.M.S.A.'
        ,'PRNR3;18.593.815/0001-97;PRINERSERVIÇOSINDUSTRIAISS.A.'
        ,'PARD3;19.378.769/0001-76;INSTITUTOHERMESPARDINIS/A'
        ,'CATA3,CATA4;19.526.748/0001-50;COMPANHIAINDUSTRIALCATAGUASES'
        ,'GNDI3;19.853.511/0001-84;NOTREDAMEINTERMÉDICAPARTICIPAÇÕESS.A.'
        ,'OFSA3;20.258.278/0001-70;OUROFINOSAÚDEANIMALPARTICIPAÇÕESS.A.'
        ,'CTSA3,CTSA4,CTSA8;21.255.567/0001-89;CIATECIDOSSANTANENSE'
        ,'MOVI3;21.314.559/0001-66;MOVIDAPARTICIPAÇÕESS.A.'
        ,'FHER3;22.266.175/0001-88;FERTILIZANTESHERINGERS.A.–EMRECUPERAÇÃOJUDICIAL'
        ,'CTNM3,CTNM4;22.677.520/0001-76;CIATECIDOSNORTEDEMINAS–COTEMINAS'
        ,'FRRN3B,FRRN5B,FRRN6B;24.962.466/0001-36;RUMOMALHANORTES.A.'
        ,'MILS3;27.093.558/0001-15;MILLSESTRUTURASESERVIÇOSDEENGENHARIAS/A'
        ,'BEES3,BEES4;28.127.603/0001-78;BANESTESSABANCODOESTADODOESPIRITOSANTO'
        ,'ABCB4;28.195.667/0001-06;BANCOABCBRASILS/A'
        ,'SCAR3;29.780.061/0001-09;SÃOCARLOSEMPREENDSEPARTICIPAÇÕESS.A'
        ,'NRTQ3;29.950.060/0001-57;NORTECQUIMICAS.A.'
        ,'SULA11,SULA3,SULA4;29.978.814/0001-87;SULAMERICAS/A'
        ,'BPAC11,BPAC3,BPAC5;30.306.294/0001-45;BANCOBTGPACTUALS/A'
        ,'HAGA3,HAGA4;30.540.991/0001-66;HAGAS.A.INDÚSTRIAECOMÉRCIO'
        ,'NTCO3;32.785.497/0001-97;NATURA&COHOLDINGS.A.'
        ,'PETR3,PETR4;33.000.167/0001-01;PETRÓLEOBRASILEIROS.A.–PETROBRAS'
        ,'LAME10,LAME3,LAME4;33.014.556/0001-96;LOJASAMERICANASSA'
        ,'FCAP3,FCAP4;33.017.039/0001-70;MASSAFALIDADESAMINDUSTRIASSA'
        ,'JFEN3;33.035.536/0001-00;JOAOFORTESENGENHARIASA'
        ,'MERC3,MERC4;33.040.601/0001-87;MERCANTILDOBRFINCSACFI'
        ,'VVAR3;33.041.260/0652-90;VIAVAREJOS.A.'
        ,'CSNA3;33.042.730/0001-04;CIASIDERURGICANACIONAL'
        ,'CBEE3;33.050.071/0001-58;AMPLAENERGIAESERVIÇOSS.A.'
        ,'MOAR3;33.102.476/0001-92;MONTEIROARANHASA'
        ,'TCNO3,TCNO4;33.111.246/0001-90;TECNOSOLOS/A–EMRECUPERAÇÃOJUDICIAL'
        ,'VLID3;33.113.309/0001-47;VALIDSOLUÇÕESESERVIÇOSDESEGURANÇAEMMEIOSDEPAGAMENTOEIDENTIFICAÇÃOS.A.'
        ,'HOOT3,HOOT4;33.200.049/0001-47;HOTEISOTHONSA'
        ,'WLMM3,WLMM4;33.228.024/0001-51;WLMINDÚSTRIAECOMÉRCIOS.A.'
        ,'UGPA3;33.256.439/0001-39;ULTRAPARPARTICIPAÇÕESSA'
        ,'IRBR3;33.376.989/0001-91;IRB–BRASILRESSEGUROSS.A.'
        ,'SOND3,SOND5,SOND6;33.386.210/0001-19;SONDOTECNICAENGENHARIADESOLOSS/A.'
        ,'RPMG3;33.412.081/0001-96;REFINARIAPETMANGUINHOSSA'
        ,'TKNO3,TKNO4;33.467.572/0001-34;TEKNOS.A.INDÚSTRIAECOMÉRCIO'
        ,'VALE3;33.592.510/0001-54;VALES.A.'
        ,'GGBR3,GGBR4;33.611.500/0001-19;GERDAUS.A.'
        ,'VIVA3;33.839.910/0001-11;VIVARAPARTICIPAÇÕESS.A.'
        ,'CEGR3;33.938.119/0001-69;CIA.DISTRIB.DEGÁSDORIODEJANEIRO'
        ,'UNIP3,UNIP5,UNIP6;33.958.695/0001-78;UNIPARCARBOCLOROS.A.'
        ,'BMIN3,BMIN4;34.169.557/0001-72;BANCOMERCANTILDEINVESTIMENTOSS.A.'
        ,'BRDT3;34.274.233/0001-02;PETROBRASDISTRIBUIDORASA'
        ,'QUSW3;35.791.391/0001-94;QUALITYSOFTWARES.A.'
        ,'BRQB3;36.542.025/0001-64;BRQSOLUÇÕESEMINFORMÁTICAS.A.'
        ,'BRKM3,BRKM5,BRKM6;42.150.391/0001-70;BRASKEMS.A.'
        ,'LOGN3;42.278.291/0001-24;LOG-INLOGISTICAINTERMODALSA'
        ,'WIZS3;42.278.473/0001-03;WIZSOLUÇÕESECORRETAGEMDESEGUROSS.A.'
        ,'BTTL3;42.331.462/0001-31;BATTISTELLAADMEPARTICIPAÇÕESSA'
        ,'AALR3;42.771.949/0001-35;CENTRODEIMAGEMDIAGNÓSTICOSS.A.'
        ,'IGBR3;43.185.362/0001-07;IGBELETRÔNICAS.A.–EMRECUPERAÇÃOJUDICIAL'
        ,'EVEN3;43.470.988/0001-65;EVENCONSTRUTORAEINCORPORADORAS/A'
        ,'SBSP3;43.776.517/0001-80;CIASANEAMENTOBÁSICOESTADOSÃOPAULO'
        ,'CEAB3;45.242.914/0001-05;C&AMODASS.A.'
        ,'PFRM3;45.453.214/0001-51;PROFARMADISTRIB.PRODUTOSFARMACEUTICOS'
        ,'BAHI3;45.987.245/0001-92;BAHEMAEDUCACAOS.A.'
        ,'PCAR3,PCAR4;47.508.411/0001-56;COMPANHIABRASILEIRADEDISTRIBUIÇÃO'
        ,'MGLU3;47.960.950/0001-21;MAGAZINELUIZASA'
        ,'HBOR3;49.263.189/0001-02;HELBOREMPREENDIMENTOSS/A'
        ,'LLIS3;49.669.856/0001-43;RESTOQUECOMÉRCIOECONFECÇÕESDEROUPASSA'
        ,'BOBR3,BOBR4;50.564.053/0001-03;BOMBRILSA'
        ,'CSAN3;50.746.577/0001-15;COSANS.A.'
        ,'VULC3;50.926.955/0001-42;VULCABRAS|AZALEIAS.A.'
        ,'NUTR3;51.128.999/0001-90;NUTRIPLANTINDUSTRIAECOMÉRCIOS/A'
        ,'IGTA3;51.218.147/0001-93;IGUATEMIEMPRESADESHOPPINGCENTERSS/A'
        ,'SMTO3;51.466.860/0001-56;SÃOMARTINHOSA'
        ,'PLAS11,PLAS3;51.928.174/0001-50;PLASCARPARTICIPAÇÕESINDUSTRIAISS.A'
        ,'JSLG3;52.548.435/0001-79;JSLS.A.'
        ,'TOTS3;53.113.791/0001-22;TOTVSS.A'
        ,'EUCA3,EUCA4;56.643.018/0001-66;EUCATEXSAINDECOMERCIO'
        ,'ROMI3;56.720.428/0001-63;INDÚSTRIASROMIS.A.'
        ,'BMKS3;56.992.423/0001-90;BICICLETASMONARKSA'
        ,'ODPV3;58.119.199/0001-51;ODONTOPREVS/A'
        ,'WHRL3,WHRL4;59.105.999/0001-86;WHIRLPOOLS.A'
        ,'BPAN4;59.285.411/0001-13;BANCOPANSA'
        ,'PPAR3;59.789.545/0001-71;POLPARS.A.'
        ,'PMAM3;60.398.369/0004-79;PARANAPANEMASA'
        ,'LEVE3;60.476.884/0001-87;MAHLEMETALLEVES.A.'
        ,'SLED11,SLED3,SLED4;60.500.139/0001-26;SARAIVASALIVREIROSEDITORES'
        ,'JPSA3;60.543.816/0001-93;JEREISSATIPARTICIPAÇÕESS/A'
        ,'JBDU3,JBDU4;60.637.238/0001-54;INDSJBDUARTESA'
        ,'NEMO3,NEMO5,NEMO6;60.651.809/0001-05;SUZANOHOLDINGS.A.'
        ,'MSPA3,MSPA4;60.730.348/0001-66;COMPANHIAMELHORAMENTOSDESÃOPAULO'
        ,'BBDC3,BBDC4;60.746.948/0001-12;BANCOBRADESCOS.A.'
        ,'BRIV3,BRIV4;60.770.336/0001-65;BANCOALFADEINVESTIMENTOSA'
        ,'FLRY3;60.840.055/0001-31;FLEURYSA'
        ,'BDLL3,BDLL4;60.851.615/0001-53;BARDELLASAINDSMECANICAS'
        ,'ITUB3,ITUB4;60.872.504/0001-23;ITAÚUNIBANCOHOLDINGS.A.'
        ,'NORD3;60.884.319/0001-59;NORDONINDSMETALURGICASSA'
        ,'USIM3,USIM5,USIM6;60.894.730/0001-05;USINASSIDERURGICASDEMINASGERAISSA'
        ,'CESP3,CESP5,CESP6;60.933.603/0001-78;CESP–COMPANHIAENERGÉTICADESÃOPAULO'
        ,'CALI3,CALI4;61.022.042/0001-18;CONSTADOLPHOLINDENBERGSA'
        ,'IDVL3,IDVL4;61.024.352/0001-71;BANCOINDUSVALS.A.'
        ,'MGEL3,MGEL4;61.065.298/0001-02;MANGELSINDUSTRIALS.A.'
        ,'RSID3;61.065.751/0001-80;ROSSIRESIDENCIALSA'
        ,'NAFG3,NAFG4;61.067.161/0001-97;NADIRFIGUEIREDOINDECOMSA'
        ,'ALPA3,ALPA4;61.079.117/0001-05;ALPARGATASSA'
        ,'ESTR3,ESTR4;61.082.004/0001-50;MANUFATURADEBRINQUEDOSESTRELASA'
        ,'CAMB3;61.088.894/0001-08;CAMBUCISA'
        ,'ETER3;61.092.037/0001-81;ETERNITS.A.-EMRECUPERAÇÃOJUDICIAL'
        ,'MYPK3;61.156.113/0001-75;IOCHPE-MAXIONSA'
        ,'APTI3,APTI4;61.156.931/0001-78;SIDERURGICAJLALIPERTISA'
        ,'BMGB4;61.186.680/0001-74;BANCOBMGS/A'
        ,'AMAR3;61.189.288/0001-89;MARISALOJASSA'
        ,'AZEV3;61.351.532/0001-68;AZEVEDO&TRAVASSOSSA'
        ,'BALM3,BALM4;61.374.161/0001-30;BAUMERSA'
        ,'DASA3;61.486.650/0001-83;DIAGNOSTICOSDAAMERICASA'
        ,'ITSA3,ITSA4;61.532.644/0001-15;ITAÚSA–INVESTIMENTOSITAÚS.A.'
        ,'REDE3;61.584.140/0001-49;REDEENERGIAPARTICIPAÇÕESS.A.'
        ,'RADL3;61.585.865/0001-51;RAIADROGASILS.A.'
        ,'CGAS3,CGAS5;61.856.571/0001-17;COMPANHIADEGÁSDESÃOPAULO–COMGÁS'
        ,'AHEB1,AHEB11,AHEB13,AHEB3,AHEB5,AHEB6;62.002.886/0001-60;SÃOPAULOTURISMOS.A.'
        ,'PINE3,PINE4;62.144.175/0001-20;BANCOPINES/A'
        ,'CAML3;64.904.295/0001-03;CAMILALIMENTOSS/A'
        ,'RDNI3;67.010.660/0001-24;RNINEGÓCIOSIMOBILIÁRIOSS.A.'
        ,'VIVR3;67.571.414/0001-41;VIVERINCORP.ECONSTRUTORAS.A.-EMRECUPERAÇÃOJUDICIAL'
        ,'BEEF11,BEEF3;67.620.377/0001-14;MINERVAS/A'
        ,'TEND3;71.476.527/0001-35;CONSTRUTORATENDAS/A'
        ,'CYRE3;73.178.600/0001-18;CYRELABRAZILREALTYSAEMPRSEPARTS'
        ,'CRFB3;75.315.333/0001-09;ATACADÃOS.A.'
        ,'CPLE3,CPLE5,CPLE6;76.483.817/0001-20;COMPANHIAPARANAENSEDEENERGIA'
        ,'SAPR11,SAPR3,SAPR4;76.484.013/0001-45;CIA.DESANEAMENTODOPARANÁ–SANEPAR'
        ,'OIBR3,OIBR4;76.535.764/0001-43;OIS.A.–EMRECUPERAÇÃOJUDICIAL'
        ,'INEP3,INEP4;76.627.504/0001-06;INEPARSAINDECONSTRUÇÕES–EMRECUPERAÇÃOJUDICIAL'
        ,'HGTX3;78.876.950/0001-71;CIAHERING'
        ,'MTIG3,MTIG4;80.227.184/0001-66;METALGRAFICAIGUACUSA'
        ,'POSI3;81.243.735/0001-48;POSITIVOTECNOLOGIAS.A.'
        ,'CASN3,CASN4;82.508.433/0001-17;CIACAT.DEÁGUASESANEAMENTO–CASAN'
        ,'TEKA3,TEKA4;82.636.986/0001-55;TEKATECELAGEMKUEHNRICHSA–EMRECUPERAÇÃOJUDICIAL'
        ,'CTKA3,CTKA4;82.640.558/0001-04;KARSTENSA'
        ,'EALT3,EALT4;82.643.537/0001-34;ELECTROAÇOALTONAS/A'
        ,'TXRX3;82.982.075/0001-80;TÊXTILRENAUXVIEWS/A'
        ,'PTBL3;83.475.913/0001-91;PBGS/A'
        ,'CLSC3,CLSC4;83.878.892/0001-55;CENTRAISELETRICASDESANTACATARINAS.A'
        ,'WEGE3;84.429.695/0001-11;WEGSA'
        ,'TUPY3;84.683.374/0001-49;TUPYSA'
        ,'DOHL3,DOHL4;84.683.408/0001-03;DOHLERS.A.'
        ,'MWET3,MWET4;84.683.671/0001-94;WETZELS.A.EMRECUPERAÇÃOJUDICIAL'
        ,'SHUL3,SHUL4;84.693.183/0001-68;SCHULZSA'
        ,'RSUL3,RSUL4;85.778.074/0001-06;METALURGICARIOSULENSESA'
        ,'MTSA3,MTSA4;86.375.425/0001-09;METISAMETALÚRGICATIMBOENSESA'
        ,'FRTA3;86.550.951/0001-50;POMIFRUTASS/A–EMRECUPERAÇÃOJUDICIAL'
        ,'JOPA3,JOPA4;87.456.562/0001-22;JOSAPAR-JOAQUIMOLIVEIRAS/APARTICIP'
        ,'HBTS3,HBTS5,HBTS6;87.762.563/0001-03;COMPANHIIAHABITASULDEPARTICIPAÇÕES'
        ,'FRAS3;88.610.126/0001-29;FRAS-LESA'
        ,'MNDL3;88.610.191/0001-54;MUNDIALS.A–PRODUTOSDECONSUMO'
        ,'POMO3,POMO4;88.611.835/0001-29;MARCOPOLOSA'
        ,'PTNT3,PTNT4;88.613.658/0001-10;PETTENATISAINDTEXTIL'
        ,'RAPT3,RAPT4;89.086.144/0001-16;RANDONS.A.IMPLEMENTOSEPARTICIPAÇÕES'
        ,'SLCE3;89.096.457/0001-55;SLCAGRICOLASA'
        ,'LUPA11,LUPA3;89.463.822/0001-12;LUPATECHS/A–EMRECUPERAÇÃOJUDICIAL'
        ,'KLBN11,KLBN3,KLBN4;89.637.490/0001-45;KLABINS.A.'
        ,'GRND3;89.850.341/0001-60;GRENDENESA'
        ,'MNPR3;90.076.886/0001-40;MINUPARPARTICIPACOESSA'
        ,'SANB11,SANB3,SANB4;90.400.888/0001-42;BANCOSANTANDER(BRASIL)S.A.'
        ,'UCAS3;90.441.460/0001-48;UNICASAINDÚSTRIADEMÓVEISS.A.'
        ,'RCSL3,RCSL4;91.333.666/0001-17;RECRUSULSA'
        ,'STTR3;91.495.499/0001-00;STARAS.A.–INDÚSTRIADEIMPLEMENTOSAGRÍCOLAS'
        ,'FNCN3;91.669.747/0001-92;FINANSINOSS/A-CRÉDITO,FINAN.EINVEST.'
        ,'KEPL11,KEPL3;91.983.056/0001-69;KEPLERWEBERSA'
        ,'CGRA3,CGRA4;92.012.467/0001-70;GRAZZIOTINSA'
        ,'LUXM3,LUXM4;92.660.570/0001-26;TREVISAINVESTIMENTOSSA'
        ,'PNVL3,PNVL4;92.665.611/0001-77;DIMEDSADISTRIBUIDORADEMEDICAMENTOS'
        ,'GOAU3,GOAU4;92.690.783/0001-09;METALURGICAGERDAUSA'
        ,'PATI3,PATI4;92.693.019/0001-89;PANATLANTICASA'
        ,'BRSR3,BRSR5,BRSR6;92.702.067/0001-96;BANCODOESTADODORIOGRANDEDOSULSA'
        ,'EEEL3,EEEL4;92.715.812/0001-31;CIAESTADUALDEGERACAOETRANSMISSAODEENERGIAELETRICA'
        ,'HETA3,HETA4;92.749.225/0001-63;HERCULESS/A–FABRICADETALHERES'
        ,'LREN3;92.754.738/0001-62;LOJASRENNERSA'
        ,'TASA13,TASA15,TASA17,TASA3,TASA4;92.781.335/0001-02;TAURUSARMASS.A.'
        ,'RANI3,RANI4;92.791.243/0001-03;CELULOSEIRANISA'
        ,'SPRI3,SPRI5,SPRI6;92.929.520/0001-00;SPRINGERSA'
        ,'MAPT3,MAPT4;93.828.986/0001-73;CEMEPEINVESTIMENTOSSA'
        ,'BAUH3,BAUH4;95.426.862/0001-97;EXCELSIORALIMENTOSSA.'
        ,'ODER3,ODER4;97.191.902/0001-94;CONSERVASODERICHSA'
        ,'DTEX3;97.837.181/0001-47;DURATEXS.A.'
        ,'ABCP11;01.201.140/0001-90;GrandPlazaShopping'
        ,'ALMI11;07.122.725/0001-00;TorreAlmirante'
        ,'ALZR11;28.737.771/0001-85;AlianzaTrustRendaImobiliária'
        ,'ANCR11B;07.789.135/0001-27;AncarIC'
        ,'ATSA11B;12.809.972/0001-00;AtriumShoppingSantoAndré'
        ,'BBFI11B;07.000.400/0001-46;BBProgressivo'
        ,'BBPO11;14.410.722/0001-29;BBProgressivoII'
        ,'BBRC11;12.681.340/0001-04;BBRendaCorporativa'
        ,'BBVJ11;10.347.985/0001-80;BBVotorantimCidadeJardimContinentalTower'
        ,'BCFF11;11.026.627/0001-38;BTGPactualFundodeFundos'
        ,'BCIA11;20.216.935/0001-17;BradescoCarteiraImobiliáriaAtiva'
        ,'BCRI11;22.219.335/0001-38;BanestesRecebíveisImobiliários'
        ,'BMII11;02.027.437/0001-44;BrasilioMachado'
        ,'BMLC11B;14.376.247/0001-11;BMBrascanLajesCorporativas'
        ,'BNFS11;15.570.431/0001-60;BanrisulNovasFronteiras'
        ,'BPFF11;17.324.357/0001-28;BrasilPluralAbsolutoFundodeFundos'
        ,'BRCR11;08.924.783/0001-01;BTGPactualCorporateOffice'
        ,'BTCR11;29.787.928/0001-40;BTGPactualCréditoImobiliário'
        ,'BVAR11;21.126.204/0001-43;BrasilVarejo'
        ,'CARE11;13.584.584/0001-31;BrazilianGraveyardandDeathCare'
        ,'CBOP11;17.144.039/0001-85;CastelloBrancoOfficePark'
        ,'CEOC11;15.799.397/0001-09;CyrelaCommercialProperties'
        ,'CNES11;13.551.286/0001-45;CENESP'
        ,'CPTS11B;18.979.895/0001-13;CapitaniaSecurities'
        ,'CTXT11;00.762.723/0001-28;CentroTêxtilInternacional'
        ,'CXCE11B;10.991.914/0001-15;CaixaCedae'
        ,'CXRI11;17.098.794/0001-70;CaixaRioBravo'
        ,'CXTL11;12.887.506/0001-43;CaixaTRXLogísticaRenda'
        ,'DOMC11;17.374.696/0001-19;ReagRendaImobiliária'
        ,'DRIT11B;10.456.810/0001-00;MultigestãoRendaComercial'
        ,'EDFO11B;06.175.262/0001-73;EdifícioOurinvest'
        ,'EDGA11;15.333.306/0001-37;EdifícioGaleria'
        ,'ELDO11B;13.022.994/0001-99;Eldorado'
        ,'EURO11;05.437.916/0001-27;EUROPAR'
        ,'FAED11;11.179.118/0001-45;AnhangueraEducacional'
        ,'FAMB11B;05.562.312/0001-02;EdifícioAlmiranteBarroso'
        ,'FCAS11;10.993.386/0001-33;EdifícioCastelo'
        ,'FCFL11;11.602.654/0001-01;CampusFariaLima'
        ,'FEXC11;09.552.812/0001-14;BTGPactualFundodeCRI'
        ,'FFCI11;03.683.056/0001-86;RioBravoRendaCorporativa'
        ,'FIGS11;17.590.518/0001-25;GeneralShoppingAtivoeRenda'
        ,'FIIB11;14.217.108/0001-45;IndustrialdoBrasil'
        ,'FIIP11B;08.696.175/0001-97;RBCapitalRendaI'
        ,'FIVN11;17.854.016/0001-64;VidaNova'
        ,'FIXX11;17.329.029/0001-14;FatorIFIX'
        ,'FLMA11;04.141.645/0001-03;ContinentalSquareFariaLima'
        ,'FLRP11;10.375.382/0001-91;FloripaShopping'
        ,'FMOF11;01.633.741/0001-72;MemorialOffice'
        ,'FOFT11;16.875.388/0001-04;CSHGTOPFOFII2'
        ,'FPAB11;03.251.720/0001-18;ProjetoAguaBranca'
        ,'FPNG11;17.161.979/0001-82;PedraNegraRendaImobiliária'
        ,'FVBI11;13.022.993/0001-44;VBIFL4440'
        ,'FVPQ11;00.332.266/0001-31;ViaParqueShopping'
        ,'GGRC11;26.614.291/0001-00;GGRCovepiRenda'
        ,'GRLV11;17.143.998/0001-86;CSHGGRLouveira'
        ,'HCRI11;04.066.582/0001-60;HospitaldaCriança'
        ,'HFOF11;18.307.582/0001-19;HedgeTopFOFII3'
        ,'HGBS11;08.431.747/0001-06;CSHGBrasilShopping'
        ,'HGCR11;11.160.521/0001-22;CSHGRecebíveisImobiliários'
        ,'HGLG11;11.728.688/0001-47;CSHGLogística'
        ,'HGPO11;11.260.134/0001-68;CSHGPrimeOffices'
        ,'HGRE11;09.072.017/0001-29;CSHGRealEstate'
        ,'HGRU11;29.641.226/0001-53;CSHGRendaUrbana'
        ,'HTMX11;08.706.065/0001-69;HotelMaxinvest'
        ,'IRDM11;28.830.325/0001-10;IridiumRecebíveisImobiliários'
        ,'JPPC11;17.216.625/0001-98;JPPCapital'
        ,'JRDM11;14.879.856/0001-93;ShoppingJardimSul'
        ,'JSRE11;13.371.132/0001-71;JSRealEstateMultigestão'
        ,'JTPR11;23.876.086/0001-16;JTPrevFIIDesenvolvimentoHabitacional'
        ,'KNCR11;16.706.958/0001-32;KineaRendimentosImobiliários'
        ,'KNIP11;24.960.430/0001-13;KineaÍndicesdePreços'
        ,'KNRI11;12.005.956/0001-65;KineaRendaImobiliária'
        ,'MALL11;26.499.833/0001-32;MallsBrasilPlural'
        ,'MAXR11;11.274.415/0001-70;MaxRetail'
        ,'MBRF11;13.500.306/0001-59;MercantildoBrasil'
        ,'MFII11;16.915.968/0001-88;MéritoDesenvolvimentoImobiliário'
        ,'MGFF11;29.216.463/0001-77;MognoFundodeFundos'
        ,'MXRF11;97.521.225/0001-25;MaxiRenda'
        ,'NSLU11;08.014.513/0001-63;HospitalNossaSenhoradeLourdes'
        ,'NVHO11;17.025.970/0001-44;NovoHorizonte'
        ,'ONEF11;12.948.291/0001-23;TheOne'
        ,'OUJP11;26.091.656/0001-50;OURINVESTJPP'
        ,'PABY11;00.613.094/0001-74;Panamby'
        ,'PLRI11;00.613.094/0001-74;PoloFundodeRecebíveis'
        ,'PQDP11;10.869.155/0001-12;ParqueDomPedroShoppingCenter'
        ,'PRSV11;11.281.322/0001-72;PresidenteVargas'
        ,'RBBV11;16.915.868/0001-51;JHSFRioBravoFazendaBoaVistaCapitalProtegido'
        ,'RBED11;13.873.457/0001-52;RIOBED'
        ,'RBGS11;13.652.006/0001-95;RBCapitalGeneralShoppingSulacap'
        ,'RBRD11;09.006.914/0001-34;RBCapitalRendaII'
        ,'RBRF11;27.529.279/0001-51;RBRAlphaFundodeFundos'
        ,'RBRP11;21.408.063/0001-51;RBRProperties'
        ,'RBRR11;29.467.977/0001-03;RBRRendimentoHighGrade'
        ,'RBTS11;29.299.737/0001-39;RBCapitalTFOSitus'
        ,'RBVA11;15.576.907/0001-70;RIOBVA'
        ,'RBVO11;15.769.670/0001-44;RioBravoCréditoImobiliárioII'
        ,'RCRI11;26.511.274/0001-39;RBCapitalRendimentosImobiliários'
        ,'RDES11;14.793.782/0001-78;RendadeEscritórios'
        ,'RDPD11;23.120.027/0001-13;BBRendadePapéisImobiliáriosII'
        ,'RNDP11;15.394.563/0001-89;BBRendadePapéisImobiliários'
        ,'RNGO11;15.006.286/0001-90;RioNegro'
        ,'SAAG11;16.915.840/0001-14;SantanderAgências'
        ,'SCPF11;01.657.856/0001-05;SCP'
        ,'SDIL11;16.671.412/0001-93;SDILogísticaRio'
        ,'SFND11;09.350.920/0001-04;SãoFernando'
        ,'SHDP11B;07.224.019/0001-60;ShoppingParqueDomPedro'
        ,'SHPH11;03.507.519/0001-59;ShoppingPatioHigienopolis'
        ,'SPTW11;15.538.445/0001-05;SPDowntown'
        ,'TBOF11;17.365.105/0001-47;TBOffice'
        ,'TGAR11B;25.032.881/0001-53;TGAtivoReal'
        ,'THRA11;13.966.653/0001-71;CyrelaTheraCorporate'
        ,'TRNT11;04.722.883/0001-02;TorreNorte'
        ,'TRXL11;11.839.593/0001-09;TRXRealtyLogísticaRendaI'
        ,'TSNC11;17.007.443/0001-07;Transinc'
        ,'UBSR11;28.152.272/0001-26;UBS(BR)RecebíveisImobiliários'
        ,'VERE11;08.693.497/0001-82;Vereda'
        ,'VGIR11;29.852.732/0001-91;ValoraREIII'
        ,'VILG11;24.853.044/0001-22;VinciLogística'
        ,'VISC11;17.554.274/0001-25;VinciShoppingCenters'
        ,'VLOL11;15.296.696/0001-12;VilaOlímpiaCorporate'
        ,'VRTA11;11.664.201/0001-00;FatorVerita'
        ,'VTLT11;27.368.600/0001-63;VotorantimLogística'
        ,'WPLZ11B;09.326.861/0001-39;ShoppingWestPlaza'
        ,'WTSP11B;28.693.595/0001-27;OurinvestREI'
        ,'XPCM11;16.802.320/0001-03;XPCorporateMacaé'
        ,'XPIN11;28.516.325/0001-40;XPIndustrial'
        ,'XPML11;28.757.546/0001-00;XPMallsFII'
        ,'XTED11;15.006.267/0001-63;TRXEdifíciosCorporativos'
        ,'RECT11;32.274.163/0001-59;REC Renda Imobiliária BRL Trus'
    ]
}