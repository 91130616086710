import React, { Component } from 'react'
import {Button} from 'primereact/button'
import {Dialog} from 'primereact/dialog'

export class ConfirmDialog extends Component {

    render() {
        const footer = (
            <div>
                <Button label="Sim" icon="pi pi-check" onClick={this.props.funcaoSim}/>
                <Button label="Não" icon="pi pi-times" onClick={this.props.funcaoNao}/>
            </div>
        );

        return (
            <Dialog header={this.props.header} visible={this.props.visible} modal={true}
                footer={footer} style={{width: '40vw'}} onHide={this.props.funcaoNao}>

                    <div className="p-grid">                        
                        <div className="p-col-12">
                            {this.props.message}
                        </div>
                    </div>

            </Dialog> 
        )
    }
}